import React from 'react';

const PrinterIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='13'
			viewBox='0 0 16 13'
			fill='none'
		>
			<g id='Printer'>
				<rect
					id='Rectangle 1303'
					x='1.24805'
					y='2.01978'
					width='14.0588'
					height='5.9236'
					rx='0.5'
					stroke='#383838'
					stroke-linejoin='round'
				/>
				<g id='Vector 2'>
					<path
						d='M4.33344 11.5589V5.32764H12.2214V9.13561L10.0701 11.5589H4.33344Z'
						fill='white'
					/>
					<path
						d='M3.25781 5.32764H4.33344M13.297 5.32764H12.2214M4.33344 5.32764V11.5589H10.0701L12.2214 9.13561V5.32764M4.33344 5.32764H12.2214'
						stroke='#383838'
						stroke-linejoin='round'
					/>
				</g>
				<path
					id='Vector 3'
					d='M5.76758 7.05848H10.7872M12.5799 3.59668H13.6555M10.0701 11.5588V9.13556H12.2214'
					stroke='#383838'
					stroke-linejoin='round'
				/>
			</g>
		</svg>
	);
};

export default PrinterIcon;
