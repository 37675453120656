import React from 'react';

const StatusIcon = ({ status }) => {
	const icons = {
		approved: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='17'
				viewBox='0 0 16 17'
				fill='none'
			>
				<path
					d='M8 1.37158V15.3716M4.5 12.0839L5.5255 12.8527C6.89167 13.8782 9.10717 13.8782 10.4745 12.8527C11.8418 11.8272 11.8418 10.1659 10.4745 9.14042C9.792 8.62708 8.896 8.37158 8 8.37158C7.15417 8.37158 6.30833 8.11492 5.66317 7.60275C4.37283 6.57725 4.37283 4.91592 5.66317 3.89042C6.9535 2.86492 9.0465 2.86492 10.3368 3.89042L10.821 4.27542'
					stroke='#383838'
					stroke-width='1.25'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
			</svg>
		),
		inprogress: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='17'
				viewBox='0 0 16 17'
				fill='none'
			>
				<g clip-path='url(#clip0_1859_7599)'>
					<path
						d='M8 3.70492V8.37158H11.5M15 8.37158C15 9.29084 14.8189 10.2011 14.4672 11.0504C14.1154 11.8996 13.5998 12.6713 12.9497 13.3213C12.2997 13.9713 11.5281 14.487 10.6788 14.8387C9.82951 15.1905 8.91925 15.3716 8 15.3716C7.08075 15.3716 6.1705 15.1905 5.32122 14.8387C4.47194 14.487 3.70026 13.9713 3.05025 13.3213C2.40024 12.6713 1.88463 11.8996 1.53284 11.0504C1.18106 10.2011 1 9.29084 1 8.37158C1 6.51507 1.7375 4.73459 3.05025 3.42183C4.36301 2.10908 6.14348 1.37158 8 1.37158C9.85652 1.37158 11.637 2.10908 12.9497 3.42183C14.2625 4.73459 15 6.51507 15 8.37158Z'
						stroke='#383838'
						stroke-width='1.25'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
				</g>
				<defs>
					<clipPath id='clip0_1859_7599'>
						<rect
							width='16'
							height='16'
							fill='white'
							transform='translate(0 0.371582)'
						/>
					</clipPath>
				</defs>
			</svg>
		),
		submitted: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='17'
				viewBox='0 0 16 17'
				fill='none'
			>
				<path
					d='M1.38281 8.04112L6.01448 12.6728L14.6168 4.07056'
					stroke='#383838'
					stroke-width='1.5'
				/>
			</svg>
		),
		rejected: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='17'
				viewBox='0 0 16 17'
				fill='none'
			>
				<path
					d='M15.326 1.69589L15.4144 1.6075L15.326 1.51911L14.8547 1.04778L14.7663 0.959392L14.6779 1.04777L8.00163 7.72339L1.32602 1.04711L1.23763 0.958717L1.14924 1.04711L0.677903 1.51844L0.589519 1.60683L0.677899 1.69522L7.35352 8.37149L0.677241 15.0471L0.588844 15.1355L0.677237 15.2239L1.14857 15.6952L1.23695 15.7836L1.32534 15.6952L8.00162 9.01961L14.6772 15.6959L14.7656 15.7843L14.854 15.6959L15.3253 15.2246L15.4137 15.1362L15.3254 15.0478L8.64974 8.37151L15.326 1.69589Z'
					fill='#383838'
					stroke='#383838'
					stroke-width='0.25'
				/>
			</svg>
		),
		unknown: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='16'
				height='17'
				viewBox='0 0 16 17'
				fill='none'
			>
				<g clip-path='url(#clip0_1871_6333)'>
					<path
						d='M15 8.5C15 9.41925 14.8189 10.3295 14.4672 11.1788C14.1154 12.0281 13.5998 12.7997 12.9497 13.4497C12.2997 14.0998 11.5281 14.6154 10.6788 14.9672C9.82951 15.3189 8.91925 15.5 8 15.5C7.08075 15.5 6.1705 15.3189 5.32122 14.9672C4.47194 14.6154 3.70026 14.0998 3.05025 13.4497C2.40024 12.7997 1.88463 12.0281 1.53284 11.1788C1.18106 10.3295 1 9.41925 1 8.5C1 6.64348 1.7375 4.86301 3.05025 3.55025C4.36301 2.2375 6.14348 1.5 8 1.5C9.85652 1.5 11.637 2.2375 12.9497 3.55025C14.2625 4.86301 15 6.64348 15 8.5Z'
						stroke='#383838'
						stroke-width='1.25'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path
						d='M4 8.5H12'
						stroke='#383838'
						stroke-width='1.25'
						stroke-linecap='square'
					/>
				</g>
				<defs>
					<clipPath id='clip0_1871_6333'>
						<rect
							width='16'
							height='16'
							fill='white'
							transform='translate(0 0.5)'
						/>
					</clipPath>
				</defs>
			</svg>
		),
	};

	return icons[status];
};

export default StatusIcon;
