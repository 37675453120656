import { createStore, combineReducers } from 'redux';
import dataStorageReducer from './dataStorageReducer';

const rootReducer = combineReducers({
	dataStorage: dataStorageReducer,
});

const store = createStore(rootReducer);

export default store;
