// Action types
export const SET_VIN = 'SET_VIN';
export const SET_PURCHASE_DATE = 'SET_PURCHASE_DATE';
export const SET_TERMS = 'SET_TERMS';
export const SET_OSTERMS = 'SET_OSTERMS';
export const SET_DDREF = 'SET_DDREF';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_POSTAL_CODE = 'SET_POSTAL_CODE';
export const SET_DOB = 'SET_DOB';
export const SET_CUSTOMER_NUMBER = 'SET_CUSTOMER_NUMBER';
export const SET_ENCRYPTED_CUSTOMER_NUMBER = 'SET_ENCRYPTED_CUSTOMER_NUMBER';
export const SET_PURCHASE_OR_LEASE = 'SET_PURCHASE_OR_LEASE';
export const SET_NEW_OR_USED = 'SET_NEW_OR_USED';
export const SET_PURCHASE_PRICE = 'SET_PURCHASE_PRICE';
export const SET_LEASE_LENGTH = 'SET_LEASE_LENGTH';
export const SET_ADDRESS_ONE = 'SET_ADDRESS_ONE';
export const SET_ADDRESS_TWO = 'SET_ADDRESS_TWO';
export const SET_CITY = 'SET_CITY';
export const SET_PROVINCE = 'SET_PROVINCE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_IS_CORPORATE = 'SET_IS_CORPORATE';
export const SET_MASTER_LIST_COMPARISON = 'SET_MASTER_LIST_COMPARISON';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_SUBMITTED = 'SET_SUBMITTED';
export const SET_correlation_ID = 'SET_correlation_ID';

// Action creators
export const setVinStorage = (vin) => ({
	type: SET_VIN,
	payload: vin,
});
export const setPurchaseDateStorage = (purchaseDate) => ({
	type: SET_PURCHASE_DATE,
	payload: purchaseDate,
});
export const setTermsStorage = (terms) => ({
	type: SET_TERMS,
	payload: terms,
});
export const setOSTermsStorage = (osterms) => ({
	type: SET_OSTERMS,
	payload: osterms,
});
export const setDdrefStorage = (ddref) => ({
	type: SET_DDREF,
	payload: ddref,
});
export const setPostalCodeStorage = (postalCode) => ({
	type: SET_POSTAL_CODE,
	payload: postalCode,
});
export const setDobStorage = (dob) => ({
	type: SET_DOB,
	payload: dob,
});
export const setCustomerNumberStorage = (customerNumber) => ({
	type: SET_CUSTOMER_NUMBER,
	payload: customerNumber,
});
export const setEncryptedCustomerNumberStorage = (customerNumber) => ({
	type: SET_ENCRYPTED_CUSTOMER_NUMBER,
	payload: customerNumber,
});
export const setLastNameStorage = (lastName) => ({
	type: SET_LAST_NAME,
	payload: lastName,
});
export const setPurchaseOrLeaseStorage = (purchaseOrLease) => ({
	type: SET_PURCHASE_OR_LEASE,
	payload: purchaseOrLease,
});
export const setNewOrUsedStorage = (newOrUsed) => ({
	type: SET_NEW_OR_USED,
	payload: newOrUsed,
});
export const setPurchasePriceStorage = (purchasePrice) => ({
	type: SET_PURCHASE_PRICE,
	payload: purchasePrice,
});
export const setLeaseLengthStorage = (leaseLength) => ({
	type: SET_LEASE_LENGTH,
	payload: leaseLength,
});
export const setAddressOneStorage = (addressOne) => ({
	type: SET_ADDRESS_ONE,
	payload: addressOne,
});
export const setAddressTwoStorage = (addressTwo) => ({
	type: SET_ADDRESS_TWO,
	payload: addressTwo,
});
export const setCityStorage = (city) => ({
	type: SET_CITY,
	payload: city,
});
export const setProvinceStorage = (province) => ({
	type: SET_PROVINCE,
	payload: province,
});
export const setEmailStorage = (email) => ({
	type: SET_EMAIL,
	payload: email,
});
export const setIsCorporateStorage = (isCorporate) => ({
	type: SET_IS_CORPORATE,
	payload: isCorporate,
});
export const setMasterListComparison = (masterListComparison) => ({
	type: SET_MASTER_LIST_COMPARISON,
	payload: masterListComparison,
});
export const setEnvironment = (environment) => ({
	type: SET_ENVIRONMENT,
	payload: environment,
});
export const setSubmitted = (submitted) => ({
	type: SET_SUBMITTED,
	payload: submitted,
});
export const setCorrelationID = (correlationID) => ({
	type: SET_correlation_ID,
	payload: correlationID,
});

// Initial state
const initialState = {
	storedVIN: null,
	storedPurchaseDate: null,
	storedTerms: null,
	storedOSTerms: null,
	storedDDREF: null,
	storedPostalCode: null,
	storedDOB: null,
	storedCustomerNumber: null,
	storedEncryptedCustomerNumber: null,
	storedLastName: null,
	storedPurchaseOrLease: null,
	storedNewOrUsed: null,
	storedPurchasePrice: null,
	storedLeaseLength: null,
	storedAddressOne: null,
	storedAddressTwo: null,
	storedCity: null,
	storedProvince: 'Manitoba',
	storedEmail: null,
	storedIsCorporate: null,
	storedMasterListComparison: null,
	storedEnvironment: null,
	storedSubmitted: null,
	storedcorrelationID: null,
};

// Reducer
const dataStorageReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_VIN:
			return {
				...state,
				storedVIN: action.payload,
			};
		case SET_PURCHASE_DATE:
			return {
				...state,
				storedPurchaseDate: action.payload,
			};
		case SET_TERMS:
			return {
				...state,
				storedTerms: action.payload,
			};
		case SET_OSTERMS:
			return {
				...state,
				storedOSTerms: action.payload,
			};
		case SET_DDREF:
			return {
				...state,
				storedDDREF: action.payload,
			};
		case SET_POSTAL_CODE:
			return {
				...state,
				storedPostalCode: action.payload,
			};
		case SET_DOB:
			return {
				...state,
				storedDOB: action.payload,
			};
		case SET_CUSTOMER_NUMBER:
			return {
				...state,
				storedCustomerNumber: action.payload,
			};
		case SET_ENCRYPTED_CUSTOMER_NUMBER:
			return {
				...state,
				storedEncryptedCustomerNumber: action.payload,
			};
		case SET_LAST_NAME:
			return {
				...state,
				storedLastName: action.payload,
			};
		case SET_PURCHASE_OR_LEASE:
			return {
				...state,
				storedPurchaseOrLease: action.payload,
			};
		case SET_NEW_OR_USED:
			return {
				...state,
				storedNewOrUsed: action.payload,
			};
		case SET_PURCHASE_PRICE:
			return {
				...state,
				storedPurchasePrice: action.payload,
			};
		case SET_LEASE_LENGTH:
			return {
				...state,
				storedLeaseLength: action.payload,
			};
		case SET_ADDRESS_ONE:
			return {
				...state,
				storedAddressOne: action.payload,
			};
		case SET_ADDRESS_TWO:
			return {
				...state,
				storedAddressTwo: action.payload,
			};
		case SET_CITY:
			return {
				...state,
				storedCity: action.payload,
			};
		case SET_PROVINCE:
			return {
				...state,
				storedProvince: action.payload,
			};
		case SET_EMAIL:
			return {
				...state,
				storedEmail: action.payload,
			};
		case SET_IS_CORPORATE:
			return {
				...state,
				storedIsCorporate: action.payload,
			};
		case SET_MASTER_LIST_COMPARISON:
			return {
				...state,
				storedMasterListComparison: action.payload,
			};
		case SET_ENVIRONMENT:
			return {
				...state,
				storedEnvironment: action.payload,
			};
		case SET_SUBMITTED:
			return {
				...state,
				storedSubmitted: action.payload,
			};
		case SET_correlation_ID:
			return {
				...state,
				storedCorrelationID: action.payload,
			};
		default:
			return state;
	}
};

export default dataStorageReducer;
