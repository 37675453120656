import React from 'react';

const ErrorIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='15'
			height='14'
			viewBox='0 0 15 14'
			fill='none'
		>
			<path
				d='M7.64124 0C7.55203 7.24657e-05 7.46452 0.0243969 7.38807 0.0703707C7.31163 0.116344 7.24912 0.182238 7.20724 0.261L0.0682352 13.224C0.0238521 13.3018 0.000346625 13.3897 3.80443e-06 13.4793C-0.000339016 13.5688 0.0224925 13.6569 0.0662788 13.735C0.110065 13.8131 0.173317 13.8786 0.249884 13.925C0.326452 13.9714 0.41373 13.9973 0.503235 14H14.7792C14.8687 13.9973 14.956 13.9714 15.0326 13.925C15.1092 13.8786 15.1724 13.8131 15.2162 13.735C15.26 13.6569 15.2828 13.5688 15.2825 13.4793C15.2821 13.3897 15.2586 13.3018 15.2142 13.224L8.07524 0.261C8.03335 0.182238 7.97084 0.116344 7.8944 0.0703707C7.81795 0.0243969 7.73044 7.24657e-05 7.64124 0ZM8.39124 11.5H6.89124V10H8.39124V11.5ZM8.39124 8.75H6.89124V5H8.39124V8.75Z'
				fill='#DC2E28'
			/>
		</svg>
	);
};

export default ErrorIcon;
