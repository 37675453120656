import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import '../styles/forms.css';
import VehicleInformation from './forms/VehicleInformation';
import CustomerInformation from './forms/CustomerInformation';
import VinForm from './forms/VinForm';
import ReviewSubmission from './forms/ReviewSubmission';
import CustomerEligibility from './CustomerEligibility';
import SubmissionSuccess from './SubmissionSuccess';

import FormHeader from './ui/FormHeader';

import { fadeVariant } from '../utils/motionVariants.js';

import '../styles/ui/FormStepBar.css';
import CheckmarkIcon from './icons/CheckmarkIcon.js';
import RightArrow from './icons/RightArrow.js';
import Notification from './ui/Notification.js';

const ApplicationSubmission = ({ isSubmission = true, ...props }) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const elementRef = useRef(null);

	const [currentStep, setCurrentStep] = useState(0);
	const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false);
	const [isApplication, setIsApplication] = useState(false);

	const hasAgreedToTerms = useSelector(
		(state) => state.dataStorage.storedTerms
	);

	const languages = {
		en: {
			TermsAndConditions: 'Terms & conditions',
			stepOne: 'Check vehicle eligibility',
			stepTwo: 'Check customer account eligibility',
			stepThree: 'Vehicle purchase or lease details',
			stepFour: 'Customer information',
			stepFive: 'Review & submit',
			header: 'Electric Vehicle Rebate application',
			preamble:
				'Congratulations on your decision to purchase or lease an electric vehicle! You may be eligible for up to $4,000 through the Manitoba Electric Vehicle Rebate Program. <br><br> If you’ve purchased or leased an EV, complete the application form below. If you’re still thinking about acquiring an EV, use the <a href="/vehicle-eligibility">vehicle</a> and <a href="/customer-eligibility">customer account</a> eligibility checkers to see if you qualify.',
			notificationContent: (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<h4>Here’s what you need to know before you begin:</h4>
					<span className='bold'>
						How do you know if you’re eligible for an EV Rebate?
					</span>
					<ul>
						<li>
							Permanent Manitoba residents, corporations, businesses, First
							Nations and municipalities are eligible. (Crown corporations,
							federal and provincial governments are not eligible.)
						</li>
						<li>
							There is a limit of only one vehicle per purchaser during the
							duration of the program.
						</li>
						<li>
							The vehicle must remain in owner’s name and be registered in
							Manitoba for a minimum of 12 months.
						</li>
						<li>The vehicle must be registered with MPI.</li>
						<li>
							Vehicles must be purchased/leased between August 1, 2023–March 31,
							2026.
						</li>
						<li>
							The vehicle must be purchased/leased from a Manitoba dealership.
							Teslas purchased in another province between August 1, 2023–May 2,
							2024 are eligible. Teslas purchased after May 2, 2024 must be made
							with a Manitoba dealership.
						</li>
						<li>
							The Manufacturer’s Suggested Retail Price (MSRP) of the vehicle
							must be less than $70,000 before taxes.
						</li>
					</ul>
					<span className='bold'>What you'll need to apply:</span>
					<ul>
						<li>Your Vehicle Identification Number (VIN)</li>
						<li>The vehicle purchase price before taxes (if purchased).</li>
						<li>The length of the vehicle lease term (if leased).</li>
						<li>
							Your valid Manitoba driver’s licence or identification card and
							registration. If you do not have a driver’s licence or
							identification card or are a corporate customer, you’ll need your
							customer number (found on your MPI documents).
						</li>
						<li>A valid email address.</li>
					</ul>
					<p className='bold'>
						Please note: submitting an application does not guarantee you will
						receive the rebate. The application will be reviewed for
						eligibility, and you will be notified by mail within 45-60 days.
					</p>
					<br />
					<p>
						After clicking Start my application, you’ll have the opportunity to
						review the complete Terms & Conditions before submitting your
						application.
					</p>
				</div>
			),
			startApplication: 'Start my application',
		},
		fr: {
			TermsAndConditions: 'Termes et conditions',
			stepOne: "Vérifiez l'éligibilité du véhicule",
			stepTwo: "Vérifiez l'éligibilité du client",
			stepThree:
				'Renseignements sur l’achat ou le contrat de location du véhicule',
			stepFour: 'Renseignements sur le client ',
			stepFive: 'Réviser et soumettre ',
			header: 'Demande de Remboursement à l’achat d’un véhicule électrique',
			preamble:
				'Félicitations d’avoir décidé d’acheter ou de louer un véhicule électrique! Vous pourriez être admissible à un montant pouvant aller jusqu’à 4 000 $ dans le cadre du Programme de remboursement à l’achat d’un véhicule électrique du Manitoba.<br><br> Si vous avez acheté ou loué un VE, veuillez remplir le formulaire de demande ci-dessous. <br><br> Si vous pensez encore à faire l’achat d’un VE, veuillez vérifier l’admissibilité du <a href="/vehicle-eligibility?lang=fr">véhicule</a> et du <a href="/customer-eligibility?lang=fr">compte du client</a> pour voir si vous êtes admissible.',
			notificationContent: (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<h4 style={{ marginBottom: '24px' }}>
						Voici ce que vous devez savoir avant de commencer :
					</h4>
					<span className='bold'>Critères d’admissibilité</span>
					<ul style={{ marginBottom: '24px' }}>
						<li>
							Les résidents permanents, les compagnies, les entreprises, les
							Premières Nations et les municipalités du Manitoba sont
							admissibles.
						</li>
						<li>
							Il y a une limite d’un seul véhicule par acheteur pendant la durée
							du programme.
						</li>
						<li>
							Le véhicule doit rester au nom du propriétaire et être immatriculé
							au Manitoba pendant au moins 12 mois.
						</li>
						<li>
							Les sociétés d’État, et les gouvernements fédéral et provincial ne
							sont pas admissibles
						</li>
						<li>Le véhicule doit être immatriculé auprès de la SAPM.</li>
						<li>
							Les véhicules doivent être achetés ou loués entre le 1er août 2023
							et le 31 mars 2026.
						</li>
						<li>
							Le véhicule doit être acheté ou loué auprès d’un concessionnaire
							du Manitoba. Les Tesla achetées dans une autre province entre le
							1er août 2023 et le 2 mai 2024 sont admissibles. Après le 2 mai
							2024, les Tesla doivent avoir été achetées auprès d’un détaillant
							du Manitoba.
						</li>
						<li>
							Le prix de détail suggéré par le fabricant du véhicule doit être
							inférieur à 70 000 $ avant taxes.
						</li>
					</ul>
					<span className='bold'>
						Ce dont vous avez besoin pour présenter une demande :
					</span>
					<ul style={{ marginBottom: '24px' }}>
						<li>Votre Numéro d’identification de véhicule (NIV)</li>
						<li>Le prix d’achat hors taxes du véhicule (si acheté).</li>
						<li>
							La durée du contrat de location du véhicule (s’il est loué).
						</li>
						<li>
							Votre permis de conduire ou votre carte d’identité manitobaine et
							votre immatriculation valides. Si vous n’avez pas de permis de
							conduire ou de carte d’identité ou si vous êtes une entreprise
							cliente, vous aurez besoin de votre numéro de client (qui figure
							sur vos documents de la SAPM).
						</li>
						<li>Une adresse électronique valide.</li>
					</ul>
					<p className='bold'>
						Remarque : l’envoi d’une demande ne garantit pas que vous recevrez
						le remboursement. L’admissibilité de la demande sera examinée et
						vous serez informé par courrier dans un délai de 45 à 60 jours.
					</p>
					<br />
					<p>
						Après avoir cliqué sur Commencer ma demande, vous aurez la
						possibilité de consulter l’intégralité des conditions générales
						avant de soumettre votre demande.
					</p>
				</div>
			),
			startApplication: 'Commencer ma demande',
		},
	};

	if (isApplication) {
		languages.en.preamble =
			'The Province of Manitoba is offering Manitobans a rebate for purchasing electric or plug-in hybrid electric vehicles. Begin your application to take advantage of this offer.';
		languages.fr.preamble =
			'Le Manitoba offre aux Manitobains un remboursement à l’achat de véhicules électriques ou de véhicules hybrides électriques rechargeables.  Présentez votre demande pour profiter de cette offre.';
	}

	const steps = [
		{ component: VinForm, name: languages[language].stepOne },
		{ component: CustomerEligibility, name: languages[language].stepTwo },
		{
			component: VehicleInformation,
			name: languages[language].stepThree,
		},
		{ component: CustomerInformation, name: languages[language].stepFour },
		{ component: ReviewSubmission, name: languages[language].stepFive },
	];

	const CurrentComponent = steps[currentStep]?.component;

	const goToNextStep = () => {
		if (currentStep < steps.length - 1) {
			setCurrentStep(currentStep + 1);
		} else {
			setShowSubmissionSuccess(true);
		}
	};

	const goToBeginning = () => {
		setCurrentStep(0);
		setIsApplication(false);
		elementRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	const goToVehicleInformation = () => {
		setCurrentStep(2);
		elementRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	const goToCustomerInformation = () => {
		setCurrentStep(3);
		elementRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		if (elementRef.current) {
			if (isApplication) {
				elementRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [currentStep, isApplication]);

	// Add beforeunload event listener
	useEffect(() => {
		const handleBeforeUnload = (e) => {
			e.preventDefault();
			e.returnValue = ''; // Required for Chrome to show the prompt
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	return (
		<React.Fragment>
			<h1 ref={elementRef}>{languages[language].header}</h1>
			<p
				dangerouslySetInnerHTML={{
					__html: languages[language].preamble,
				}}
			></p>
			<AnimatePresence mode='wait'>
				{!isApplication && (
					<motion.div
						key='appStarter'
						initial='hidden'
						animate='visible'
						exit='hidden'
						transition={{ duration: 0.3 }}
						variants={fadeVariant}
					>
						<Notification type='error' useIcon={false} animateIn={false}>
							{languages[language].notificationContent}
						</Notification>
						<div className='button-container'>
							<button
								className={`primary`}
								onClick={() => {
									setIsApplication(true);
								}}
							>
								{languages[language].startApplication} <RightArrow />
							</button>
						</div>
					</motion.div>
				)}
				{isApplication && (
					<motion.div
						key='stepBar'
						initial='hidden'
						animate='visible'
						exit='hidden'
						transition={{ duration: 0.3 }}
						variants={fadeVariant}
						className='step-bar'
					>
						<div
							className={`step ${hasAgreedToTerms ? 'completed' : 'active'}`}
						>
							<div className='circle'>
								{hasAgreedToTerms && (
									<span className='checkmark'>
										<CheckmarkIcon />
									</span>
								)}
							</div>
							<div className='label'>
								{languages[language].TermsAndConditions}
							</div>
						</div>
						{steps.map((step, index) => (
							<div
								key={index}
								className={`step ${
									showSubmissionSuccess || currentStep === index ? 'active' : ''
								} ${
									showSubmissionSuccess || currentStep > index
										? 'completed'
										: ''
								}`}
							>
								<div className='circle'>
									<AnimatePresence mode='wait'>
										{currentStep <= index && !showSubmissionSuccess && (
											<motion.span
												key={index + 1}
												initial='hidden'
												animate='visible'
												exit='hidden'
												variants={fadeVariant}
												transition={{
													duration: 0.2,
													ease: 'easeInOut',
												}}
											>
												{index + 1}
											</motion.span>
										)}
										{(showSubmissionSuccess || currentStep > index) && (
											<motion.span
												key='check'
												initial='hidden'
												animate='visible'
												exit='hidden'
												variants={fadeVariant}
												transition={{
													duration: 0.2,
													ease: 'easeInOut',
												}}
												className='checkmark'
											>
												<CheckmarkIcon />
											</motion.span>
										)}
									</AnimatePresence>
								</div>
								<div className='label'>{step.name}</div>
							</div>
						))}
					</motion.div>
				)}
				{isApplication && (
					<AnimatePresence mode='wait'>
						{!showSubmissionSuccess && (
							<motion.div
								key={currentStep}
								initial='hidden'
								animate='visible'
								exit='hidden'
								className='currentComponent'
								transition={{ duration: 0.5 }}
								variants={fadeVariant}
							>
								<FormHeader
									heading={steps[currentStep].name}
									formStep={currentStep + 1}
								/>
								{CurrentComponent && (
									<CurrentComponent
										isSubmission={isSubmission}
										goToNextStep={goToNextStep}
										goToCustomerInformation={goToCustomerInformation}
										goToVehicleInformation={goToVehicleInformation}
										goToBeginning={goToBeginning}
										{...props}
									/>
								)}
							</motion.div>
						)}
						{showSubmissionSuccess && (
							<motion.div
								key='submissionSuccess'
								initial='hidden'
								animate='visible'
								exit='hidden'
								transition={{ duration: 0.5 }}
								variants={fadeVariant}
							>
								<SubmissionSuccess />
							</motion.div>
						)}
					</AnimatePresence>
				)}
			</AnimatePresence>
		</React.Fragment>
	);
};

export default ApplicationSubmission;
