import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../../styles/SkeletonLoader.css';
import '../../styles/Application.css';
import EVIcon from '../icons/EVIcon';
import StatusIcon from '../icons/StatusIcon';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';
import Tooltip from './Tooltip';

import { fadeVariant } from '../../utils/motionVariants';

const Application = ({ index, application }) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const [vehicleDetails, setVehicleDetails] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showTooltip, setShowTooltip] = useState(false);
	const [tooltipPosition, setTooltipPosition] = useState({});

	const fetchVehicleDetails = async () => {
		const newDate = new Date();
		const isoString = newDate.toISOString();

		const payload = {
			vin: application.VIN,
			effective_date: isoString,
		};

		try {
			const vehicleData = await fetch('/wp-json/custom/v1/validate-vehicle', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			});

			const vehicleResponse = await vehicleData.json();
			if (vehicleResponse.success) {
				if (vehicleResponse.api_response.errorCode === 'http.TIMEOUT') {
					fetchVehicleDetails();
				} else {
					setVehicleDetails(vehicleResponse.api_response);
				}
			} else {
				console.error('Failed to fetch vehicle details');
			}
		} catch (error) {
			console.error('Error:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchVehicleDetails();
	}, [application.VIN]);

	const handleToolTip = (event) => {
		event.stopPropagation();
		setShowTooltip((prevState) => !prevState);
	};

	const formatDate = (isoString) => {
		const date = new Date(isoString);

		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		};

		return date.toLocaleString('en-US', options).replace(',', ', ');
	};

	const languages = {
		en: {
			approved: 'Your application is approved and a cheque has been issued.',
			inprogress: 'We are reviewing your application.',
			submitted: 'We received your application and it will be reviewed soon.',
			rejected:
				'You will receive a letter explaining the reasons why your application was rejected. If you have questions, please email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			unknown:
				'An error occurred retrieving your application. We apologize for the inconvenience. Please check again later.',
			VINLabel: 'Vehicle Identification Number (VIN)',
			lastUpdatedLabel: 'Application last updated',
			submittedLabel: 'Date application submitted',
			statusLabel: 'Application status',
			approvedStatusText: 'Approved / Payment Issued',
			inProgressStatusText: 'In review',
			submittedStatusText: 'Submitted',
			rejectedStatusText: 'Rejected',
			unknownStatusText: 'Status Unknown',
			returnHome: 'Return to the homepage',
		},
		fr: {
			approved: 'Votre demande est approuvée et un chèque a été émis. ',
			inprogress: 'Nous examinons votre demande. ',
			submitted:
				'Nous avons reçu votre demande et elle sera examinée sous peu. ',
			rejected:
				"Vous recevrez une lettre expliquant les raisons pour lesquelles votre demande a été rejetée. Si vous avez des questions, veuillez communiquer avec nous à l’adresse <a href='mailto:evrebate@mpi.mb.ca'>evrebate@mpi.mb.ca</a>.",
			unknown:
				'Une erreur s’est produite lors du traitement de votre demande. Nous vous remercions de votre compréhension. Veuillez réessayer plus tard.',
			VINLabel: "Numéro d'identification du véhicule (VIN)",
			lastUpdatedLabel: 'Dernière mise à jour de la demande',
			submittedLabel: 'Date d’envoi de la demande',
			statusLabel: 'État de la demande',
			approvedStatusText: 'Approuvé/Paiement émis',
			inProgressStatusText: 'En examen',
			submittedStatusText: 'Soumis',
			rejectedStatusText: 'Rejeté',
			unknownStatusText: 'État inconnu',
			returnHome: "Retour à l'accueil",
		},
	};

	let status;
	let statusClass;
	if (application.ApplicationStatus === 'Payment issued') {
		status = languages[language].approvedStatusText;
		statusClass = 'approved';
	} else if (application.ApplicationStatus === 'New') {
		status = languages[language].submittedStatusText;
		statusClass = 'submitted';
	} else if (
		application.ApplicationStatus === 'In Progress' ||
		application.ApplicationStatus === 'CVP On Hold' ||
		application.ApplicationStatus === 'CVP Approved' ||
		application.ApplicationStatus === 'Queued for payment'
	) {
		status = languages[language].inProgressStatusText;
		statusClass = 'inprogress';
	} else if (application.ApplicationStatus === 'CVP Rejected') {
		status = languages[language].rejectedStatusText;
		statusClass = 'rejected';
	} else {
		status = languages[language].unknownStatusText;
		statusClass = 'unknown';
	}

	return (
		<div
			key={index}
			className={`application-details ${loading ? 'loading' : ''}`}
		>
			<div>
				<EVIcon />
				<AnimatePresence initial={false} mode='wait'>
					{loading ? (
						<motion.div
							key='vehicleSkeletonLoader'
							variants={fadeVariant}
							initial='hidden'
							animate='visible'
							exit='hidden'
							transition={{ duration: 0.2, ease: 'easeInOut' }}
							className='skeleton-line-header'
							style={{ width: '60%' }}
						></motion.div>
					) : (
						<motion.h3
							key='vehicleDetails'
							variants={fadeVariant}
							initial='hidden'
							animate='visible'
							exit='hidden'
							transition={{ duration: 0.2, ease: 'easeInOut' }}
						>
							{vehicleDetails.vehicleMakeDescription}{' '}
							{vehicleDetails.vehicleModelDescription}
						</motion.h3>
					)}
				</AnimatePresence>
			</div>
			<div>
				<div className='application-field'>
					<label>{languages[language].VINLabel}</label>
					<AnimatePresence initial={false} mode='wait'>
						{loading ? (
							<motion.div
								key='vinSkeletonLoader'
								variants={fadeVariant}
								initial='hidden'
								animate='visible'
								exit='hidden'
								transition={{ duration: 0.2, ease: 'easeInOut' }}
								className='skeleton-line'
								style={{ width: '40%' }}
							></motion.div>
						) : (
							<motion.span
								key='vinDetails'
								variants={fadeVariant}
								initial='hidden'
								animate='visible'
								exit='hidden'
								transition={{ duration: 0.2, ease: 'easeInOut' }}
							>
								{application.VIN}
							</motion.span>
						)}
					</AnimatePresence>
				</div>
				<div className='application-field'>
					<label>{languages[language].lastUpdatedLabel}</label>
					<AnimatePresence initial={false} mode='wait'>
						{loading ? (
							<motion.div
								key='statusSkeletonLoader'
								variants={fadeVariant}
								initial='hidden'
								animate='visible'
								exit='hidden'
								transition={{ duration: 0.2, ease: 'easeInOut' }}
								className='skeleton-line'
								style={{ width: '40%' }}
							></motion.div>
						) : (
							<motion.span
								key='statusDetails'
								variants={fadeVariant}
								initial='hidden'
								animate='visible'
								exit='hidden'
								transition={{ duration: 0.2, ease: 'easeInOut' }}
							>
								{formatDate(application.Modified)}
							</motion.span>
						)}
					</AnimatePresence>
				</div>
				<div className='application-field'>
					<label>{languages[language].submittedLabel}</label>
					<AnimatePresence initial={false} mode='wait'>
						{loading ? (
							<motion.div
								key='vinSkeletonLoader'
								variants={fadeVariant}
								initial='hidden'
								animate='visible'
								exit='hidden'
								transition={{ duration: 0.2, ease: 'easeInOut' }}
								className='skeleton-line'
								style={{ width: '40%' }}
							></motion.div>
						) : (
							<motion.span
								key='vinDetails'
								variants={fadeVariant}
								initial='hidden'
								animate='visible'
								exit='hidden'
								transition={{ duration: 0.2, ease: 'easeInOut' }}
							>
								{formatDate(application.Created)}
							</motion.span>
						)}
					</AnimatePresence>
				</div>
				<div className='application-field'>
					<label>{languages[language].statusLabel}</label>
					<AnimatePresence initial={false} mode='wait'>
						{loading ? (
							<motion.div
								key='statusSkeletonLoader'
								variants={fadeVariant}
								initial='hidden'
								animate='visible'
								exit='hidden'
								transition={{ duration: 0.2, ease: 'easeInOut' }}
								className='skeleton-line'
								style={{ width: '40%' }}
							></motion.div>
						) : (
							<div>
								<motion.span
									key='statusDetails'
									variants={fadeVariant}
									initial='hidden'
									animate='visible'
									exit='hidden'
									className={`${statusClass} status`}
									transition={{ duration: 0.2, ease: 'easeInOut' }}
								>
									<StatusIcon status={statusClass} />
									{status}
								</motion.span>
								<div className='needHelp'>
									<QuestionMarkIcon
										onClick={(e) => handleToolTip(e)}
										className='question-mark-icon'
									/>
									<AnimatePresence>
										{showTooltip && (
											<Tooltip
												allowClose
												setShow={setShowTooltip}
												position={tooltipPosition}
											>
												<div>
													<p
														dangerouslySetInnerHTML={{
															__html: languages[language][statusClass],
														}}
													></p>
												</div>
											</Tooltip>
										)}
									</AnimatePresence>
								</div>
							</div>
						)}
					</AnimatePresence>
				</div>
			</div>
		</div>
	);
};

export default Application;
