import React from 'react';

const EVIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='26'
			height='25'
			viewBox='0 0 26 25'
			fill='none'
		>
			<g id='Icon'>
				<g id='icons'>
					<g id='electric car'>
						<g id='Group'>
							<path
								id='Vector'
								d='M13.7744 7.33931H16.8711V1.146H13.7744C12.0645 1.146 10.6778 2.53272 10.6778 4.24265C10.6778 5.95259 12.0645 7.33931 13.7744 7.33931Z'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								id='Vector_2'
								d='M19.9688 2.69458H16.8721'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								id='Vector_3'
								d='M19.9688 5.79077H16.8721'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</g>
						<g id='Group_2'>
							<path
								id='Vector_4'
								d='M4.48374 11.5973L5.61886 10.7457C5.88691 10.5444 6.21303 10.436 6.54786 10.436H14.3601C14.9766 10.436 15.5669 10.6809 16.0023 11.1163L17.6445 12.7585'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								id='Vector_5'
								d='M11.0625 12.7585V15.081'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								id='Vector_6'
								d='M3.32284 20.8875C3.32284 20.8875 3.3809 20.8875 2.74319 20.8875C2.18192 20.8875 1.70097 20.4859 1.60129 19.9343L1.00035 15.0813'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								id='Vector_7'
								d='M16.8711 20.8875H8.74237'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								id='Vector_8'
								d='M10.6771 4.24268H6.41915C3.42604 4.24268 1 6.66871 1 9.66182C1 12.6549 3.42604 15.081 6.41915 15.081H15.5901C18.1903 15.081 20.8631 15.3345 23.415 15.8329C23.6308 15.8755 23.8485 15.918 24.0662 15.9616C24.6439 16.0777 25.0436 16.6061 24.9962 17.1935C24.9226 18.0944 24.8162 19.3844 24.7514 20.1769C24.7185 20.5785 24.3827 20.8862 23.9801 20.8862H22.2915'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								id='Vector_9'
								d='M19.5795 23.5966C18.083 23.5966 16.8699 22.3835 16.8699 20.8871C16.8699 19.3906 18.083 18.1775 19.5795 18.1775C21.0759 18.1775 22.2891 19.3906 22.2891 20.8871C22.2891 22.3835 21.0759 23.5966 19.5795 23.5966Z'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								id='Vector_10'
								d='M6.03261 23.5966C4.53616 23.5966 3.32304 22.3835 3.32304 20.8871C3.32304 19.3906 4.53616 18.1775 6.03261 18.1775C7.52907 18.1775 8.74219 19.3906 8.74219 20.8871C8.74219 22.3835 7.52907 23.5966 6.03261 23.5966Z'
								stroke='#383838'
								stroke-width='1.5'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default EVIcon;
