import React from 'react';

const CheckmarkIcon = () => {
	return (
		<svg
			width='8'
			height='8'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.20013 9.50689L12.8902 2.8168L14.6458 4.56003L6.02309 13.1828L1.35382 8.5273L3.1104 6.77071L5.84658 9.50689L6.02336 9.68366L6.20013 9.50689Z'
				fill='#383838'
				stroke='#383838'
				strokeWidth='0.5'
			></path>
		</svg>
	);
};

export default CheckmarkIcon;
