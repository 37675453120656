import React, { useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/forms.css';

import { fadeVariant } from '../../utils/motionVariants';

import {
	setCustomerNumberStorage,
	setEncryptedCustomerNumberStorage,
	setPostalCodeStorage,
	setLastNameStorage,
	setIsCorporateStorage,
	setCorrelationID,
} from '../../redux/dataStorageReducer';

import PostalCodeInput from '../inputFields/PostalCodeInput';
import TextInput from '../inputFields/TextInput';
import Notification from '../ui/Notification';
import OSTerms from '../inputFields/OSTerms';
import RightArrow from '../icons/RightArrow';
import ButtonLink from '../ui/ButtonLink';
import NoteAccordion from '../ui/NoteAccordion';

const CustomerNoLicense = ({
	isSubmission = false,
	isCorporate = false,
	isStatusTracker = false,
	hasError = false,
	goToNextStep,
	setEncryptedPartyID,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const [customerFormData, setCustomerFormData] = useState({
		customerNumber: '',
		postalCode: '',
		lastName: '',
	});
	const [formErrors, setFormErrors] = useState({});
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [formValidation, setFormValidation] = useState({
		error: '',
		warning: '',
		success: '',
	});
	const [errorType, setErrorType] = useState('');

	const correlationIDStorage = useSelector(
		(state) => state.dataStorage.storedCorrelationID
	);

	const handleClearFields = (e) => {
		e.preventDefault();
		setCustomerFormData({
			customerNumber: '',
			postalCode: '',
			lastName: '',
		});
		setIsChecked(false);
		setIsDisabled(false);
		setErrorType('');
		setIsValid(false);
		setFormErrors({
			postalCode: '',
			customerNumber: '',
			lastName: '',
			terms: '',
		});
	};

	const dispatch = useDispatch();

	const postalCodeRef = useRef();

	const handleSubmit = async (e) => {
		e.preventDefault();

		const errors = {};

		// Customer Number Validations
		if (!customerFormData.customerNumber) {
			errors.customerNumber = languages[language].customerEmptyError;
		} else if (customerFormData.customerNumber.length > 10) {
			errors.customerNumber = languages[language].customerLengthError;
		}

		// Validate postal code using the ref
		if (customerFormData.postalCode.length < 1) {
			errors.postalCode = languages[language].postalCodeEmptyError;
		} else {
			const isPostalCodeValid = postalCodeRef.current.validatePostalCode();
			if (!isPostalCodeValid) {
				errors.postalCode = languages[language].postalCodeInvalidError;
			}
		}

		// Last Name or Company Name Validations
		if (!customerFormData.lastName) {
			errors.lastName = isCorporate
				? languages[language].companyNameEmptyError
				: languages[language].lastNameEmptyError;
		}

		if (!isSubmission && !isChecked) {
			errors.terms = languages[language].termsError;
		}

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		// Strip spaces from the postal code
		const formattedPostalCode = customerFormData.postalCode
			.replace(/\s+/g, '')
			.toUpperCase();
		const formattedName = customerFormData.lastName.replace(/\s+/g, '%20');

		// Create request payload
		let payload = {
			customerNumber: customerFormData.customerNumber,
			postal_code: formattedPostalCode,
			name: formattedName,
			correlationID: correlationIDStorage,
		};

		if (isLoading) {
		} else {
			setIsLoading(true);

			try {
				const response = await fetch('/wp-json/custom/v1/validate-party', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				});

				const data = await response.json();

				if (data.correlationID) {
					dispatch(setCorrelationID(data.correlationID));
					payload = {
						...payload,
						correlationID: data.correlationID,
					};
				}

				if (data.success) {
					if (
						data.api_response.errorCode === 'http.TIMEOUT' ||
						data.api_response.errorCode === 'http.INTERNAL_SERVER_ERROR'
					) {
						setFormValidation({
							error: languages[language].unexpectedError,
						});
						setErrorType('error');
						setIsDisabled(true);
						setIsLoading(false);
					} else {
						if (data.api_response.partyId === null) {
							setFormValidation({
								error: languages[language].pleaseConfirmInformation,
							});
							setIsDisabled(true);
							setErrorType('error');
						} else {
							if (isStatusTracker) {
								setEncryptedPartyID(data.api_response.encrypted_party_id);
								setIsLoading(false);
								setIsDisabled(true);
							} else {
								try {
									const listData = await fetch(
										'/wp-json/custom/v1/check-customer-list',
										{
											method: 'POST',
											headers: {
												'Content-type': 'application/json',
											},
											body: JSON.stringify({
												party_id: data.api_response.encrypted_party_id,
												correlationID: payload.correlationID,
											}),
										}
									);

									const listResponse = await listData.json();
									if (listResponse.success) {
										setFormValidation({
											success: languages[language].customerIsEligible,
										});
										setIsDisabled(true);
										setIsValid(true);
										setErrorType('success');
										dispatch(setCustomerNumberStorage(payload.customerNumber));
										dispatch(
											setEncryptedCustomerNumberStorage(
												data.api_response.encrypted_party_id
											)
										);
										dispatch(setPostalCodeStorage(payload.postal_code));
										dispatch(setLastNameStorage(formattedName));
										if (isCorporate) {
											dispatch(setIsCorporateStorage(true));
										}
									} else {
										if (listResponse.message === 'maybe') {
											setFormValidation({
												warning: languages[language].customerSoftNo,
											});
											setErrorType('warning');
											setIsLoading(false);
											setIsDisabled(true);
											setIsValid(true);
											dispatch(
												setCustomerNumberStorage(payload.customerNumber)
											);
											dispatch(
												setEncryptedCustomerNumberStorage(
													data.api_response.encrypted_party_id
												)
											);
											dispatch(setPostalCodeStorage(payload.postal_code));
											dispatch(setLastNameStorage(formattedName));
											if (isCorporate) {
												dispatch(setIsCorporateStorage(true));
											}
										} else {
											setFormValidation({
												error: languages[language].customerNotEligible,
											});
											setErrorType('error');
											setIsLoading(false);
											setIsDisabled(true);
										}
									}
								} catch {
									setFormValidation({
										error: languages[language].unableToVerify,
									});
									setIsDisabled(true);
									setErrorType('error');
								}
							}
						}
					}
				} else {
					if (
						data.api_response.errorCode === 'http.TIMEOUT' ||
						data.api_response.errorCode === 'http.INTERNAL_SERVER_ERROR'
					) {
						setFormValidation({
							error: languages[language].unexpectedError,
						});
						setErrorType('error');
						setIsLoading(false);
						setIsDisabled(true);
					} else {
						setFormValidation({
							error: languages[language].pleaseConfirmInformation,
						});
						setErrorType('error');
						setIsLoading(false);
						setIsDisabled(true);
					}
				}
			} catch (error) {
				console.error('Error:', error);
				setIsLoading(false);
				setIsDisabled(true);
				setErrorType('error');
				setFormValidation({
					error: languages[language].unableToVerify,
				});
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === 'customerNumber') {
			const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
			setCustomerFormData({
				...customerFormData,
				[name]: numericValue.slice(0, 10),
			});
		} else if (name === 'postalCode') {
			setCustomerFormData({
				...customerFormData,
				[name]: value.slice(0, 7),
			});
		} else {
			setCustomerFormData({
				...customerFormData,
				[name]: value,
			});
		}

		setFormErrors({
			...formErrors,
			[name]: '',
		});
	};

	useEffect(() => {
		if (hasError === true) {
			setFormValidation({
				error: languages[language].statusError,
			});
			setErrorType('error');
			setIsLoading(false);
		}
	}, [hasError]);

	const languages = {
		en: {
			required: 'All fields are required.',
			customerLabel: 'Customer number',
			customerHelper: 'Your customer number is located on your MPI documents.',
			customerEmptyError: 'Please enter your customer number.',
			customerLengthError:
				'Your customer number cannot be more than 10 characters.',
			lastNameLabel: 'Last name',
			companyNameLabel: 'Company name',
			lastNameEmptyError: 'Please enter your Last name.',
			companyNameEmptyError: 'Please enter your Company name.',
			postalCodeEmptyError:
				'Please enter a six-character postal code in the format ‘A1A 1A1’.',
			postalCodeInvalidError:
				'Please enter a six-character postal code in the format ‘A1A 1A1’.',
			termsError:
				'Please review and agree to the Terms of Use before continuing.',
			pleaseConfirmInformation:
				'We could not verify your identity with the information in our system. Please ensure all information was entered correctly and resubmit. ',
			customerIsEligible: 'You are eligible to apply.',
			customerSoftNo:
				'We are unable to confirm your eligibility. If you have submitted an application, you can check the status of the application or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			customerSoftNoSubmission:
				'We are unable to confirm your eligibility. If you have submitted an application, you can check the status of the application or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			customerNotEligible:
				'You are not eligible for the EV Rebate program. If you have submitted an application, you can check the status of the application or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			unableToVerify:
				'We could not verify your identity with the information in our system. Please ensure all information was entered correctly and resubmit. ',
			unexpectedError:
				'An unexpected error has occurred, please try again later.',
			CheckEligibility: 'Check eligibility',
			Continue: 'Continue',
			StartOver: 'Start over',
			noteAccordionTitle: 'Need more help finding your customer number?',
			noteAccordionContent: (
				<>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/asoa-englargement.png`}
						alt='Annual Statement of Account'
					/>
					<span className='bold mb-8'>
						Annual Statement of Account or Renewal Notice
					</span>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/new-policy-registration-englargement.png`}
						alt='New Policy / Registration Application'
					/>
					<span className='bold mb-8'>
						New Policy / Registration Application
					</span>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/vehicle-registration-certificate-englargement.png`}
						alt='Vehicle Registration Certificate'
					/>
					<span className='bold mb-2'>Vehicle Registration Certificate</span>
					<ButtonLink
						icon='magnify'
						text='Show More'
						link='https://onlineservices.mpi.mb.ca/drivertesting/identity/customernumber/'
						type='primary'
						additionalClasses='mt-8'
						isExternal={true}
					/>
				</>
			),
			statusError:
				'No applications for the EV rebate were found with this customer information. Please try again or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
		},
		fr: {
			required: 'Tous les champs doivent obligatoirement être remplis.',
			customerLabel: 'Numéro de référence de client',
			customerHelper:
				'Votre numéro de client figure sur les documents de la Société d’assurance publique du Manitoba.',
			customerEmptyError: 'Veuillez saisir votre numéro de client.',
			lastNameLabel: 'Nom de famille',
			companyNameLabel: 'Nom de l’entreprise',
			lastNameEmptyError: 'Veuillez saisir votre nom de famille.',
			companyNameEmptyError: 'Veuillez saisir votre nom de famille.',
			postalCodeEmptyError:
				'Veuillez entrer un code postal à six caractères au format « A1A 1A1 ».',
			postalCodeInvalidError:
				'Veuillez entrer un code postal à six caractères au format « A1A 1A1 ».',
			termsError:
				'Veuillez vérifier votre demande et accepter les modalités d’utilisation des services en ligne avant de poursuivre.',
			pleaseConfirmInformation:
				'Nous n’avons pu vérifier votre identité dans notre système. Veuillez vous assurer que toute l’information a été saisie correctement et soumettre le formulaire de nouveau. ',
			customerIsEligible: 'Vous êtes admissible pour faire une demande. ',
			customerSoftNo:
				'Nous ne sommes pas en mesure d’établir votre admissibilité. Si vous avez déjà soumis une demande, vous pouvez vérifier le statut de la demande ou envoyer un courriel à <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca.</a>',
			customerSoftNoSubmission:
				'Nous ne sommes pas en mesure d’établir votre admissibilité. Si vous avez déjà soumis une demande, vous pouvez vérifier le statut de la demande ou envoyer un courriel à <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca.</a>',
			customerNotEligible:
				'Vous n’êtes pas admissible au Programme de remboursement à l’achat ou à la location d’un VE. Si vous avez déjà soumis une demande, vous pouvez vérifier le statut de la demande ou envoyer un courriel à <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			unableToVerify:
				'Nous ne pouvons pas vérifier votre identité à partir des informations fournies.',
			unexpectedError:
				'Une erreur inattendue s’est produite, veuillez réessayer plus tard.',
			CheckEligibility: 'Vérifier l’admissibilité',
			Continue: 'Continuer',
			StartOver: 'Recommencer',
			noteAccordionTitle:
				'Vous avez besoin d’aide pour trouver votre numéro de client?',
			noteAccordionContent: (
				<>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/asoa-englargement.png`}
						alt='Permis de conduire'
					/>
					<span className='bold mb-8'>
						Relevé de compte annuel ou avis de renouvellement
					</span>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/vehicle-registration-certificate-englargement.png`}
						alt='Addendum au permis de conduire'
					/>
					<span className='bold mb-2'>
						Nouvelle police/Demande d’immatriculation
					</span>
					<span className='mb-2'>Ou</span>
					<span className='bold mb-2'>
						Certificat d’immatriculation du véhicule
					</span>
					<ButtonLink
						icon='magnify'
						text='Plus'
						link='https://onlineservices.mpi.mb.ca/drivertesting/identity/customernumber/'
						type='primary'
						additionalClasses='mt-8'
						isExternal={true}
					/>
				</>
			),
			statusError:
				'No applications for the EV rebate were found with this customer information. Please try again or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
		},
	};

	if (isStatusTracker) {
		languages.en.CheckEligibility = 'Check status';
		languages.fr.CheckEligibility = 'Vérifier le statut';
	}

	return (
		<React.Fragment>
			<p className='required-fields'>{languages[language].required}</p>
			<form onSubmit={handleSubmit}>
				<TextInput
					label={languages[language].customerLabel}
					name='customerNumber'
					value={customerFormData.customerNumber}
					onChange={handleChange}
					error={formErrors.customerNumber}
					isDisabled={isDisabled}
					helperText={languages[language].customerHelper}
				/>
				<NoteAccordion
					titleText={languages[language].noteAccordionTitle}
					content={languages[language].noteAccordionContent}
				/>
				<PostalCodeInput
					ref={postalCodeRef}
					name='postalCode'
					value={customerFormData.postalCode}
					onChange={handleChange}
					error={formErrors.postalCode}
					isDisabled={isDisabled}
				/>
				<TextInput
					label={
						isCorporate
							? languages[language].companyNameLabel
							: languages[language].lastNameLabel
					}
					name='lastName'
					value={customerFormData.lastName}
					onChange={handleChange}
					error={formErrors.lastName}
					isDisabled={isDisabled}
				/>
				<AnimatePresence>
					{errorType && (
						<Notification type={errorType}>
							<p
								dangerouslySetInnerHTML={{
									__html: formValidation[errorType],
								}}
							></p>
						</Notification>
					)}
				</AnimatePresence>
				{!isSubmission && (
					<OSTerms
						checked={isChecked}
						onChange={(e) => {
							setIsChecked(e.target.checked);
							setFormErrors((prevErrors) => ({ ...prevErrors, terms: '' }));
						}}
						error={formErrors.terms}
						isDisabled={isDisabled}
					/>
				)}
				<div className='button-container'>
					<button
						className={`${isDisabled ? 'disabled' : ''} primary`}
						type='submit'
						disabled={isDisabled}
					>
						<AnimatePresence mode='wait'>
							{isLoading ? (
								<motion.span
									key='spinner'
									initial='hidden'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{ duration: 0.2, ease: 'easeInOut', delay: 0.2 }}
								>
									<div className='button-spinner'></div>
									<span className={isLoading ? 'opacity-0' : ''}>
										{languages[language].CheckEligibility}
									</span>
								</motion.span>
							) : (
								<motion.span
									key='eligibility'
									initial='visible'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{ duration: 0.2, ease: 'easeInOut', delay: 0.2 }}
								>
									{languages[language].CheckEligibility}
								</motion.span>
							)}
						</AnimatePresence>
					</button>
					{isSubmission && (
						<button
							className={`${!isValid ? 'disabled' : ''} primary`}
							type='button'
							disabled={!isValid}
							onClick={goToNextStep}
						>
							<>
								{languages[language].Continue} <RightArrow />
							</>
						</button>
					)}
					<AnimatePresence>
						{isDisabled && (
							<motion.button
								key='startOver'
								className={`${!isDisabled ? 'disabled' : ''} primary`}
								type='submit'
								onClick={(e) => handleClearFields(e)}
								initial='visible'
								animate='visible'
								exit='hidden'
								variants={fadeVariant}
								transition={{
									duration: 0.2,
									ease: 'easeInOut',
									delay: 0.2,
								}}
							>
								<>{languages[language].StartOver}</>
							</motion.button>
						)}
					</AnimatePresence>
				</div>
			</form>
		</React.Fragment>
	);
};

export default CustomerNoLicense;
