import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import AsyncSelect from 'react-select/async';

import '../../styles/forms.css';

import { fadeVariant } from '../../utils/motionVariants';

import TextInput from '../inputFields/TextInput';
import PostalCodeInput from '../inputFields/PostalCodeInput';
import Notification from '../ui/Notification';

import {
	setAddressOneStorage,
	setAddressTwoStorage,
	setCityStorage,
	setProvinceStorage,
	setPostalCodeStorage,
	setEmailStorage,
} from '../../redux/dataStorageReducer';
import RightArrow from '../icons/RightArrow';
import TermsAndConditions from '../TermsAndConditions';

const CustomerInformation = ({
	isSubmission = false,
	goToNextStep,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const [showModal, setShowModal] = useState(false);

	const [customerFormData, setCustomerFormData] = useState({
		addressLine1: '',
		addressLine2: '',
		city: '',
		province: 'Manitoba',
		postalCode: '',
		emailAddress: '',
		confirmEmailAddress: '',
	});
	const [formErrors, setFormErrors] = useState({});
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [formValidation, setFormValidation] = useState({
		error: '',
		warning: '',
		success: '',
	});
	const [errorType, setErrorType] = useState('');

	//Customer information storage
	const addressOneStorage = useSelector(
		(state) => state.dataStorage.storedAddressOne
	);
	const addressTwoStorage = useSelector(
		(state) => state.dataStorage.storedAddressTwo
	);
	const cityStorage = useSelector((state) => state.dataStorage.storedCity);
	const provinceStorage = useSelector(
		(state) => state.dataStorage.storedProvince
	);
	const postalCodeStorage = useSelector(
		(state) => state.dataStorage.storedPostalCode
	);
	const emailStorage = useSelector((state) => state.dataStorage.storedEmail);

	useEffect(() => {
		setCustomerFormData((prevFormData) => ({
			...prevFormData,
			addressLine1: addressOneStorage || prevFormData.addressLine1,
			addressLine2: addressTwoStorage || prevFormData.addressLine2,
			city: cityStorage || prevFormData.city,
			province: provinceStorage || prevFormData.province,
			postalCode: postalCodeStorage || prevFormData.postalCode,
			emailAddress: emailStorage || prevFormData.emailAddress,
			confirmEmailAddress: emailStorage || prevFormData.confirmEmailAddress,
		}));
	}, [
		addressOneStorage,
		addressTwoStorage,
		cityStorage,
		provinceStorage,
		postalCodeStorage,
		emailStorage,
	]);

	const dispatch = useDispatch();

	const postalCodeRef = useRef();

	const loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				[{ value: 'Manitoba', label: 'Manitoba' }].filter((i) =>
					i.label.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const errors = {};

		// Address Line 1 Validation
		if (!customerFormData.addressLine1) {
			errors.addressLine1 = languages[language].noAddressError;
		}

		// City Validation
		if (!customerFormData.city) {
			errors.city = languages[language].noCityError;
		}

		// Email Validation
		if (!customerFormData.emailAddress) {
			errors.emailAddress = languages[language].noEmailError;
		} else if (
			!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
				customerFormData.emailAddress
			)
		) {
			errors.emailAddress = languages[language].emailNotValidError;
		}

		if (
			customerFormData.emailAddress !== customerFormData.confirmEmailAddress
		) {
			errors.confirmEmailAddress = languages[language].emailNotMatchError;
		}

		// Validate postal code using the ref
		const isPostalCodeValid = postalCodeRef.current.validatePostalCode();
		if (!isPostalCodeValid) {
			errors.postalCode = languages[language].invalidPostalCodeError;
		}

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		// Create request payload
		const payload = {
			...customerFormData,
		};

		setIsLoading(true);
		dispatch(setAddressOneStorage(customerFormData.addressLine1));
		dispatch(setAddressTwoStorage(customerFormData.addressLine2));
		dispatch(setCityStorage(customerFormData.city));
		dispatch(setProvinceStorage(customerFormData.province));
		dispatch(setPostalCodeStorage(customerFormData.postalCode));
		dispatch(setEmailStorage(customerFormData.emailAddress));
		setTimeout(() => {
			setIsLoading(false);
			goToNextStep();
		}, 1500);
	};

	const handleProvinceChange = (e) => {
		return;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setCustomerFormData({
			...customerFormData,
			[name]: value,
		});

		setFormErrors({
			...formErrors,
			[name]: '',
		});
	};

	const languages = {
		en: {
			required: "All fields are required, unless marked as 'Optional'.",
			preamble:
				'It’s important to use the same information on your customer file so the address can be confirmed. The correct mailing address is required to send the rebate cheque. If you’re unsure, contact your broker before submitting this application. You must be a permanent Manitoba resident to qualify for this rebate.',
			mailingAddress: 'Mailing address',
			addressLine1Label: 'Address Line 1',
			addressLine1Helper:
				'Apt/suite/unit/building (if applicable) and street address',
			addressLine2Label: 'Address line 2 (Optional)',
			addressLine2Helper: 'PO Box or Rural Route Number',
			cityLabel: 'City/Town',
			provinceLabel: 'Province',
			provinceValue: 'Manitoba',
			provinceHelp:
				'The EV Rebate is only available to permanent Manitoba residents.',
			emailAddressLabel: 'Email address',
			confirmEmailAddressLabel: 'Confirm email address',
			emailPreamble:
				'It may also be used by Environment and Climate Change Canada to provide follow-up communication, as outlined in the',
			TermsAndConditionsText: 'Terms & Conditions.',
			Continue: 'Continue',
			noAddressError: 'Please provide your mailing address.',
			noCityError: 'Please provide your city/town.',
			noEmailError:
				'Please provide an email address in the format: name@example.com',
			emailNotValidError:
				'Please provide an email address in the format: name@example.com',
			emailNotMatchError:
				'The confirmation email must match your email address. ',
			invalidPostalCodeError:
				'Please enter a six-character postal code in the format ‘A1A 1A1’.',
		},
		fr: {
			required:
				'Tous les champs sont obligatoires, sauf s’ils sont indiqués comme « facultatifs ».',
			preamble:
				'Il est important d’utiliser les mêmes renseignements que dans votre dossier client afin que l’adresse puisse être confirmée. L’adresse postale exacte est nécessaire pour que nous puissions vous faire parvenir le chèque incitatif.<br> Si vous n’en êtes pas certain, veuillez communiquer avec votre courtier avant de présenter votre demande. <br>Vous devez être un résident permanent du Manitoba pour être admissible à ce remboursement.',
			mailingAddress: 'Adresse postale officielle',
			addressLine1Label: 'Adresse ligne 1',
			addressLine1Helper:
				'Appt./suite/unité/immeuble (le cas échéant) et nom de la rue',
			addressLine2Label: 'Adresse ligne 2 (facultatif)',
			addressLine2Helper: 'C. P.  ou Route rurale numéro',
			cityLabel: 'Ville',
			provinceLabel: 'Province',
			provinceValue: 'Manitoba',
			provinceHelp:
				'Le Programme de remboursement à l’achat ou à la location d’un VE est uniquement offert aux résidents permanents du Manitoba.',
			emailAddressLabel: 'Adresse électronique',
			confirmEmailAddressLabel: 'Confirmez l’adresse courriel',
			emailPreamble:
				'Nous avons besoin de votre adresse électronique pour pouvoir communiquer avec vous au sujet de votre demande. Environnement et Changement climatique Canada pourrait également s’en servir pour assurer le suivi des communications, comme indiqué dans les conditions générales.',
			TermsAndConditionsText: 'Termes et Conditions',
			Continue: 'Continuer',
			noAddressError: 'Veuillez fournir votre adresse postale.',
			noCityError: 'Veuillez fournir le nom de la ville/du village.',
			noEmailError:
				'Veuillez fournir une adresse électronique au format suivant : nom@exemple.com.',
			emailNotValidError:
				'Veuillez fournir une adresse électronique au format suivant : nom@exemple.com.',
			emailNotMatchError:
				'L’adresse courriel de confirmation doit correspondre à votre adresse courriel. ',
			invalidPostalCodeError:
				'Veuillez entrer un code postal à six caractères au format « A1A 1A1 ».',
		},
	};

	return (
		<React.Fragment>
			<div className='form-container'>
				<p
					dangerouslySetInnerHTML={{
						__html: languages[language].preamble,
					}}
					style={{ fontSize: '20px' }}
				></p>
				<h4 style={{ marginBottom: '0px' }}>
					{languages[language].mailingAddress}
				</h4>
				<p className='required-fields'>{languages[language].required}</p>
				<form onSubmit={handleSubmit}>
					<TextInput
						label={languages[language].addressLine1Label}
						name='addressLine1'
						value={customerFormData.addressLine1}
						onChange={handleChange}
						error={formErrors.addressLine1}
						helperText={languages[language].addressLine1Helper}
					/>
					<TextInput
						label={languages[language].addressLine2Label}
						name='addressLine2'
						value={customerFormData.addressLine2}
						onChange={handleChange}
						error={formErrors.addressLine2}
						helperText={languages[language].addressLine2Helper}
					/>
					<TextInput
						label={languages[language].cityLabel}
						name='city'
						value={customerFormData.city}
						onChange={handleChange}
						error={formErrors.city}
					/>
					<PostalCodeInput
						ref={postalCodeRef}
						name='postalCode'
						value={customerFormData.postalCode}
						onChange={handleChange}
						error={formErrors.postalCode}
					/>
					<div className='text-input province'>
						<label>{languages[language].provinceLabel}</label>
						<AsyncSelect
							cacheOptions
							loadOptions={loadOptions}
							defaultOptions
							className='react-select-container'
							classNamePrefix='react-select'
							styles={{
								indicatorSeparator: () => ({ display: 'none' }),
							}}
							onChange={handleProvinceChange}
							isDisabled={true}
							defaultValue={{
								value: 'Manitoba',
								label: languages[language].provinceValue,
							}}
						/>
						<p className='help'>{languages[language].provinceHelp}</p>
					</div>
					<hr />
					<h4
						className='email-header'
						style={{ marginTop: '24px', marginBottom: '24px' }}
					>
						{languages[language].emailAddressLabel}
					</h4>
					<p>
						{languages[language].emailPreamble}{' '}
						<a
							className='email-preamble'
							onClick={(e) => {
								e.stopPropagation();
								setShowModal(true);
							}}
						>
							{languages[language].TermsAndConditionsText}
						</a>
					</p>
					<TextInput
						label={languages[language].emailAddressLabel}
						name='emailAddress'
						value={customerFormData.emailAddress}
						onChange={handleChange}
						error={formErrors.emailAddress}
					/>
					<TextInput
						label={languages[language].confirmEmailAddressLabel}
						name='confirmEmailAddress'
						value={customerFormData.confirmEmailAddress}
						onChange={handleChange}
						error={formErrors.confirmEmailAddress}
					/>
					<AnimatePresence>
						{errorType && (
							<Notification type={errorType}>
								<p>{formValidation[errorType]}</p>
							</Notification>
						)}
					</AnimatePresence>
					<div className='button-container'>
						<button
							className={`${isDisabled ? 'disabled' : ''} primary`}
							type='submit'
							disabled={isDisabled}
						>
							<AnimatePresence mode='wait'>
								{isLoading ? (
									<motion.span
										key='spinner'
										initial='hidden'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{
											duration: 0.2,
											ease: 'easeInOut',
											delay: 0.2,
										}}
									>
										<div className='button-spinner'></div>
										<span className={isLoading ? 'opacity-0' : ''}>
											{languages[language].Continue} <RightArrow />
										</span>
									</motion.span>
								) : (
									<motion.span
										key='submit'
										initial='visible'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{
											duration: 0.2,
											ease: 'easeInOut',
											delay: 0.2,
										}}
									>
										{languages[language].Continue} <RightArrow />
									</motion.span>
								)}
							</AnimatePresence>
						</button>
					</div>
				</form>
			</div>
			<AnimatePresence>
				{showModal && (
					<TermsAndConditions setShow={setShowModal} isSubmission={false} />
				)}
			</AnimatePresence>
		</React.Fragment>
	);
};

export default CustomerInformation;
