import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { slideFadeVariant } from '../../utils/motionVariants';

import '../../styles/ui/Notifications.css';

const Notification = ({
	isSubmission = false,
	useIcon = true,
	animateIn = true,
	children,
	type,
}) => {
	const icons = {
		important: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='19'
				height='25'
				viewBox='0 0 19 25'
				fill='none'
			>
				<path
					d='M11.5003 21.5V22.5C11.5003 23.0304 11.2896 23.5391 10.9145 23.9142C10.5394 24.2893 10.0307 24.5 9.5003 24.5C8.96986 24.5 8.46116 24.2893 8.08608 23.9142C7.71101 23.5391 7.5003 23.0304 7.5003 22.5V21.5H11.5003ZM17.0583 16.573C16.9532 16.4986 16.8675 16.4 16.8087 16.2854C16.7498 16.1709 16.7195 16.0438 16.7203 15.915V10.015C16.8104 8.41155 16.3383 6.8269 15.3854 5.53414C14.4325 4.24138 13.0586 3.32149 11.5003 2.933V2.5C11.5003 1.96957 11.2896 1.46086 10.9145 1.08579C10.5394 0.710714 10.0307 0.5 9.5003 0.5C8.96986 0.5 8.46116 0.710714 8.08608 1.08579C7.71101 1.46086 7.5003 1.96957 7.5003 2.5V2.933C5.93959 3.31711 4.56279 4.23589 3.60916 5.5297C2.65552 6.8235 2.18533 8.41053 2.2803 10.015V15.915C2.28097 16.0439 2.2505 16.171 2.19147 16.2856C2.13245 16.4001 2.04662 16.4987 1.9413 16.573L0.000296442 17.919C-0.00773931 18.3302 0.147876 18.7278 0.43292 19.0242C0.717964 19.3207 1.1091 19.4919 1.5203 19.5H17.4803C17.8914 19.4916 18.2824 19.3204 18.5674 19.024C18.8524 18.7275 19.0081 18.3301 19.0003 17.919L17.0583 16.573Z'
					fill='#78779E'
				/>
			</svg>
		),
		tip: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<g id='Info Flag Icons - Tip'>
					<path
						id='Path 10185'
						d='M21.6414 16.9222C22.9417 14.4903 23.3248 11.672 22.7208 8.98125C22.1169 6.29046 20.5661 3.9063 18.3512 2.26338C16.1362 0.620452 13.4047 -0.171832 10.6544 0.0309494C7.90413 0.233731 5.3183 1.41808 3.36829 3.36809C1.41827 5.3181 0.23393 7.90393 0.0311478 10.6542C-0.171634 13.4045 0.62065 16.136 2.26358 18.351C3.9065 20.5659 6.29066 22.1167 8.98145 22.7206C11.6722 23.3246 14.4905 22.9415 16.9224 21.6412L24.0004 24.0002L21.6414 16.9222ZM13.0004 17.7502H10.0004V15.2502H13.0004V17.7502ZM13.0004 13.5002H10.0004V5.5002H13.0004V13.5002Z'
						fill='#6F96AE'
					/>
				</g>
			</svg>
		),
		note: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='23'
				height='22'
				viewBox='0 0 23 22'
				fill='none'
			>
				<g id='Info Flag Icons'>
					<path
						id='Path 10180'
						d='M22.5876 20.4561L12.2376 0.619102C12.1532 0.456838 12.0258 0.320854 11.8694 0.225954C11.713 0.131053 11.5336 0.0808716 11.3506 0.0808716C11.1677 0.0808716 10.9883 0.131053 10.8319 0.225954C10.6755 0.320854 10.5481 0.456838 10.4636 0.619102L0.113642 20.4561C0.0339999 20.6086 -0.00496716 20.779 0.000506519 20.9509C0.0059802 21.1229 0.0557096 21.2905 0.144889 21.4375C0.234068 21.5846 0.359681 21.7062 0.509589 21.7906C0.659496 21.8749 0.828629 21.9192 1.00064 21.9191H21.7006C21.8727 21.9192 22.0418 21.8749 22.1917 21.7906C22.3416 21.7062 22.4672 21.5846 22.5564 21.4375C22.6456 21.2905 22.6953 21.1229 22.7008 20.9509C22.7063 20.779 22.6673 20.6086 22.5876 20.4561ZM12.8506 19.1691H9.85064V16.6691H12.8506V19.1691ZM12.8506 14.9191H9.85064V6.9191H12.8506V14.9191Z'
						fill='#299662'
					/>
				</g>
			</svg>
		),
		nova: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='18'
				viewBox='0 0 24 18'
				fill='none'
			>
				<g id='Info Flag Icons'>
					<path
						id='Path 10182'
						d='M23.986 0.648987L15.357 3.82099L13.276 7.98299L12.076 6.23199L10.705 7.98299L8.628 3.82099L0 0.648987L1.675 8.00899C1.675 8.00899 4.975 8.54199 5.05 8.54199L2.233 11.664L5.126 17.348H8.933L11.168 12.143L11.951 13.926H12.132L12.867 12.174L15.053 17.351H18.86L21.739 11.651L18.939 8.54399C19.015 8.54399 22.314 8.01099 22.314 8.01099L23.986 0.648987Z'
						fill='#1FA2B5'
					/>
				</g>
			</svg>
		),
		warning: (
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 25'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M23.2371 21.8821L12.8871 2.04507C12.8026 1.8828 12.6752 1.74682 12.5188 1.65192C12.3624 1.55702 12.183 1.50684 12.0001 1.50684C11.8171 1.50684 11.6377 1.55702 11.4813 1.65192C11.3249 1.74682 11.1975 1.8828 11.1131 2.04507L0.763056 21.8821C0.683414 22.0345 0.644447 22.205 0.649921 22.3769C0.655394 22.5488 0.705124 22.7164 0.794303 22.8635C0.883482 23.0106 1.00909 23.1322 1.159 23.2166C1.30891 23.3009 1.47804 23.3452 1.65006 23.3451H22.3501C22.5221 23.3452 22.6912 23.3009 22.8411 23.2166C22.991 23.1322 23.1166 23.0106 23.2058 22.8635C23.295 22.7164 23.3447 22.5488 23.3502 22.3769C23.3557 22.205 23.3167 22.0345 23.2371 21.8821ZM13.5001 20.5951H10.5001V18.0951H13.5001V20.5951ZM13.5001 16.3451H10.5001V8.34507H13.5001V16.3451Z'
					fill='#E59318'
				></path>
			</svg>
		),
		info: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='21'
				height='24'
				viewBox='0 0 21 24'
				fill='none'
			>
				<g id='Info Flag Icons'>
					<path
						id='Path 10181'
						d='M0 1.12045V23.9874H2V13.1124C8.333 11.4354 14.667 17.3874 21 13.9124V1.12045C14 4.95845 7 -2.71755 0 1.12045Z'
						fill='#757575'
					/>
				</g>
			</svg>
		),
		success: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<g id='Frame 11260'>
					<rect width='24' height='24' rx='12' fill='#8EDEB7' />
					<path
						id='Vector'
						d='M10.2001 13.5069L16.8902 6.8168L18.6458 8.56003L10.0231 17.1828L5.35382 12.5273L7.1104 10.7707L9.84658 13.5069L10.0234 13.6837L10.2001 13.5069Z'
						fill='#383838'
						stroke='#107A48'
						stroke-width='0.5'
					/>
				</g>
			</svg>
		),
		error: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<g id='Info Flag Icons'>
					<path
						id='Path 10179'
						d='M16.18 6.369L17.719 7.908L13.539 12.089L17.719 16.268L16.18 17.807L12 13.628L7.82 17.807L6.281 16.268L10.461 12.089L6.281 7.908L7.82 6.369L12 10.55L16.18 6.369ZM7.029 0L0 7.03V16.97L7.029 24H16.97L24 16.97V7.03L16.97 0H7.029Z'
						fill='#EF4136'
					/>
				</g>
			</svg>
		),
	};

	return (
		<motion.div
			initial={animateIn ? 'hidden' : 'visible'}
			animate='visible'
			exit='hidden'
			variants={slideFadeVariant}
			transition={{ duration: 0.2, ease: 'easeInOut' }}
			className={`notification ${type} `}
		>
			{useIcon && icons[type]}
			{children}
		</motion.div>
	);
};

export default Notification;
