import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ErrorIcon from '../icons/ErrorIcon';

import { fadeVariant } from '../../utils/motionVariants';

const TextInput = ({
	label,
	name,
	placeholder,
	value,
	onChange,
	isDisabled = false,
	error,
	helperText,
	...props
}) => {
	return (
		<div className={`${isDisabled ? 'disabled' : ''} text-input`}>
			{label && <label>{label}</label>}
			<input
				type='text'
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				maxlength='255'
				disabled={isDisabled ? 'disabled' : ''}
				className={`${error ? 'error' : ''} ${isDisabled ? 'disabled' : ''}`}
				{...props}
			/>
			{helperText && <p className='help'>{helperText}</p>}
			<AnimatePresence>
				{error && (
					<motion.span
						key={name}
						initial='hidden'
						animate='visible'
						exit='hidden'
						variants={fadeVariant}
						transition={{ duration: 0.2, ease: 'easeInOut' }}
						className='error-message'
					>
						<ErrorIcon />
						<span>{error}</span>
					</motion.span>
				)}
			</AnimatePresence>
		</div>
	);
};

export default TextInput;
