import React from 'react';

const EditIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='15'
			viewBox='0 0 14 15'
			fill='none'
		>
			<path
				d='M1.50011 10.345L0.847035 10.9971L0 14.5L3.48485 13.7153L4.13792 13.0633L1.50011 10.345Z'
				fill='#383838'
			/>
			<path
				d='M13.7444 2.20126L12.361 0.770903C12.1952 0.599561 11.9693 0.502128 11.7328 0.500034C11.4963 0.497941 11.2687 0.591357 11.1 0.759738L10.3219 1.53666L12.9553 4.25937L13.7334 3.48245C13.902 3.31401 13.9979 3.08442 14 2.84416C14.002 2.60391 13.9101 2.37265 13.7444 2.20126Z'
				fill='#383838'
			/>
			<path
				d='M9.05 2.80667L2.13609 9.70913L4.76941 12.4318L11.6824 5.52937L9.05 2.80667Z'
				fill='#383838'
			/>
		</svg>
	);
};

export default EditIcon;
