import React, { useState, useImperativeHandle, forwardRef } from 'react';
import TextInput from './TextInput';
import vinValidator from 'vin-validator';

const VinInput = forwardRef(
	({ value, onChange, error, language, isDisabled, ...props }, ref) => {
		const [localError, setLocalError] = useState('');

		const languages = {
			en: {
				label: 'Vehicle Identification Number (VIN)',
				helperText:
					'The VIN is a 17-character combination of letters and numbers.',
				enterVinError: 'Please enter your VIN.',
				invalidVin:
					'The VIN you entered is invalid. Please check the number and try again.',
				vinLength: 'Please enter a 17-character VIN.',
			},
			fr: {
				label: 'Numéro d’identification de véhicule (NIV)',
				helperText:
					'Le NIV est une combinaison de 17 caractères (chiffres et lettres).',
				enterVinError: 'Veuillez saisir votre NIV.',
				invalidVin:
					'Le NIV saisi n’est pas valide. Veuillez vérifier le numéro et réessayer.',
				vinLength: 'Veuillez saisir votre NIV à 17 caractères.',
			},
		};

		const handleChange = (event) => {
			const newValue = event.target.value.replace(/\s/g, '');
			onChange({ ...event, target: { ...event.target, value: newValue } }); // Call the onChange prop
		};

		const validateVIN = () => {
			if (!value) {
				setLocalError(languages[language].enterVinError);
				return false;
			} else if (value.length !== 17) {
				setLocalError(languages[language].vinLength);
				return false;
			} else if (!vinValidator.validate(value)) {
				setLocalError(languages[language].invalidVin);
				return false;
			} else {
				setLocalError('');
				return true;
			}
		};

		useImperativeHandle(ref, () => ({
			validateVIN,
		}));

		return (
			<TextInput
				label={languages[language].label}
				value={value}
				name='VIN'
				onChange={handleChange}
				error={localError || error}
				isDisabled={isDisabled}
				helperText={languages[language].helperText}
				{...props}
			/>
		);
	}
);

export default VinInput;
