import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../../styles/Tooltips.css';

const Tooltip = ({ children, allowClose, setShow, position }) => {
	const TooltipRef = useRef(null);

	const handleOverlayClick = (event) => {
		if (
			allowClose &&
			TooltipRef.current &&
			!TooltipRef.current.contains(event.target)
		) {
			setShow(false);
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	useEffect(() => {
		if (allowClose) {
			document.addEventListener('click', handleOverlayClick);
		}
		return () => {
			if (allowClose) {
				document.removeEventListener('click', handleOverlayClick);
			}
		};
	}, [allowClose]);

	const TooltipVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<motion.div
			className='Tooltip'
			initial='hidden'
			animate='visible'
			exit='exit'
			variants={TooltipVariants}
			transition={{ duration: 0.2, ease: 'easeInOut' }}
			ref={TooltipRef}
			style={position}
		>
			<div className='Tooltip-arrow'></div>
			{allowClose && (
				<button className='Tooltip-close' onClick={handleClose}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='22'
						height='22'
						viewBox='0 0 22 22'
						fill='none'
					>
						<path
							d='M21.854 0.853996L21.147 0.146996L11 10.293L0.853996 0.145996L0.146996 0.852996L10.293 11L0.145996 21.146L0.852996 21.853L11 11.707L21.146 21.854L21.853 21.147L11.707 11L21.854 0.853996Z'
							fill='#383838'
						/>
					</svg>
				</button>
			)}
			{children}
		</motion.div>
	);
};

export default Tooltip;
