import React from 'react';
import '../../styles/forms.css';

const LockIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='17'
			viewBox='0 0 16 17'
			fill='none'
		>
			<rect x='2.5' y='7' width='11' height='8' rx='0.5' stroke='#383838' />
			<path
				d='M5 6.5V4.5C5 2.84315 6.34315 1.5 8 1.5V1.5C9.65685 1.5 11 2.84315 11 4.5V6.5'
				stroke='#383838'
			/>
			<circle cx='7.87695' cy='11.1199' r='1' fill='#383838' />
		</svg>
	);
};

export default LockIcon;
