import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import '../../styles/forms.css';
import '../../styles/Print.css';
import Notification from '../ui/Notification';

import { fadeVariant } from '../../utils/motionVariants';

import LockIcon from '../icons/LockIcon';
import EditIcon from '../icons/EditIcon';
import RightArrow from '../icons/RightArrow';
import PrinterIcon from '../icons/PrinterIcon';

const ReviewSubmission = ({
	isSubmission,
	goToNextStep,
	goToVehicleInformation,
	goToCustomerInformation,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const [formErrors, setFormErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [formValidation, setFormValidation] = useState({
		error: '',
		warning: '',
		success: '',
	});
	const [errorType, setErrorType] = useState('');
	const [environment, setEnvironment] = useState('PROD');

	const correlationIDStorage = useSelector(
		(state) => state.dataStorage.storedCorrelationID
	);

	//Vehicle Eligibility storage
	const VIN = useSelector((state) => state.dataStorage.storedVIN);
	const purchaseDate = useSelector(
		(state) => state.dataStorage.storedPurchaseDate
	);

	//Customer eligibility storage
	const DDREF = useSelector((state) => state.dataStorage.storedDDREF);
	const customerNumber = useSelector(
		(state) => state.dataStorage.storedCustomerNumber
	);
	const encryptedCustomerNumber = useSelector(
		(state) => state.dataStorage.storedEncryptedCustomerNumber
	);
	const postalCode = useSelector((state) => state.dataStorage.storedPostalCode);
	const dateOfBirth = useSelector((state) => state.dataStorage.storedDOB);
	const customerName = useSelector((state) => state.dataStorage.storedLastName);
	const isCorporate = useSelector(
		(state) => state.dataStorage.storedIsCorporate
	);

	//Vehicle information storage
	const purchaseOrLease = useSelector(
		(state) => state.dataStorage.storedPurchaseOrLease
	);
	const newOrUsed = useSelector((state) => state.dataStorage.storedNewOrUsed);
	const purchasePrice = useSelector(
		(state) => state.dataStorage.storedPurchasePrice
	);
	const leaseLength = useSelector(
		(state) => state.dataStorage.storedLeaseLength
	);

	const masterListComparison = useSelector(
		(state) => state.dataStorage.storedMasterListComparison
	);

	//Customer information storage
	const addressOne = useSelector((state) => state.dataStorage.storedAddressOne);
	const addressTwo = useSelector((state) => state.dataStorage.storedAddressTwo);
	const city = useSelector((state) => state.dataStorage.storedCity);
	const province = useSelector((state) => state.dataStorage.storedProvince);
	const email = useSelector((state) => state.dataStorage.storedEmail);

	function capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const formatPostalCode = (postalCode) => {
		if (!postalCode) return '';

		const part1 = postalCode.substring(0, 3);
		const part2 = postalCode.substring(3);

		return `${part1} ${part2}`;
	};

	const newDate = new Date();
	const isoString = newDate.toISOString();

	const handleSubmit = async (e) => {
		e.preventDefault();

		const errors = {};

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		const payload = {
			VIN: VIN,
			MailingPostalCode: postalCode,
			DateOfBirth: dateOfBirth,
			MailingCity: city,
			MailingProvince: province,
			EmailAddress: email,
			ApplicationSubmissionDate: isoString,
			Environment: environment,
			MasterListComparison: masterListComparison,
			correlationID: correlationIDStorage,
		};

		if (addressOne) {
			payload.MailingAddress = addressOne;
		}
		if (addressTwo) {
			payload.MailingAddress = payload.MailingAddress + ' ' + addressTwo;
		}
		if (customerNumber) {
			payload.CustomerNumber = customerNumber;
		} else {
			payload.DDRef = DDREF;
		}

		// Add conditional properties
		if (customerName && !isCorporate) {
			payload.CustomerName = customerName.replace(/%20/g, ' ');
		} else if (isCorporate) {
			payload.CompanyName = customerName.replace(/%20/g, ' ');
		}

		if (purchaseOrLease === 'purchase') {
			payload.SalesLease = 'Sales';
			if (newOrUsed === 'new') {
				payload.NewUsedVehicle = 'New';
			} else {
				payload.NewUsedVehicle = 'Used';
				payload.PurchasePricePreTax = purchasePrice;
			}
		} else {
			payload.SalesLease = 'Lease';
			payload.LeaseLength = leaseLength.value;
		}

		if (encryptedCustomerNumber) {
			payload.encrypted_party_id = encryptedCustomerNumber;
		}

		if (isLoading) {
		} else {
			setIsLoading(true);
			try {
				const response = await fetch('/wp-json/custom/v1/submit-application', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				});

				const data = await response.json();

				if (data.success) {
					if (data.api_response.statusType === 'INTERNAL_SERVER_ERROR') {
						setFormValidation({
							error: languages[language].unexpectedError,
						});
						setErrorType('error');
						setHasError(true);
						setIsDisabled(true);
						setIsLoading(false);
					} else {
						goToNextStep();
						setIsDisabled(true);
						setIsLoading(false);
					}
				} else {
					setFormValidation({
						error: languages[language].unexpectedError,
					});
					setErrorType('error');
					setHasError(true);
					setIsDisabled(true);
					setIsLoading(false);
				}
			} catch (error) {
				console.error('Error:', error);
				setIsLoading(false);
				setErrorType('error');
				setHasError(true);
				setFormValidation({
					error: languages[language].unexpectedError,
				});
				setIsDisabled(true);
			} finally {
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		const currentURL = window.location.href;

		if (currentURL.includes('evincentivedev')) {
			setEnvironment('DEV');
		} else if (currentURL.includes('evincentivestg')) {
			setEnvironment('STAGE');
		} else if (currentURL.includes('evincentiveprd')) {
			setEnvironment('PROD');
		}
	}, []);

	function formatDate(date) {
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
		const year = date.getFullYear();
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
	}

	const languages = {
		en: {
			required: 'All fields are required.',
			preamble:
				'Before submitting your application, please verify that all information is accurate. If you need to update your vehicle purchase/lease details or customer information, please do so before clicking submit.',
			error:
				'An error occurred processing your application submission. We apologize for the inconvenience. Please try again later. (You may want to print this page as a reference.) <a href="/">Return to the homepage</a>',
			printError:
				'An error occurred processing your application submission. We apologize for the inconvenience. Please try again later.',
			EVTitle: 'Electric Vehicle Rebate application',
			vehicleEligibilityLabel: 'Vehicle eligibility',
			VINLabel: 'Vehicle Identification Number (VIN)',
			purchaseOrLeaseLabel: 'Purchase or lease effective date',
			customerEligibilityLabel: 'Customer eligibility',
			documentNumberLabel: 'Document number (DD/RÉF)',
			customerNumberLabel: 'Customer number',
			postalCodeLabel: 'Postal code',
			dateOfBirthLabel: 'Date of birth',
			vehiclePurchaseInformationLabel: 'Vehicle purchase or lease information',
			purchaseOrLeaseTypeLabel: 'Vehicle purchase or lease',
			newOrUsedLabel: 'New or used vehicle',
			leaseLengthLabel: 'Length of lease',
			customerNameLabel: 'Last name',
			companyNameLabel: 'Company name',
			purchasePriceLabel: 'Purchase price before tax',
			editInformation: 'Edit information',
			customerInformationLabel: 'Customer information',
			mailingAddressLabel: 'Mailing address',
			emailAddressLabel: 'Email address',
			submitApplication: 'Submit application',
			print: 'Print page',
			unexpectedError:
				'An unexpected error has occurred, please try again later.',
		},
		fr: {
			required: 'Tous les champs doivent obligatoirement être remplis.',
			preamble:
				'Avant de soumettre votre demande, veuillez vérifier que toute l’information est exacte. Si vous devez mettre à jour les renseignements relatifs à l’achat/la location du véhicule ou l’information relative au client, veuillez le faire avant de cliquer sur « Soumettre ».',
			error:
				'Une erreur s’est produite lors du traitement de votre demande. Nous vous remercions de votre compréhension. Veuillez réessayer plus tard. (Vous pouvez imprimer cette page à titre de référence). <a href="/">Retour à l’accueil</a>',
			printError:
				'Une erreur s’est produite lors du traitement de votre demande. Nous vous remercions de votre compréhension. Veuillez réessayer plus tard. (Vous pouvez imprimer cette page à titre de référence). <a href="/">Retour à l’accueil</a>',
			EVTitle: 'Demande de Remboursement à l’achat d’un véhicule électrique',
			vehicleEligibilityLabel: 'Admissibilité du véhicule',
			VINLabel: 'Numéro d’identification du véhicule (NIV)',
			purchaseOrLeaseLabel: 'Date effective de l’achat ou de la location',
			customerEligibilityLabel: 'Admissibilité du client',
			documentNumberLabel: 'Numéro de document (DD/RÉF)',
			customerNumberLabel: 'Numéro de référence de client',
			postalCodeLabel: 'Code postal',
			dateOfBirthLabel: 'Date de naissance',
			vehiclePurchaseInformationLabel:
				'Renseignements sur l’achat ou le contrat de location du véhicule',
			purchaseOrLeaseTypeLabel: 'Achat ou location du véhicule',
			newOrUsedLabel: 'Véhicule neuf ou d’occasion',
			leaseLengthLabel: 'Durée de la location',
			customerNameLabel: 'Nom de famille',
			companyNameLabel: 'Nom de l’entreprise',
			purchasePriceLabel: 'Prix d’achat avant taxes',
			editInformation: 'Modifier l’information',
			customerInformationLabel: 'Informations sur le client',
			mailingAddressLabel: 'Adresse postale officielle',
			emailAddressLabel: 'Adresse électronique',
			submitApplication: 'Soumettre la demande',
			print: 'Imprimer la page',
			unexpectedError:
				'Une erreur inattendue s’est produite, veuillez réessayer plus tard.',
		},
	};

	return (
		<>
			<div className='form-container'>
				<p>{languages[language].preamble}</p>
				<div style={{ marginTop: '0px' }} className='review-container'>
					<h4>{languages[language].vehicleEligibilityLabel}</h4>
					<div className='locked-input'>
						<label>{languages[language].VINLabel}</label>
						<span>
							{VIN}
							<LockIcon />
						</span>
					</div>
					<div className='locked-input'>
						<label>{languages[language].purchaseOrLeaseLabel}</label>
						<span>
							{purchaseDate instanceof Date
								? purchaseDate.toLocaleDateString()
								: purchaseDate}
							<LockIcon />
						</span>
					</div>
				</div>
				<hr style={{ marginTop: '24px' }} />
				<div className='review-container'>
					<h4>{languages[language].customerEligibilityLabel}</h4>
					{DDREF && (
						<div className='locked-input'>
							<label>{languages[language].documentNumberLabel}</label>
							<span>
								{DDREF}
								<LockIcon />
							</span>
						</div>
					)}
					{customerNumber && (
						<div className='locked-input'>
							<label>{languages[language].customerNumberLabel}</label>
							<span>
								{customerNumber}
								<LockIcon />
							</span>
						</div>
					)}
					{postalCode && (
						<div className='locked-input'>
							<label>{languages[language].postalCodeLabel}</label>
							<span>
								{postalCode}
								<LockIcon />
							</span>
						</div>
					)}
					{dateOfBirth && (
						<div className='locked-input'>
							<label>{languages[language].dateOfBirthLabel}</label>
							<span>
								{dateOfBirth instanceof Date
									? dateOfBirth.toLocaleDateString()
									: dateOfBirth}
								<LockIcon />
							</span>
						</div>
					)}
					{customerName && (
						<div className='locked-input'>
							<label>
								{isCorporate
									? languages[language].companyNameLabel
									: languages[language].customerNameLabel}
							</label>
							<span>
								{customerName.replace(/%20/g, ' ')}
								<LockIcon />
							</span>
						</div>
					)}
				</div>
				<hr style={{ marginTop: '24px' }} />
				<div className='review-container'>
					<h4>{languages[language].vehiclePurchaseInformationLabel}</h4>
					{purchaseOrLease && (
						<div className='locked-input'>
							<label>{languages[language].purchaseOrLeaseLabel}</label>
							<span>{capitalize(purchaseOrLease)}</span>
						</div>
					)}
					{newOrUsed && (
						<div className='locked-input'>
							<label>{languages[language].newOrUsedLabel}</label>
							<span>{capitalize(newOrUsed)}</span>
						</div>
					)}
					{purchasePrice && (
						<div className='locked-input'>
							<label>{languages[language].purchasePriceLabel}</label>
							<span>$ {purchasePrice}</span>
						</div>
					)}
					{leaseLength && (
						<div className='locked-input'>
							<label>{languages[language].leaseLengthLabel}</label>
							<span>{leaseLength.label}</span>
						</div>
					)}
					<button
						style={{ marginBottom: '0px' }}
						className={`secondary ${isDisabled ? 'disabled' : ''}`}
						onClick={() => {
							goToVehicleInformation();
						}}
						isDisabled={isDisabled}
					>
						<EditIcon /> {languages[language].editInformation}
					</button>
				</div>
				<hr style={{ marginTop: '24px' }} />
				<div className='review-container'>
					<h4>{languages[language].customerInformationLabel}</h4>
					<div className='locked-input'>
						<label>{languages[language].mailingAddressLabel}</label>
						{addressOne && <span>{addressOne}</span>}
						{addressTwo && <span>{addressTwo}</span>}
						{city && province && (
							<span>
								{capitalize(city)}, {capitalize(province)}
							</span>
						)}
						{postalCode && <span>{formatPostalCode(postalCode)}</span>}
					</div>
					{email && (
						<div className='locked-input'>
							<label>{languages[language].emailAddressLabel}</label>
							<span>{email}</span>
						</div>
					)}
					<button
						style={{ marginBottom: '0px' }}
						className={`secondary ${isDisabled ? 'disabled' : ''}`}
						onClick={() => {
							goToCustomerInformation();
						}}
						isDisabled={isDisabled}
					>
						<EditIcon /> {languages[language].editInformation}
					</button>
				</div>
				<hr style={{ marginTop: '24px' }} />
				<AnimatePresence>
					{errorType && (
						<Notification type={errorType}>
							<p
								dangerouslySetInnerHTML={{
									__html: formValidation[errorType],
								}}
							></p>
						</Notification>
					)}
				</AnimatePresence>
				<form onSubmit={handleSubmit}>
					<div className='button-container'>
						<button
							className={`${isDisabled ? 'disabled' : ''} primary`}
							type='submit'
							disabled={isDisabled}
							style={{ marginTop: '24px' }}
						>
							<AnimatePresence mode='wait'>
								{isLoading ? (
									<motion.span
										key='spinner'
										initial='hidden'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{
											duration: 0.2,
											ease: 'easeInOut',
											delay: 0.2,
										}}
									>
										<div className='button-spinner'></div>
										<span className={isLoading ? 'opacity-0' : ''}>
											{languages[language].submitApplication} <RightArrow />
										</span>
									</motion.span>
								) : (
									<motion.span
										key='submit'
										initial='visible'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{
											duration: 0.2,
											ease: 'easeInOut',
											delay: 0.2,
										}}
									>
										{languages[language].submitApplication} <RightArrow />
									</motion.span>
								)}
							</AnimatePresence>
						</button>
						<AnimatePresence>
							{hasError && (
								<motion.button
									style={{ marginTop: '24px' }}
									key='startOver'
									className={`${!isDisabled ? 'disabled' : ''} primary`}
									type='submit'
									onClick={() => {
										window.print();
									}}
									initial='visible'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{
										duration: 0.2,
										ease: 'easeInOut',
										delay: 0.2,
									}}
								>
									{languages[language].print} <PrinterIcon />
								</motion.button>
							)}
						</AnimatePresence>
					</div>
				</form>
			</div>
			<div className='print-container'>
				<span class='brand-wrapper'>
					<a
						class='navbar-brand'
						data-en-url='/?lang=en'
						data-fr-url='/?lang=fr'
						href='/?lang=en'
					>
						<img
							class='nav-logo'
							src='/wp-content/themes/evincentive/img/mpi-logo.svg'
							alt='Manitoba Public Insurance'
							data-en='Manitoba Public Insurance'
							data-en-img='/wp-content/themes/evincentive/img/mpi-logo.svg'
							data-fr="Société d'assurance publique du Manitoba"
							data-fr-img='/wp-content/themes/evincentive/img/mpi-logo.svg'
							title='Manitoba Public Insurance'
						/>
						<img
							class='nav-logo-text'
							src='/wp-content/themes/evincentive/img/mpi-en.svg'
							alt='Manitoba Public Insurance'
							data-en='Manitoba Public Insurance'
							data-en-img='/wp-content/themes/evincentive/img/mpi-en.svg'
							data-fr="Société d'assurance publique du Manitoba"
							data-fr-img='/wp-content/themes/evincentive/img/mpi-fr.svg'
							title='Manitoba Public Insurance'
						/>
					</a>
					<a
						class='navbar-gov-brand'
						data-en-url='https://www.gov.mb.ca/index.html'
						data-fr-url='https://www.gov.mb.ca/index.fr.html'
						href='https://www.gov.mb.ca/index.html'
					>
						<img
							class='prov-logo-text'
							src='/wp-content/themes/evincentive/img/govt-text.svg'
							alt='Government of Manitoba'
							data-en='Government of Manitoba'
							data-en-img='/wp-content/themes/evincentive/img/govt-text.svg'
							data-fr='Gouvernement du Manitoba'
							data-fr-img='/wp-content/themes/evincentive/img/govt-text.svg'
							title='Government of Manitoba'
						/>
						<img
							class='prov-logo'
							src='/wp-content/themes/evincentive/img/govt-logo.svg'
							alt='Government of Manitoba'
							data-en='Government of Manitoba'
							data-en-img='/wp-content/themes/evincentive/img/govt-logo.svg'
							data-fr='Gouvernement du Manitoba'
							data-fr-img='/wp-content/themes/evincentive/img/govt-logo.svg'
							title='Government of Manitoba'
						/>
					</a>
				</span>
				<h2>{languages[language].EVTitle}</h2>
				<Notification type={errorType}>
					<p
						dangerouslySetInnerHTML={{
							__html: languages[language].printError,
						}}
					></p>
				</Notification>
				<div className='columns'>
					<div>
						<h5>{languages[language].vehicleEligibilityLabel}</h5>
						{VIN && (
							<div className='locked-input'>
								<label>{languages[language].VINLabel}</label>
								<span>{VIN}</span>
							</div>
						)}
						{purchaseDate && (
							<div className='locked-input'>
								<label>{languages[language].purchaseOrLeaseLabel}</label>
								<span>{formatDate(purchaseDate)}</span>
							</div>
						)}
						<h5>{languages[language].customerEligibilityLabel}</h5>
						{DDREF && (
							<div className='locked-input'>
								<label>{languages[language].documentNumberLabel}</label>
								<span>{DDREF}</span>
							</div>
						)}
						{customerNumber && (
							<div className='locked-input'>
								<label>{languages[language].customerNumberLabel}</label>
								<span>{customerNumber}</span>
							</div>
						)}
						{postalCode && (
							<div className='locked-input'>
								<label>{languages[language].postalCodeLabel}</label>
								<span>{postalCode}</span>
							</div>
						)}
						{dateOfBirth && (
							<div className='locked-input'>
								<label>{languages[language].dateOfBirthLabel}</label>
								<span>{dateOfBirth}</span>
							</div>
						)}
						{customerName && (
							<div className='locked-input'>
								<label>
									{isCorporate
										? languages[language].companyNameLabel
										: languages[language].customerNameLabel}
								</label>
								<span>{customerName}</span>
							</div>
						)}
					</div>
					<div>
						<h5>{languages[language].vehiclePurchaseInformationLabel}</h5>
						{purchaseOrLease && (
							<div className='locked-input'>
								<label>{languages[language].purchaseOrLeaseLabel}</label>
								<span>{capitalize(purchaseOrLease)}</span>
							</div>
						)}
						{purchaseOrLease === 'purchase' && (
							<div className='locked-input'>
								<label>{languages[language].newOrUsedLabel}</label>
								<span>{newOrUsed}</span>
							</div>
						)}
						{newOrUsed === 'used' && (
							<div className='locked-input'>
								<label>{languages[language].purchasePriceLabel}</label>
								<span>{purchasePrice}</span>
							</div>
						)}
						{purchaseOrLease === 'lease' && (
							<div className='locked-input'>
								<label>{languages[language].leaseLengthLabel}</label>
								<span>{leaseLength.label}</span>
							</div>
						)}
						<h5>{languages[language].customerInformationLabel}</h5>
						<div className='locked-input mailing-address'>
							<label>{languages[language].mailingAddressLabel}</label>
							{addressOne && <span>{addressOne}</span>}
							{addressTwo && <span>{addressTwo}</span>}
							{city && province && (
								<span>
									{capitalize(city)}, {capitalize(province)}
								</span>
							)}
							{postalCode && <span>{formatPostalCode(postalCode)}</span>}
						</div>
						<h5>{languages[language].emailAddress}</h5>
						{email && (
							<div className='locked-input'>
								<label>{languages[language].emailAddressLabel}</label>
								<span>{email}</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ReviewSubmission;
