import React from 'react';
import VinForm from './components/forms/VinForm';

import './App.css';

function App() {
	return (
		<div className='App'>
			<VinForm />
		</div>
	);
}

export default App;
