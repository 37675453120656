import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import App from './App';
import CustomerEligibility from './components/CustomerEligibility';
import ApplicationSubmission from './components/ApplicationSubmission';
import SubmissionSuccess from './components/SubmissionSuccess';
import ProgramOverview from './components/ProgramOverview';

// Render the main App component if the div with ID 'root' exists
const vinEligibilityElement = document.getElementById('vin-eligibility');
if (vinEligibilityElement) {
	const vinEligibilityRoot = createRoot(vinEligibilityElement);
	vinEligibilityRoot.render(
		<React.StrictMode>
			<Provider store={store}>
				<App />
			</Provider>
		</React.StrictMode>
	);
} else {
}

// Render the CustomerEligibility component if the div with ID 'customer-eligibility' exists
const customerEligibilityElement = document.getElementById(
	'customer-eligibility'
);
if (customerEligibilityElement) {
	const customerEligibilityRoot = createRoot(customerEligibilityElement);
	customerEligibilityRoot.render(
		<React.StrictMode>
			<Provider store={store}>
				<CustomerEligibility />
			</Provider>
		</React.StrictMode>
	);
} else {
}

// Render the CustomerEligibility component if the div with ID 'customer-eligibility' exists
const applicationSubmissionElement = document.getElementById('application');
if (applicationSubmissionElement) {
	const applicationSubmissionRoot = createRoot(applicationSubmissionElement);
	applicationSubmissionRoot.render(
		<React.StrictMode>
			<Provider store={store}>
				<ApplicationSubmission />
			</Provider>
		</React.StrictMode>
	);
} else {
}
// Render the CustomerEligibility component if the div with ID 'customer-eligibility' exists
const applicationStatusElement = document.getElementById('application-status');
if (applicationStatusElement) {
	const applicationStatusRoot = createRoot(applicationStatusElement);
	applicationStatusRoot.render(
		<React.StrictMode>
			<Provider store={store}>
				<CustomerEligibility isStatusTracker={true} />
			</Provider>
		</React.StrictMode>
	);
} else {
}
// Render the print component if the div with ID 'customer-eligibility' exists
const overviewElement = document.getElementById('program-overview');
if (overviewElement) {
	const overviewRoot = createRoot(overviewElement);
	overviewRoot.render(
		<React.StrictMode>
			<Provider store={store}>
				<ProgramOverview />
			</Provider>
		</React.StrictMode>
	);
} else {
}
