import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import Notification from './ui/Notification';
import PrinterIcon from './icons/PrinterIcon';

import '../styles/Print.css';

const SubmissionSuccess = ({
	isSubmission,
	goToNextStep,
	goToVehicleInformation,
	goToCustomerInformation,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const [formValidation, setFormValidation] = useState({
		error: '',
		warning: '',
		success: '',
	});
	const [errorType, setErrorType] = useState('');

	function formatDate(date) {
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	}

	const VIN = useSelector((state) => state.dataStorage.storedVIN);
	const purchaseDate = useSelector(
		(state) => state.dataStorage.storedPurchaseDate
	);
	const DDREF = useSelector((state) => state.dataStorage.storedDDREF);
	const customerNumber = useSelector(
		(state) => state.dataStorage.storedCustomerNumber
	);
	const postalCode = useSelector((state) => state.dataStorage.storedPostalCode);
	const dateOfBirth = useSelector((state) => state.dataStorage.storedDOB);
	const customerName = useSelector((state) => state.dataStorage.storedLastName);
	const isCorporate = useSelector(
		(state) => state.dataStorage.storedIsCorporate
	);
	const purchaseOrLease = useSelector(
		(state) => state.dataStorage.storedPurchaseOrLease
	);
	const newOrUsed = useSelector((state) => state.dataStorage.storedNewOrUsed);
	const purchasePrice = useSelector(
		(state) => state.dataStorage.storedPurchasePrice
	);
	const leaseLength = useSelector(
		(state) => state.dataStorage.storedLeaseLength
	);
	const addressOne = useSelector((state) => state.dataStorage.storedAddressOne);
	const addressTwo = useSelector((state) => state.dataStorage.storedAddressTwo);
	const city = useSelector((state) => state.dataStorage.storedCity);
	const province = useSelector((state) => state.dataStorage.storedProvince);
	const email = useSelector((state) => state.dataStorage.storedEmail);

	// Submission date
	const currentDate = new Date();
	const formattedDate = formatDate(currentDate);
	const submitted = formattedDate;

	function capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	setTimeout(() => {
		setErrorType('success');
		setFormValidation({
			success: languages[language].success,
		});
	}, 250);

	const languages = {
		en: {
			success:
				'Thank you for your application. We will review the information based on the program’s eligibility criteria and will notify you by mail within 60 days. <br><br> Come back later to <a href="/application-status">check the status</a> of your application. If you have questions, email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			printSuccess:
				'Thank you for your application. We will review the information based on the program’s eligibility criteria and will notify you by mail within 60 days. <br><br><strong>Check the status of your application at <a href="evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a></strong>. If you have questions, email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			print: 'Print confirmation',
			returnToHome: 'Return to the homepage',
			EVTitle: 'Electric Vehicle Rebate application',
			applicationSubmitted: 'Application submitted',
			vehicleEligiblity: 'Vehicle eligibility',
			VINLabel: 'Vehicle Identification Number (VIN)',
			purchaseOrLeaseDateLabel: 'Purchase or lease effective date',
			customerEligibility: 'Customer eligibility',
			DDRefLabel: 'Document Number (DD/REF)',
			customerNumber: 'Customer number',
			postalCode: 'Postal code',
			dateOfBirth: 'Date of birth',
			companyName: 'Company name',
			lastName: 'Last name',
			vehiclePurchaseOrLease: 'Vehicle purchase or lease information',
			purchaseOrLeaseLabel: 'Vehicle purchase or lease',
			newOrUsed: 'New or used vehicle',
			purchasePrice: 'Purchase price before tax',
			leaseLength: 'Length of lease',
			customerInformation: 'Customer information',
			mailingAddress: 'Mailing address',
			emailAddress: 'Email address',
		},
		fr: {
			success:
				'Merci d’avoir soumis votre demande. Nous examinerons les renseignements en fonction des critères d’admissibilité au programme et communiquerons avec vous par courrier dans les 60 jours.<br> Vérifiez l’état de votre demande en écrivant à <a href="https://evrebate.mpi.mb.ca">evrebate.mpi.mb.ca</a>. Si vous avez des questions, veuillez faire parvenir un courriel à <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			printSuccess:
				'Merci d’avoir soumis votre demande. Nous examinerons les renseignements en fonction des critères d’admissibilité au programme et communiquerons avec vous par courrier dans les 60 jours.<br> Vérifiez l’état de votre demande en écrivant à <a href="https://evrebate.mpi.mb.ca">evrebate.mpi.mb.ca</a>. Si vous avez des questions, veuillez faire parvenir un courriel à <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			print: 'Imprimer la confirmation',
			returnToHome: 'Retour à l’accueil',
			EVTitle: 'Demande de Remboursement à l’achat d’un véhicule électrique',
			applicationSubmitted: 'Demande soumise',
			vehicleEligiblity: 'Éligibilité du véhicule',
			VINLabel: 'Numéro d’identification de véhicule (NIV)',
			purchaseOrLeaseDateLabel: "Date d'achat ou de location effective",
			customerEligibility: 'Éligibilité du client',
			DDRefLabel: 'Numéro de document (DD/REF)',
			customerNumber: 'Numéro de référence de client',
			postalCode: 'Code postal',
			dateOfBirth: 'Date de naissance',
			companyName: "Nom de l'entreprise",
			lastName: 'Nom de famille',
			vehiclePurchaseOrLease:
				"Informations sur l'achat ou la location du véhicule",
			purchaseOrLeaseLabel: 'Achat ou location du véhicule',
			newOrUsed: "Véhicule neuf ou d'occasion",
			purchasePrice: "Prix d'achat avant taxes",
			leaseLength: 'Durée de la location',
			customerInformation: 'Renseignements sur le client ',
			mailingAddress: 'Adresse postale officielle',
			emailAddress: 'Adresse e-mail',
		},
	};

	function toTitleCase(str) {
		return str.replace(/\w\S*/g, (txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	return (
		<>
			<div className='form-container final'>
				<AnimatePresence>
					{errorType && (
						<Notification type={errorType}>
							<p
								dangerouslySetInnerHTML={{
									__html: formValidation[errorType],
								}}
							></p>
						</Notification>
					)}
				</AnimatePresence>
				<form>
					<div className='button-container'>
						<button
							type='button'
							className='secondary'
							onClick={() => {
								window.print();
							}}
						>
							<PrinterIcon /> {languages[language].print}
						</button>
						<button
							type='button'
							className='primary'
							onClick={() => {
								window.location.href = '/';
							}}
						>
							{languages[language].returnToHome}
						</button>
					</div>
				</form>
			</div>
			<div className='print-container'>
				<span class='brand-wrapper'>
					<a
						class='navbar-brand'
						data-en-url='/?lang=en'
						data-fr-url='/?lang=fr'
						href='/?lang=en'
					>
						<img
							class='nav-logo'
							src='/wp-content/themes/evincentive/img/mpi-logo.svg'
							alt='Manitoba Public Insurance'
							data-en='Manitoba Public Insurance'
							data-en-img='/wp-content/themes/evincentive/img/mpi-logo.svg'
							data-fr="Société d'assurance publique du Manitoba"
							data-fr-img='/wp-content/themes/evincentive/img/mpi-logo.svg'
							title='Manitoba Public Insurance'
						/>
						<img
							class='nav-logo-text'
							src='/wp-content/themes/evincentive/img/mpi-en.svg'
							alt='Manitoba Public Insurance'
							data-en='Manitoba Public Insurance'
							data-en-img='/wp-content/themes/evincentive/img/mpi-en.svg'
							data-fr="Société d'assurance publique du Manitoba"
							data-fr-img='/wp-content/themes/evincentive/img/mpi-fr.svg'
							title='Manitoba Public Insurance'
						/>
					</a>
					<a
						class='navbar-gov-brand'
						data-en-url='https://www.gov.mb.ca/index.html'
						data-fr-url='https://www.gov.mb.ca/index.fr.html'
						href='https://www.gov.mb.ca/index.html'
					>
						<img
							class='prov-logo-text'
							src='/wp-content/themes/evincentive/img/govt-text.svg'
							alt='Government of Manitoba'
							data-en='Government of Manitoba'
							data-en-img='/wp-content/themes/evincentive/img/govt-text.svg'
							data-fr='Gouvernement du Manitoba'
							data-fr-img='/wp-content/themes/evincentive/img/govt-text.svg'
							title='Government of Manitoba'
						/>
						<img
							class='prov-logo'
							src='/wp-content/themes/evincentive/img/govt-logo.svg'
							alt='Government of Manitoba'
							data-en='Government of Manitoba'
							data-en-img='/wp-content/themes/evincentive/img/govt-logo.svg'
							data-fr='Gouvernement du Manitoba'
							data-fr-img='/wp-content/themes/evincentive/img/govt-logo.svg'
							title='Government of Manitoba'
						/>
					</a>
				</span>
				<h2>{languages[language].EVTitle}</h2>
				<Notification type={errorType}>
					<p
						dangerouslySetInnerHTML={{
							__html: languages[language].printSuccess,
						}}
					></p>
				</Notification>
				<div className='columns'>
					<div>
						{submitted && (
							<h5 style={{ marginBottom: '12px' }}>
								{languages[language].applicationSubmitted}
							</h5>
						)}
						{submitted && <span>{submitted}</span>}
						<h5>{languages[language].vehicleEligiblity}</h5>
						<div className='locked-input'>
							<label>{languages[language].VINLabel}</label>
							<span>{VIN}</span>
						</div>
						<div className='locked-input'>
							<label>{languages[language].purchaseOrLeaseDateLabel}</label>
							<span>{formatDate(purchaseDate)}</span>
						</div>
						<h5>{languages[language].customerEligibility}</h5>
						{DDREF && (
							<div className='locked-input'>
								<label>{languages[language].DDRefLabel}</label>
								<span>{DDREF}</span>
							</div>
						)}
						{customerNumber && (
							<div className='locked-input'>
								<label>{languages[language].customerNumber}</label>
								<span>{customerNumber}</span>
							</div>
						)}
						<div className='locked-input'>
							<label>{languages[language].postalCode}</label>
							<span>{postalCode}</span>
						</div>
						{dateOfBirth && (
							<div className='locked-input'>
								<label>{languages[language].dateOfBirth}</label>
								<span>{dateOfBirth}</span>
							</div>
						)}
						{customerName && (
							<div className='locked-input'>
								<label>
									{isCorporate
										? languages[language].companyName
										: languages[language].lastName}
								</label>
								<span>{customerName.replace(/%20/g, ' ')}</span>
							</div>
						)}
					</div>
					<div>
						<h5>{languages[language].vehiclePurchaseOrLease}</h5>
						<div className='locked-input'>
							<label>{languages[language].purchaseOrLeaseLabel}</label>
							<span>{capitalize(purchaseOrLease)}</span>
						</div>
						{purchaseOrLease === 'purchase' && (
							<div className='locked-input'>
								<label>{languages[language].newOrUsed}</label>
								<span>{toTitleCase(newOrUsed)}</span>
							</div>
						)}
						{newOrUsed === 'used' && (
							<div className='locked-input'>
								<label>{languages[language].purchasePrice}</label>
								<span>${purchasePrice}</span>
							</div>
						)}
						{purchaseOrLease === 'lease' && (
							<div className='locked-input'>
								<label>{languages[language].leaseLength}</label>
								<span>{leaseLength.label}</span>
							</div>
						)}
						<h5>{languages[language].customerInformation}</h5>
						<div className='locked-input mailing-address'>
							<label>{languages[language].mailingAddress}</label>
							<span>{addressOne}</span>
							<span>{addressTwo}</span>
							<span>
								{city}, {province}
							</span>
							<span>{postalCode}</span>
						</div>
						<h5>{languages[language].emailAddress}</h5>
						<div className='locked-input'>
							<label>{languages[language].emailAddress}</label>
							<span>{email}</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SubmissionSuccess;
