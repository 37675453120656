import { motion } from 'framer-motion';
import '../../styles/ui/Hero.css';

const Hero = ({ children, background }) => {
	return (
		<section className='hero' style={{ backgroundImage: `url(${background})` }}>
			<div className='container'>{children}</div>
		</section>
	);
};

export default Hero;
