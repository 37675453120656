import React from 'react';

const FormHeader = ({ heading, formStep }) => {
	return (
		<div className='form-header'>
			<span>{formStep}</span>
			<h3>{heading}</h3>
		</div>
	);
};

export default FormHeader;
