import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import DatePicker from 'react-datepicker';

import '../../styles/forms.css';
import 'react-datepicker/dist/react-datepicker.css';

import ButtonLink from '../ui/ButtonLink';
import PostalCodeInput from '../inputFields/PostalCodeInput';
import TextInput from '../inputFields/TextInput';
import Notification from '../ui/Notification';
import NoteAccordion from '../ui/NoteAccordion';
import OSTerms from '../inputFields/OSTerms';

import { fadeVariant, slideVariant } from '../../utils/motionVariants';

import {
	setEncryptedCustomerNumberStorage,
	setPostalCodeStorage,
	setDobStorage,
	setDdrefStorage,
	setCorrelationID,
} from '../../redux/dataStorageReducer';
import RightArrow from '../icons/RightArrow';

const CustomerHasLicense = ({
	isSubmission = false,
	isStatusTracker = false,
	hasError = false,
	goToNextStep,
	setEncryptedPartyID,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const [customerFormData, setCustomerFormData] = useState({
		DDRef: '',
		postalCode: '',
		dateOfBirth: null,
	});
	const [formErrors, setFormErrors] = useState({});
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [formValidation, setFormValidation] = useState({
		error: '',
		warning: '',
		success: '',
	});
	const [errorType, setErrorType] = useState('');

	const correlationIDStorage = useSelector(
		(state) => state.dataStorage.storedCorrelationID
	);

	const handleClearFields = (e) => {
		e.preventDefault();
		setCustomerFormData({
			DDRef: '',
			postalCode: '',
			dateOfBirth: null,
		});
		setIsChecked(false);
		setIsDisabled(false);
		setErrorType('');
		setIsValid(false);
		setFormErrors({});
	};

	const dispatch = useDispatch();

	const postalCodeRef = useRef();

	const currentDate = new Date();
	const lastDayOfMonth = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth() + 1,
		0
	);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const errors = {};

		// DD Ref Validations
		if (!customerFormData.DDRef) {
			errors.DDRef = languages[language].DDRefEmptyError;
		} else if (customerFormData.DDRef.length !== 9) {
			errors.DDRef = languages[language].DDRefLengthError;
		}

		// Validate postal code using the ref
		if (customerFormData.postalCode.length < 1) {
			errors.postalCode = languages[language].postalCodeEmptyError;
		} else {
			const isPostalCodeValid = postalCodeRef.current.validatePostalCode();
			if (!isPostalCodeValid) {
				errors.postalCode = languages[language].postalCodeInvalidError;
			}
		}

		// Date of Birth Validations
		if (!customerFormData.dateOfBirth) {
			errors.dateOfBirth = languages[language].DOBEmptyError;
		} else if (customerFormData.dateOfBirth > currentDate) {
			errors.dateOfBirth = languages[language].DOBFutureDateError;
		} else {
			const ageDiff =
				currentDate.getFullYear() - customerFormData.dateOfBirth.getFullYear();
			const monthDiff =
				currentDate.getMonth() - customerFormData.dateOfBirth.getMonth();
			const dayDiff =
				currentDate.getDate() - customerFormData.dateOfBirth.getDate();

			const isAtLeast16 =
				ageDiff > 16 ||
				(ageDiff === 16 &&
					(monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));

			if (!isAtLeast16) {
				errors.dateOfBirth = languages[language].DOBNotOldEnoughError;
			}
		}

		if (!isSubmission && !isChecked) {
			errors.terms = languages[language].termsError;
		}

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		setFormErrors({});

		// Strip spaces from the postal code
		const formattedPostalCode = customerFormData.postalCode
			.replace(/\s+/g, '')
			.toUpperCase();

		// Format date of birth to YYYY-MM-DD
		const formattedDateOfBirth = customerFormData.dateOfBirth
			.toISOString()
			.split('T')[0];

		// Create request payload
		let payload = {
			ddref: customerFormData.DDRef,
			postal_code: formattedPostalCode,
			date_of_birth: formattedDateOfBirth,
			correlationID: correlationIDStorage,
		};
		if (isLoading) {
		} else {
			setIsLoading(true);

			try {
				const response = await fetch('/wp-json/custom/v1/validate-party', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				});

				const data = await response.json();
				if (data.correlationID) {
					dispatch(setCorrelationID(data.correlationID));
					payload = {
						...payload,
						correlationID: data.correlationID,
					};
				}

				if (data.success) {
					if (
						data.api_response.errorCode === 'http.TIMEOUT' ||
						data.api_response.errorCode === 'http.INTERNAL_SERVER_ERROR'
					) {
						setFormValidation({
							error: languages[language].unexpectedError,
						});
						setErrorType('error');
						setIsDisabled(true);
						setIsLoading(false);
					} else {
						if (data.api_response.partyId === null) {
							setFormValidation({
								error: languages[language].pleaseConfirmInformation,
							});
							setIsDisabled(true);
							setErrorType('error');
						} else {
							if (isStatusTracker) {
								setEncryptedPartyID(data.api_response.encrypted_party_id);
								setIsLoading(false);
								setIsDisabled(true);
							} else {
								try {
									const listData = await fetch(
										'/wp-json/custom/v1/check-customer-list',
										{
											method: 'POST',
											headers: {
												'Content-type': 'application/json',
											},
											body: JSON.stringify({
												party_id: data.api_response.encrypted_party_id,
												correlationID: payload.correlationID,
											}),
										}
									);

									const listResponse = await listData.json();
									if (listResponse.success) {
										setFormValidation({
											success: languages[language].customerIsEligible,
										});
										setIsDisabled(true);
										setIsValid(true);
										setErrorType('success');
										dispatch(
											setEncryptedCustomerNumberStorage(
												data.api_response.encrypted_party_id
											)
										);
										dispatch(setDdrefStorage(payload.ddref));
										dispatch(setDobStorage(payload.date_of_birth));
										dispatch(setPostalCodeStorage(payload.postal_code));
									} else {
										if (listResponse.message === 'maybe') {
											setFormValidation({
												warning: languages[language].customerSoftNo,
											});
											setErrorType('warning');
											setIsLoading(false);
											setIsDisabled(true);
											setIsValid(true);
											dispatch(
												setEncryptedCustomerNumberStorage(
													data.api_response.encrypted_party_id
												)
											);
											dispatch(setDdrefStorage(payload.ddref));
											dispatch(setDobStorage(payload.date_of_birth));
											dispatch(setPostalCodeStorage(payload.postal_code));
										} else {
											setFormValidation({
												error: languages[language].customerNotEligible,
											});
											setErrorType('error');
											setIsLoading(false);
											setIsDisabled(true);
										}
									}
								} catch {
									setFormValidation({
										error: languages[language].unableToVerify,
									});
									setErrorType('error');
									setIsDisabled(true);
								}
							}
						}
					}
				} else {
					if (data.api_response.errorCode === 'http.TIMEOUT') {
						setFormValidation({
							error: languages[language].unexpectedError,
						});
						setErrorType('error');
						setIsLoading(false);
						setIsDisabled(true);
					} else {
						setFormValidation({
							error: languages[language].pleaseConfirmInformation,
						});
						setErrorType('error');
						setIsLoading(false);
						setIsDisabled(true);
					}
				}
			} catch (error) {
				console.error('Error:', error);
				setIsLoading(false);
				setIsDisabled(true);
				setFormValidation({
					error: languages[language].unableToVerify,
				});
				setErrorType('error');
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'DDRef') {
			setCustomerFormData({
				...customerFormData,
				[name]: value.slice(0, 9),
			});
		} else if (name === 'postalCode') {
			setCustomerFormData({
				...customerFormData,
				[name]: value.slice(0, 7),
			});
		} else {
			setCustomerFormData({
				...customerFormData,
				[name]: value,
			});
		}

		setFormErrors({
			...formErrors,
			[name]: '',
		});
	};

	useEffect(() => {
		if (hasError === true) {
			setFormValidation({
				error: languages[language].statusError,
			});
			setErrorType('error');
			setIsLoading(false);
		}
	}, [hasError]);

	const languages = {
		en: {
			required: 'All fields are required.',
			DDRefLabel: 'Document number (DD/RÉF)',
			DDRefHelper:
				'This is located on the front of your driver’s licence, driver’s licence addendum, or temporary driver’s licence certificate.',
			DDRefLengthError:
				'Your document (DD/RÉF) number must be 9 characters long.',
			DDRefEmptyError: 'Please provide your document (DD/RÉF) number.',
			DOBLabel: 'Date of birth',
			DOBPlaceholder: 'mm/dd/yyyy',
			DOBEmptyError: 'Please enter your date of birth.',
			DOBNotOldEnoughError: 'You must be 16 years or older to apply.',
			DOBFutureDateError: 'Your date of birth cannot be in the future.',
			postalCodeEmptyError:
				'Please enter a six-character postal code in the format ‘A1A 1A1’.',
			postalCodeInvalidError:
				'Please enter a six-character postal code in the format ‘A1A 1A1’.',
			termsError:
				'Please review and agree to the Terms of Use before continuing.',
			pleaseConfirmInformation:
				'We could not verify your identity with the information in our system. Please ensure all information was entered correctly and resubmit.',
			customerIsEligible: 'You are eligible to apply.',
			customerSoftNo:
				'We are unable to confirm your eligibility. If you already submitted, you can check the status of the application or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca.</a> ',
			customerSoftNoSubmission:
				'We are unable to confirm your eligibility. If you already submitted, you can check the status of the application or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca.</a> ',
			customerNotEligible:
				'Your are not eligible for the EV Rebate program. If you already submitted an application, you can check the status of the application or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			unableToVerify:
				'An unexpected error has occurred, please try again later.',
			unexpectedError:
				'An unexpected error has occurred, please try again later.',
			CheckEligibility: 'Check eligibility',
			Continue: 'Continue',
			StartOver: 'Start over',
			noteAccordionTitle: 'Need more help finding your document number?',
			noteAccordionContent: (
				<>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/drivers-licence-enlargement-small.webp`}
						alt="Driver's Licence"
					/>
					<span className='bold mb-8'>Manitoba Driver's Licence Card</span>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/drivers-licence-addendum-enlargement-small.webp`}
						alt="Driver's Licence Addendum"
					/>
					<span className='bold mb-2'>Manitoba Driver's Licence Addendum</span>
					<span className='mb-2'>or</span>
					<span className='bold mb-2'>
						Manitoba Temporary Driver's Licence Certificate
					</span>
					<ButtonLink
						icon='magnify'
						text='Show More'
						link='https://apps.mpi.mb.ca/ddref/'
						type='primary'
						additionalClasses='mt-8'
						isExternal={true}
					/>
				</>
			),
			statusError:
				'We could not find an application with this information. Please try again or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
		},
		fr: {
			required: 'Tous les champs doivent obligatoirement être remplis.',
			DDRefLabel: 'Numéro de document (DD/RÉF)',
			DDRefHelper:
				'Il se trouve à l’avant de votre permis de conduire, sur votre document d’ajout au permis de conduire ou sur votre certificat de permis de conduire temporaire.',
			DDRefLengthError:
				'Votre numéro de document (DD/RÉF) doit comporter 9 caractères.',
			DDRefEmptyError:
				'Veuillez indiquer votre numéro de réclamation à huit chiffres.',
			DOBLabel: 'Date de naissance',
			DOBPlaceholder: 'jj/mm/aaaa',
			DOBEmptyError: 'Veuillez fournir votre date de naissance.',
			DOBNotOldEnoughError:
				'Vous devez avoir 16 ans ou plus pour faire une demande.',
			DOBFutureDateError: 'Votre date de naissance ne peut être dans le futur.',
			postalCodeEmptyError:
				'Veuillez entrer un code postal à six caractères au format « A1A 1A1 ».',
			postalCodeInvalidError:
				'Veuillez entrer un code postal à six caractères au format « A1A 1A1 ».',
			termsError:
				'Veuillez vérifier votre demande et accepter les modalités d’utilisation des services en ligne avant de poursuivre.',
			pleaseConfirmInformation:
				"Une erreur s'est produite lors de la vérification de votre admissibilité, veuillez confirmer vos informations.",
			customerIsEligible: 'Vous êtes admissible pour faire une demande. ',
			customerSoftNo: "Le client n'est pas éligible, contactez CVP",
			customerSoftNoSubmission: 'Client non éligible, contactez CVP',
			customerNotEligible: "Ce client n'est pas éligible.",
			unableToVerify:
				'Nous ne pouvons pas vérifier votre identité avec les informations fournies.',
			unexpectedError:
				"Une erreur inattendue s'est produite, veuillez réessayer plus tard.",
			CheckEligibility: "Vérifier l'admissibilité",
			Continue: 'Continuer',
			StartOver: 'Recommencer',
			noteAccordionTitle:
				'Besoin d’aide supplémentaire pour trouver votre numéro de document?',
			noteAccordionContent: (
				<>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/drivers-licence-enlargement-small.webp`}
						alt='Permis de conduire'
					/>
					<span className='bold mb-8'>
						Sur le certificat de permis de conduire temporaire du Manitoba
					</span>
					<img
						src={`/wp-content/plugins/ev-incentive-react/build/images/drivers-licence-addendum-enlargement-small.webp`}
						alt='Addendum au permis de conduire'
					/>
					<span className='bold mb-2'>
						Sur le document d’ajout au permis de conduire du Manitoba
					</span>
					<span className='mb-2'>Ou</span>
					<span className='bold mb-2'>
						Sur le certificat de permis de conduire temporaire du Manitoba
					</span>
					<ButtonLink
						icon='magnify'
						text='Afficher plus'
						link='https://apps.mpi.mb.ca/ddref/?lang=fr'
						type='primary'
						additionalClasses='mt-8'
						isExternal={true}
					/>
				</>
			),
			statusError:
				'Nous n’avons pas trouvé de demande avec cette information. Veuillez réessayer ou écrire à <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
		},
	};

	if (isStatusTracker) {
		languages.en.CheckEligibility = 'Check status';
		languages.fr.CheckEligibility = 'Vérifier le statut';
	}

	return (
		<React.Fragment>
			<p className='required-fields'>{languages[language].required}</p>
			<form onSubmit={handleSubmit}>
				<TextInput
					label={languages[language].DDRefLabel}
					name='DDRef'
					value={customerFormData.DDRef}
					onChange={handleChange}
					error={formErrors.DDRef}
					isDisabled={isDisabled}
					helperText={languages[language].DDRefHelper}
				/>
				<NoteAccordion
					titleText={languages[language].noteAccordionTitle}
					content={languages[language].noteAccordionContent}
				/>
				<PostalCodeInput
					ref={postalCodeRef}
					name='postalCode'
					value={customerFormData.postalCode}
					onChange={handleChange}
					error={formErrors.postalCode}
					isDisabled={isDisabled}
				/>
				<div className='text-input'>
					<label>{languages[language].DOBLabel}</label>
					<DatePicker
						selected={customerFormData.dateOfBirth}
						placeholderText={languages[language].DOBPlaceholder}
						onChange={(date) => {
							setFormErrors({ dateOfBirth: '' });
							setCustomerFormData({ ...customerFormData, dateOfBirth: date });
						}}
						showMonthDropdown
						showYearDropdown
						maxDate={lastDayOfMonth}
						dropdownMode='select'
						autoComplete='off'
						className={formErrors.dateOfBirth ? 'error' : ''}
						disabled={isDisabled}
					/>
					<AnimatePresence>
						{formErrors.dateOfBirth && (
							<motion.div
								key={customerFormData.dateOfBirth}
								initial='hidden'
								animate='visible'
								exit='hidden'
								variants={fadeVariant}
								transition={{ duration: 0.2, ease: 'easeInOut' }}
								className='error-message'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='14'
									viewBox='0 0 16 14'
									fill='none'
								>
									<path
										d='M7.64124 0C7.55203 7.24657e-05 7.46452 0.0243969 7.38807 0.0703707C7.31163 0.116344 7.24912 0.182238 7.20724 0.261L0.0682352 13.224C0.0238521 13.3018 0.000346625 13.3897 3.80443e-06 13.4793C-0.000339016 13.5688 0.0224925 13.6569 0.0662788 13.735C0.110065 13.8131 0.173317 13.8786 0.249884 13.925C0.326452 13.9714 0.41373 13.9973 0.503235 14H14.7792C14.8687 13.9973 14.956 13.9714 15.0326 13.925C15.1092 13.8786 15.1724 13.8131 15.2162 13.735C15.26 13.6569 15.2828 13.5688 15.2825 13.4793C15.2821 13.3897 15.2586 13.3018 15.2142 13.224L8.07524 0.261C8.03335 0.182238 7.97084 0.116344 7.8944 0.0703707C7.81795 0.0243969 7.73044 7.24657e-05 7.64124 0ZM8.39124 11.5H6.89124V10H8.39124V11.5ZM8.39124 8.75H6.89124V5H8.39124V8.75Z'
										fill='#DC2E28'
									/>
								</svg>{' '}
								<span>{formErrors.dateOfBirth}</span>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
				<AnimatePresence>
					{errorType && (
						<Notification type={errorType}>
							<p
								dangerouslySetInnerHTML={{
									__html: formValidation[errorType],
								}}
							></p>
						</Notification>
					)}
				</AnimatePresence>
				{!isSubmission && (
					<OSTerms
						checked={isChecked}
						onChange={(e) => {
							setIsChecked(e.target.checked);
							setFormErrors((prevErrors) => ({ ...prevErrors, terms: '' }));
						}}
						error={formErrors.terms}
						isDisabled={isDisabled}
					/>
				)}
				<div className='button-container'>
					<button
						className={`${isDisabled ? 'disabled' : ''} primary`}
						type='submit'
						disabled={isDisabled}
					>
						<AnimatePresence mode='wait'>
							{isLoading ? (
								<motion.span
									key='spinner'
									initial='hidden'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{ duration: 0.2, ease: 'easeInOut', delay: 0.2 }}
								>
									<div className='button-spinner'></div>
									<span className={isLoading ? 'opacity-0' : ''}>
										{languages[language].CheckEligibility}
									</span>
								</motion.span>
							) : (
								<motion.span
									key='eligibility'
									initial='visible'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{ duration: 0.2, ease: 'easeInOut', delay: 0.2 }}
								>
									{languages[language].CheckEligibility}
								</motion.span>
							)}
						</AnimatePresence>
					</button>
					{isSubmission && (
						<button
							className={`${!isValid ? 'disabled' : ''} primary`}
							onClick={() => goToNextStep()}
							disabled={!isValid}
						>
							<>
								{languages[language].Continue} <RightArrow />
							</>
						</button>
					)}
					<AnimatePresence>
						{isDisabled && (
							<motion.button
								key='startOver'
								className={`${!isDisabled ? 'disabled' : ''} primary`}
								onClick={(e) => handleClearFields(e)}
								type='submit'
								initial='visible'
								animate='visible'
								exit='hidden'
								variants={fadeVariant}
								transition={{
									duration: 0.2,
									ease: 'easeInOut',
									delay: 0.2,
								}}
							>
								<>{languages[language].StartOver}</>
							</motion.button>
						)}
					</AnimatePresence>
				</div>
			</form>
		</React.Fragment>
	);
};

export default CustomerHasLicense;
