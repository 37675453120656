import React from 'react';

const BuyingEVIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='58'
			height='55'
			viewBox='0 0 58 55'
			fill='none'
		>
			<path
				d='M30.6397 15.4511H37.8652V1H30.6397C26.6499 1 23.4142 4.23568 23.4142 8.22553C23.4142 12.2154 26.6499 15.4511 30.6397 15.4511Z'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M45.0928 4.61328H37.8672'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M45.0928 11.8379H37.8672'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M8.96239 25.3873L11.611 23.4003C12.2365 22.9306 12.9974 22.6777 13.7787 22.6777H32.0073C33.4456 22.6777 34.823 23.249 35.8391 24.2651L39.6709 28.0969'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M24.3154 28.0957V33.5149'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M6.25167 47.0635C6.25167 47.0635 6.38715 47.0635 4.89914 47.0635C3.58951 47.0635 2.4673 46.1264 2.23473 44.8394L0.832519 33.5156'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M37.8643 47.0645H18.8972'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M23.4138 8.22656H13.4787C6.49474 8.22656 0.833985 13.8873 0.833985 20.8712C0.833985 27.8552 6.49474 33.5159 13.4787 33.5159H34.8775C40.9447 33.5159 47.1813 34.1075 53.1355 35.2704C53.6391 35.3697 54.1471 35.4691 54.6552 35.5707C56.0032 35.8416 56.9357 37.0745 56.8251 38.4451C56.6535 40.5473 56.4051 43.5572 56.2538 45.4064C56.177 46.3435 55.3935 47.0615 54.4542 47.0615H50.514'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M44.1884 53.3869C40.6967 53.3869 37.8661 50.5563 37.8661 47.0645C37.8661 43.5728 40.6967 40.7422 44.1884 40.7422C47.6801 40.7422 50.5107 43.5728 50.5107 47.0645C50.5107 50.5563 47.6801 53.3869 44.1884 53.3869Z'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M12.5771 53.3869C9.08534 53.3869 6.25473 50.5563 6.25473 47.0645C6.25473 43.5728 9.08534 40.7422 12.5771 40.7422C16.0688 40.7422 18.8994 43.5728 18.8994 47.0645C18.8994 50.5563 16.0688 53.3869 12.5771 53.3869Z'
				stroke='#383838'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

export default BuyingEVIcon;
