import React from 'react';

const RightArrow = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='15'
			viewBox='0 0 16 15'
			fill='none'
		>
			<g id='16px Frame'>
				<path
					id='Path 10100'
					d='M7.75048 0.145996L15.1035 7.5L7.75048 14.854L7.04248 14.146L13.1895 8L0.896484 8L0.896484 7L13.1895 7L7.04248 0.853996L7.75048 0.145996Z'
					fill='white'
				/>
			</g>
		</svg>
	);
};

export default RightArrow;
