import React, { useState } from 'react';
import { motion } from 'framer-motion';

import '../../styles/NoteAccordion.css';

const NoteAccordion = ({
	titleText,
	content,
	callToAction,
	link,
	contentAlignment = 'center',
	...props
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const containerVariant = {
		hidden: { height: 0, opacity: 0, padding: '0px 24px' },
		visible: { height: 'auto', opacity: 1, padding: '24px 24px' },
	};

	const iconVariant = {
		hidden: { rotate: 0 },
		visible: { rotate: 180 },
	};

	return (
		<div
			className='note-accordion'
			onClick={() => {
				setIsOpen((prevIsOpen) => !prevIsOpen);
			}}
		>
			<div className={`${isOpen ? 'open' : ''} note-accordion__header`}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='17'
					height='16'
					viewBox='0 0 17 16'
					fill='none'
				>
					<circle cx='8.22473' cy='8' r='8' fill='#008193' />
					<path
						d='M7.71078 9.74965C7.71078 9.20343 7.78255 8.76365 7.92611 8.43032C8.06967 8.09699 8.36833 7.73587 8.82211 7.34698C9.19233 7.01365 9.43989 6.73587 9.56478 6.51365C9.69011 6.29143 9.75278 6.0461 9.75278 5.77765C9.75278 5.37054 9.60678 5.03254 9.31478 4.76365C9.02322 4.49521 8.65055 4.36098 8.19678 4.36098C7.84522 4.36098 7.53055 4.45121 7.25278 4.63165C6.975 4.81254 6.76189 5.09743 6.61344 5.48632L5.69678 5.09698C5.919 4.55076 6.25478 4.13187 6.70411 3.84032C7.153 3.54876 7.65056 3.40298 8.19678 3.40298C8.947 3.40298 9.56055 3.62276 10.0374 4.06232C10.5143 4.50232 10.7528 5.06476 10.7528 5.74965C10.7528 6.11098 10.6717 6.45365 10.5094 6.77765C10.3472 7.10165 10.0672 7.44432 9.66944 7.80565C9.26189 8.16654 9.00478 8.46521 8.89811 8.70165C8.79189 8.93765 8.73411 9.28699 8.72478 9.74965H7.71078ZM8.19678 12.597C7.99322 12.597 7.82211 12.5277 7.68344 12.389C7.54433 12.2499 7.47478 12.0785 7.47478 11.875C7.47478 11.6714 7.54433 11.5001 7.68344 11.361C7.82211 11.2223 7.99322 11.153 8.19678 11.153C8.39144 11.153 8.56055 11.2223 8.70411 11.361C8.84767 11.5001 8.91944 11.6714 8.91944 11.875C8.91944 12.0785 8.84767 12.2499 8.70411 12.389C8.56055 12.5277 8.39144 12.597 8.19678 12.597Z'
						fill='white'
					/>
				</svg>
				{titleText && <p className='note-accordion__title'>{titleText}</p>}
				<motion.svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					variants={iconVariant}
					animate={isOpen ? 'visible' : 'hidden'}
				>
					<path
						d='M0.689033 8.311L2.81103 6.189L12 15.379L21.189 6.189L23.311 8.311L12 19.621L0.689033 8.311Z'
						fill='#1FA2B5'
					/>
				</motion.svg>
			</div>
			<motion.div
				className={`note-accordion__content ${contentAlignment}`}
				variants={containerVariant}
				initial='hidden'
				animate={isOpen ? 'visible' : 'hidden'}
			>
				{content}
			</motion.div>
		</div>
	);
};

export default NoteAccordion;
