import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { fadeVariant } from '../../utils/motionVariants';
import ErrorIcon from '../icons/ErrorIcon';

// Online services terms checkbox
const OSTerms = ({
	label,
	checked,
	onChange,
	isDisabled = false,
	error,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const languages = {
		en: {
			label:
				'I agree to the <a target="_blank" href="https://apps.mpi.mb.ca/onlineservices/en/TermsOfUse.html"> Manitoba Public Insurance Online Services - Terms of Use</a>',
		},
		fr: {
			label: `J'accepte les <a target="_blank" href="https://apps.mpi.mb.ca/onlineservices/fr/TermsOfUse.html">modalités d’utilisation des services en ligne de la Société d’assurance publique du Manitoba (SAPM)</a>.`,
		},
	};

	return (
		<div className='checkbox-input'>
			<div>
				<input
					type='checkbox'
					checked={checked}
					onChange={onChange}
					className={error ? 'error' : ''}
					disabled={isDisabled ? 'disabled' : ''}
					{...props}
				/>
				<label
					dangerouslySetInnerHTML={{ __html: languages[language].label }}
				/>
			</div>
			<AnimatePresence>
				{error && (
					<motion.span
						key='terms-error'
						initial='hidden'
						animate='visible'
						exit='hidden'
						variants={fadeVariant}
						transition={{ duration: 0.2, ease: 'easeInOut' }}
						className='error-message'
					>
						<ErrorIcon />
						<span>{error}</span>
					</motion.span>
				)}
			</AnimatePresence>
		</div>
	);
};

export default OSTerms;
