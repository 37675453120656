import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import DatePicker from 'react-datepicker';
import PapaParse from 'papaparse';

import 'react-datepicker/dist/react-datepicker.css';

import {
	setVinStorage,
	setPurchaseDateStorage,
	setOSTermsStorage,
	setMasterListComparison,
	setCorrelationID,
} from '../../redux/dataStorageReducer';
import VinInput from '../inputFields/VinInput';
import OSTerms from '../inputFields/OSTerms';
import TermsAndConditions from '../TermsAndConditions';
import Notification from '../ui/Notification';

import '../../styles/forms.css';
import NoteAccordion from '../ui/NoteAccordion';

import { fadeVariant } from '../../utils/motionVariants';
import RightArrow from '../icons/RightArrow';

const VinForm = ({
	isSubmission = false,
	goToNextStep,
	goToBeginning,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');

	// Local states
	const [vehicleData, setVehicleData] = useState([]);
	const [VIN, setVIN] = useState('');
	const [purchaseDate, setPurchaseDate] = useState();
	const [isChecked, setIsChecked] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [formErrors, setFormErrors] = useState({});
	const [errorType, setErrorType] = useState('');
	const [formValidation, setFormValidation] = useState({
		error: '',
		warning: '',
		success: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [showModal, setShowModal] = useState(true);
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	//Vehicle Eligibility Storage
	const VINStorage = useSelector((state) => state.dataStorage.storedVIN);
	const purchaseDateStorage = useSelector(
		(state) => state.dataStorage.storedPurchaseDate
	);
	const correlationIDStorage = useSelector(
		(state) => state.dataStorage.storedCorrelationID
	);

	useEffect(() => {
		if (VINStorage) {
			setVIN(VINStorage);
		}
		if (purchaseDateStorage) {
			setPurchaseDate(purchaseDateStorage);
		}
	}, [VINStorage, purchaseDateStorage]);

	const dispatch = useDispatch();
	const vinInputRef = useRef(null);

	const hasAgreedToTerms = useSelector(
		(state) => state.dataStorage.storedTerms
	);

	const currentDate = new Date();
	const startDate = new Date(2023, 7, 1);
	const lastDayOfMonth = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth() + 1,
		0
	);

	const languages = {
		en: {
			titleText: 'Check vehicle eligibility',
			enterVin:
				'Thinking about purchasing an electric vehicle and want to know if it’s eligible for the EV Rebate? Check using the Vehicle Identification Number (VIN ), or if you don’t have a VIN, <a href="https://www.manitoba.ca/lowercosts/evrebate/eligible-vehicles.html">check by vehicle model</a>',
			required: 'All fields are required.',
			purchaseDate: 'Purchase or lease effective date',
			purchaseDatePlaceholder: 'mm/dd/yyyy',
			purchaseDateHelperText: 'This will be listed on your Bill of Sale.',
			enterVinError: 'Please enter your VIN.',
			termsError:
				'Please review and agree to the Terms of Use before continuing.',
			enterPurchaseDate: 'Please enter your purchase or lease effective date.',
			futurePurchaseDate:
				'Your purchase or lease effective date cannot be in the future.',
			startPurchaseDate:
				'Only vehicles purchased or leased after August 1, 2023 are eligible for the rebate',
			checkEligibility: 'Check eligibility',
			continue: 'Continue',
			startOver: 'Start over',
			isEligible: 'This vehicle is eligible.',
			notEligibleVIN:
				'This Vehicle Identification Number (VIN) is not registered in Manitoba. Please check the VIN and try again. ',
			notEligible:
				'This vehicle is not eligible for the EV Rebate program. Possible reasons are that it isn’t on the Province of Manitoba’s list of eligible vehicles, or an application has already been submitted.',
			softNoSubmission:
				'MPI will review this Vehicle Identification Number (VIN) to determine eligibility. Do you want to continue or enter a different VIN? ',
			softNoNotSubmission:
				'We can’t confirm this vehicle’s eligibility. If an application for this vehicle has been previously submitted, you can <a href="/application-status">check the status</a> of the application or email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			serverError:
				'An error occurred processing your vehicle information. We apologize for the inconvenience. Please try again later or <a href="/">return to the homepage</a>.',
			vinTitle: 'Need more help finding your VIN?',
			vinContent: (
				<>
					<span className='bold mb-4'>The VIN is usually located on:</span>
					<ul>
						<li>the driver's side of the windshield near the dashboard.</li>
						<li>the driver's side of the door, and</li>
						<li>the vehicle registration</li>
					</ul>
				</>
			),
		},
		fr: {
			titleText: 'Vérifier l’admissibilité du véhicule ',
			enterVin:
				"Vous envisagez d’acheter un véhicule électrique et vous voulez savoir s’il est admissible au remboursement à l’achat d’un VE? Vérifier avec le numéro d’identification de véhicule (NIV), ou, si vous n’en avez pas, vérifier par <a href='https://www.gov.mb.ca/lowercosts/evrebate/'>modèle de véhicule</a> ",
			required: 'Tous les champs doivent obligatoirement être remplis.',
			purchaseDate: 'Date d’achat ou d’entrée en vigueur du bail',
			purchaseDatePlaceholder: 'jj/mm/aaaa',
			purchaseDateHelperText: 'Cette information figure sur votre contrat. ',
			enterVinError: 'Veuillez saisir votre NIV',
			termsError:
				'Veuillez vérifier votre demande et accepter les modalités d’utilisation des services en ligne avant de poursuivre.',
			enterPurchaseDate:
				'Veuillez saisir la date d’achat ou d’entrée en vigueur du bail.',
			futurePurchaseDate:
				'La date d’achat ou d’entrée en vigueur du bail ne peut être dans le futur.',
			startPurchaseDate:
				'Seuls les véhicules achetés ou loués après le 1er août 2023 sont admissibles à l’incitatif.',
			checkEligibility: 'Vérifier l’admissibilité',
			continue: 'Continuer',
			startOver: 'Recommencer',
			isEligible: 'Le véhicule est admissible.',
			notEligibleVIN:
				'Ce numéro d’identification de véhicule (NIV) n’est pas enregistré au Manitoba. Veuillez vérifier le NIV et réessayer. ',
			notEligible:
				'Ce véhicule n’est pas admissible au Programme de remboursement à l’achat ou à la location d’un VE. Raisons possibles : il ne figure pas sur la liste des véhicules admissibles de la province du Manitoba, ou une demande a déjà été soumise.  ',
			softNoSubmission:
				'La SAPM va vérifier ce numéro d’identification de véhicule (NIV) afin de déterminer son admissibilité. Voulez-vous continuer ou entrer un NIV différent? ',
			softNoNotSubmission:
				'Nous ne sommes pas en mesure d’établir l’admissibilité du véhicule. Si une demande a déjà été soumise pour ce véhicule, vous pouvez vérifier le statut de votre demande ou envoyer un courriel à <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			serverError:
				'Une erreur s’est produite et nous n’avons pu récupérer l’information relative à votre demande. Nous vous remercions de votre compréhension. Veuillez réessayer plus tard ou revenir à la page d’accueil.',
			vinTitle: "Besoin d'aide pour trouver votre VIN ?",
			vinContent: (
				<>
					<span className='bold mb-4'>
						Le NIV est généralement indiqué sur{' '}
					</span>
					<ul>
						<li>
							le pare-brise, du côté du conducteur, près du tableau de bord;
						</li>
						<li>la porte, du côté du conducteur;</li>
						<li>le certificat d’immatriculation du véhicule.</li>
					</ul>
				</>
			),
		},
	};

	if (isSubmission) {
		languages.en.enterVin =
			'Enter your Vehicle Identification Number (VIN) to see if your vehicle is eligible for an EV Rebate. ';
		languages.fr.enterVin =
			'Saisissez le numéro d’identification de véhicule (NIV) pour savoir si vous êtes admissible au Programme de remboursement à l’achat ou à la location d’un VE.';
	}

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const lang = params.get('lang');

		if (lang === 'en' || lang === 'fr') {
			setLanguage(lang);
		}
	}, []);

	const handleClearFields = (e) => {
		e.preventDefault();
		setVIN('');
		setPurchaseDate();
		setIsChecked(false);
		setIsDisabled(false);
		setErrorType('');
		setIsValid(false);
		setFormErrors({});
	};

	const parseCSV = (csvData) => {
		try {
			const parsedData = PapaParse.parse(csvData, {
				header: true,
				skipEmptyLines: true, // Skip empty lines
			});
			return parsedData.data;
		} catch (error) {
			console.error('Error parsing CSV:', error);
			return [];
		}
	};

	const loadCSVData = async () => {
		try {
			const response = await fetch(
				`/wp-content/uploads/EV/eligible-vehicles.csv`
			);
			const csvText = await response.text();
			const data = parseCSV(csvText);
			setVehicleData(data);
		} catch (error) {
			console.error('Error loading CSV data:', error);
		} finally {
		}
	};

	useEffect(() => {
		loadCSVData();
	}, []);

	const checkMasterList = (vehicleNumber, year) => {
		const matchingVehicles = vehicleData.filter(
			(vehicle) => vehicle['Vehicle Description Number'] == vehicleNumber
		);

		// Check if any vehicle with the same vehicle number has the matching year
		const vehicleWithMatchingYear = matchingVehicles.find(
			(vehicle) => vehicle['Model Year'] == year
		);

		if (vehicleWithMatchingYear) {
			return vehicleWithMatchingYear['Program State'].toLowerCase();
		} else {
			// If no matching year found, return Program State for vehicles with Model Year as -9999
			const vehicleWithDefaultYear = matchingVehicles.find(
				(vehicle) => vehicle['Model Year'] == '-9999'
			);
			if (vehicleWithDefaultYear) {
				return vehicleWithDefaultYear['Program State'].toLowerCase();
			} else {
				return null; // No vehicle found with matching or default year
			}
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		const errors = {};
		if (!vinInputRef.current || !vinInputRef.current.validateVIN()) {
			errors.VIN = vinInputRef.current
				? vinInputRef.current.validateVIN()
				: languages[language].enterVinError;
		}
		if (!isChecked) {
			errors.terms = languages[language].termsError;
		}
		if (isSubmission && !purchaseDate) {
			errors.purchaseDate = languages[language].enterPurchaseDate;
		} else if (isSubmission && purchaseDate > currentDate) {
			errors.purchaseDate = languages[language].futurePurchaseDate;
		} else if (isSubmission && purchaseDate < startDate) {
			errors.purchaseDate = languages[language].startPurchaseDate;
		}

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		setFormErrors({});

		const newDate = new Date();
		const isoString = newDate.toISOString();

		let payload = {
			vin: VIN.toUpperCase(),
			effective_date: isoString,
			correlationID: correlationIDStorage,
		};

		if (isLoading) {
		} else {
			setIsLoading(true);

			try {
				const response = await fetch('/wp-json/custom/v1/validate-vehicle', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				});
				const data = await response.json();

				// Update correlation ID if received in the response
				if (data.correlationID) {
					dispatch(setCorrelationID(data.correlationID));
					payload = {
						...payload,
						correlationID: data.correlationID,
					};
				}

				if (data.success) {
					if (data.api_response.errorCode === 'http.TIMEOUT') {
						setFormValidation({
							error: languages[language].serverError,
						});
						setErrorType('error');
						setIsLoading(false);
						setIsDisabled(true);
					} else {
						let result;
						const currentYear = new Date().getFullYear();
						if (
							data.api_response.vehicleModelYearNumber < currentYear - 1 &&
							data.api_response.vehicleModelYearNumber > currentYear - 4 &&
							(data.api_response.fuelCode === 'EL' ||
								data.api_response.fuelCode === 'GE' ||
								data.api_response.fuelCode === 'DE')
						) {
							result = 'Used - Exempt';
						} else {
							result = checkMasterList(
								data.api_response.vehicleDescriptionNumber,
								data.api_response.vehicleModelYearNumber
							);
						}
						if (
							result === 'yes' ||
							result === 'maybe' ||
							result === 'Used - Exempt'
						) {
							dispatch(setMasterListComparison(result));
							const listResponse = await fetch(
								'/wp-json/custom/v1/check-vehicle-list',
								{
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
									},
									body: JSON.stringify(payload),
								}
							);
							const listData = await listResponse.json();
							if (listData.success) {
								if (data.api_response.vehicleTypeCode === 'UNDFN') {
									setErrorType('warning');
									setIsValid(true);
									if (isSubmission) {
										setFormValidation({
											warning: languages[language].softNoSubmission,
										});
									} else {
										setFormValidation({
											warning: languages[language].softNoNotSubmission,
										});
									}
								} else {
									if (result === 'Used - Exempt') {
										if (isSubmission) {
											setErrorType('warning');
											setFormValidation({
												warning: languages[language].softNoSubmission,
											});
											setIsValid(true);
										} else {
											setErrorType('warning');
											setFormValidation({
												warning: languages[language].softNoNotSubmission,
											});
											setIsValid(true);
										}
									} else {
										setErrorType('success');
										setFormValidation({
											success: languages[language].isEligible,
										});
										setIsValid(true);
									}
								}
								setIsLoading(false);
								setIsDisabled(true);
								if (isSubmission) {
									dispatch(setVinStorage(VIN.toUpperCase()));
									dispatch(setPurchaseDateStorage(purchaseDate));
									dispatch(setOSTermsStorage(isChecked));
								}
							} else {
								if (listData.message === 'maybe') {
									if (isSubmission) {
										dispatch(setVinStorage(VIN.toUpperCase()));
										dispatch(setPurchaseDateStorage(purchaseDate));
										dispatch(setOSTermsStorage(isChecked));
										setFormValidation({
											warning: languages[language].softNoSubmission,
										});
										setErrorType('warning');
										setIsLoading(false);
										setIsDisabled(true);
										setIsValid(true);
									} else {
										setFormValidation({
											warning: languages[language].softNoNotSubmission,
										});
										setErrorType('warning');
										setIsLoading(false);
										setIsDisabled(true);
										setIsValid(true);
									}
								} else {
									setFormValidation({ error: languages[language].notEligible });
									setErrorType('error');
									setIsLoading(false);
									setIsDisabled(true);
								}
							}
						} else {
							setFormValidation({
								error: languages[language].notEligible,
							});
							setErrorType('error');
							setIsDisabled(true);
							setIsLoading(false);
						}
					}
				} else {
					if (data.api_response.errorCode === 'http.TIMEOUT') {
						setFormValidation({
							error: languages[language].serverError,
						});
						setErrorType('error');
						setIsLoading(false);
						setIsDisabled(true);
					} else {
						setFormValidation({
							error: languages[language].notEligibleVIN,
						});
						setErrorType('error');
						setIsLoading(false);
						setIsDisabled(true);
					}
				}
			} catch (error) {
				console.error('Error:', error);
				setIsLoading(false);
				setIsDisabled(true);
				setErrorType('error');
				setFormValidation({
					error: languages[language].serverError,
				});
			}
		}
	};

	useEffect(() => {
		if (isSubmission && hasAgreedToTerms) {
			setShowModal(false);
		}
	}, [hasAgreedToTerms, isSubmission]);

	return (
		<React.Fragment>
			<AnimatePresence>
				{showModal && isSubmission && !hasAgreedToTerms && (
					<TermsAndConditions
						setShow={setShowModal}
						goToBeginning={goToBeginning}
					/>
				)}
			</AnimatePresence>
			<div className='form-container'>
				{!isSubmission && <h1>{languages[language].titleText}</h1>}
				<p
					className='preamble'
					dangerouslySetInnerHTML={{
						__html: languages[language].enterVin,
					}}
				></p>
				<form onSubmit={handleSubmit}>
					<p className='required-fields'>{languages[language].required}</p>
					<VinInput
						ref={vinInputRef}
						value={VIN}
						language={language}
						onChange={(e) => {
							setVIN(e.target.value);
							setFormErrors({});
						}}
						error={formErrors.VIN}
						isDisabled={isDisabled}
					/>
					<NoteAccordion
						titleText={languages[language].vinTitle}
						content={languages[language].vinContent}
						contentAlignment='left'
					/>
					{isSubmission && (
						<div className='text-input'>
							<label>{languages[language].purchaseDate}</label>
							<DatePicker
								selected={purchaseDate}
								placeholderText={languages[language].purchaseDatePlaceholder}
								onChange={(date) => setPurchaseDate(date)}
								showMonthDropdown
								showYearDropdown
								maxDate={lastDayOfMonth}
								dropdownMode='select'
								autoComplete='off'
								className={formErrors.purchaseDate ? 'error' : ''}
								disabled={isDisabled}
							/>
							<p className='help'>
								{languages[language].purchaseDateHelperText}
							</p>
							<AnimatePresence>
								{formErrors.purchaseDate && (
									<motion.div
										key={purchaseDate}
										initial='hidden'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{ duration: 0.2, ease: 'easeInOut' }}
										className='error-message'
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='16'
											height='14'
											viewBox='0 0 16 14'
											fill='none'
										>
											<path
												d='M7.64124 0C7.55203 7.24657e-05 7.46452 0.0243969 7.38807 0.0703707C7.31163 0.116344 7.24912 0.182238 7.20724 0.261L0.0682352 13.224C0.0238521 13.3018 0.000346625 13.3897 3.80443e-06 13.4793C-0.000339016 13.5688 0.0224925 13.6569 0.0662788 13.735C0.110065 13.8131 0.173317 13.8786 0.249884 13.925C0.326452 13.9714 0.41373 13.9973 0.503235 14H14.7792C14.8687 13.9973 14.956 13.9714 15.0326 13.925C15.1092 13.8786 15.1724 13.8131 15.2162 13.735C15.26 13.6569 15.2828 13.5688 15.2825 13.4793C15.2821 13.3897 15.2586 13.3018 15.2142 13.224L8.07524 0.261C8.03335 0.182238 7.97084 0.116344 7.8944 0.0703707C7.81795 0.0243969 7.73044 7.24657e-05 7.64124 0ZM8.39124 11.5H6.89124V10H8.39124V11.5ZM8.39124 8.75H6.89124V5H8.39124V8.75Z'
												fill='#DC2E28'
											/>
										</svg>{' '}
										<span>{formErrors.purchaseDate}</span>
									</motion.div>
								)}
							</AnimatePresence>
						</div>
					)}
					<OSTerms
						checked={isChecked}
						onChange={(e) => {
							setIsChecked(e.target.checked);
							setFormErrors((prevErrors) => ({ ...prevErrors, terms: '' }));
						}}
						error={formErrors.terms}
						isDisabled={isDisabled}
					/>
					<AnimatePresence>
						{errorType && (
							<Notification isSubmission={isSubmission} type={errorType}>
								<p
									dangerouslySetInnerHTML={{
										__html: formValidation[errorType],
									}}
								></p>
							</Notification>
						)}
					</AnimatePresence>
					<div className='button-container'>
						<button
							className={`${isDisabled ? 'disabled' : ''} primary`}
							type='submit'
							disabled={isDisabled}
						>
							<AnimatePresence mode='wait'>
								{isLoading ? (
									<motion.span
										key='spinner'
										initial='hidden'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{
											duration: 0.2,
											ease: 'easeInOut',
											delay: 0.2,
										}}
									>
										<div className='button-spinner'></div>
										<span className={isLoading ? 'opacity-0' : ''}>
											{languages[language].checkEligibility}
										</span>
									</motion.span>
								) : (
									<motion.span
										key='eligibility'
										initial='visible'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{
											duration: 0.2,
											ease: 'easeInOut',
											delay: 0.2,
										}}
									>
										{languages[language].checkEligibility}
									</motion.span>
								)}
							</AnimatePresence>
						</button>
						{isSubmission && (
							<button
								className={`${!isValid ? 'disabled' : ''} primary`}
								type='submit'
								disabled={!isValid}
								onClick={() => goToNextStep()}
							>
								<>
									{languages[language].continue} <RightArrow />
								</>
							</button>
						)}
						<AnimatePresence>
							{isDisabled && (
								<motion.button
									key='startOver'
									className={`${!isDisabled ? 'disabled' : ''} primary`}
									type='submit'
									onClick={(e) => handleClearFields(e)}
									initial='visible'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{
										duration: 0.2,
										ease: 'easeInOut',
										delay: 0.2,
									}}
								>
									<>{languages[language].startOver}</>
								</motion.button>
							)}
						</AnimatePresence>
					</div>
				</form>
			</div>
		</React.Fragment>
	);
};

export default VinForm;
