import React from 'react';

const QuestionMarkIcon = ({ className, onClick }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='21'
			viewBox='0 0 20 21'
			fill='none'
			className={className}
			onClick={onClick}
			style={{ cursor: 'pointer' }}
		>
			<g id='16px Frame'>
				<g id='Group 12231'>
					<circle id='Ellipse 16' cx='10' cy='10.5' r='10' fill='#008193' />
					<path
						id='Vector'
						d='M9.35734 12.6872C9.35734 12.0045 9.44707 11.4547 9.62651 11.0381C9.80595 10.6214 10.1793 10.17 10.7465 9.68391C11.2093 9.26724 11.5187 8.92002 11.6748 8.64224C11.8315 8.36446 11.9098 8.0578 11.9098 7.72224C11.9098 7.21335 11.7273 6.79085 11.3623 6.45474C10.9979 6.11918 10.5321 5.95141 9.96484 5.95141C9.5254 5.95141 9.13207 6.06418 8.78484 6.28974C8.43762 6.51585 8.17123 6.87196 7.98568 7.35807L6.83984 6.87141C7.11762 6.18863 7.53734 5.66502 8.09901 5.30057C8.66012 4.93613 9.28207 4.75391 9.96484 4.75391C10.9026 4.75391 11.6696 5.02863 12.2657 5.57807C12.8618 6.12807 13.1598 6.83113 13.1598 7.68724C13.1598 8.13891 13.0585 8.56724 12.8557 8.97224C12.6529 9.37724 12.3029 9.80557 11.8057 10.2572C11.2962 10.7084 10.9748 11.0817 10.8415 11.3772C10.7087 11.6722 10.6365 12.1089 10.6248 12.6872H9.35734ZM9.96484 16.2464C9.7104 16.2464 9.49651 16.1597 9.32318 15.9864C9.14929 15.8125 9.06234 15.5984 9.06234 15.3439C9.06234 15.0895 9.14929 14.8753 9.32318 14.7014C9.49651 14.5281 9.7104 14.4414 9.96484 14.4414C10.2082 14.4414 10.4196 14.5281 10.599 14.7014C10.7785 14.8753 10.8682 15.0895 10.8682 15.3439C10.8682 15.5984 10.7785 15.8125 10.599 15.9864C10.4196 16.1597 10.2082 16.2464 9.96484 16.2464Z'
						fill='white'
					/>
				</g>
			</g>
		</svg>
	);
};

export default QuestionMarkIcon;
