const fadeVariant = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 },
	exit: { opacity: 0 },
};

const slideFadeVariant = {
	hidden: {
		opacity: 0,
		height: 0,
		padding: 0,
		margin: 0,
		transition: {
			opacity: { duration: 0.2, ease: 'easeInOut' },
			height: { duration: 0.2, delay: 0.3, ease: 'easeInOut' },
			padding: { duration: 0.2, delay: 0.3, ease: 'easeInOut' },
			margin: { duration: 0.2, delay: 0.3, ease: 'easeInOut' },
		},
	},
	visible: {
		opacity: 1,
		height: 'auto',
		padding: '18px 24px',
		margin: '0 0 24px',
		transition: {
			opacity: { duration: 0.2, delay: 0.3, ease: 'easeInOut' },
			height: { duration: 0.2, ease: 'easeInOut' },
			padding: { duration: 0.2, ease: 'easeInOut' },
			margin: { duration: 0.2, ease: 'easeInOut' },
		},
	},
	exit: {
		opacity: 0,
		transition: { opacity: { duration: 0.2, ease: 'easeInOut' } },
	},
};

const slideFadeVehicleVariant = {
	hidden: {
		opacity: 0,
		height: 0,
		padding: 0,
		margin: 0,
		transition: {
			opacity: { duration: 0.2, ease: 'easeInOut' },
			height: { duration: 0.2, delay: 0.3, ease: 'easeInOut' },
			padding: { duration: 0.2, delay: 0.3, ease: 'easeInOut' },
			margin: { duration: 0.2, delay: 0.3, ease: 'easeInOut' },
		},
	},
	visible: {
		opacity: 1,
		height: 'auto',
		padding: '24px 32px',
		margin: '24px 0px',
		transition: {
			opacity: { duration: 0.2, delay: 0.3, ease: 'easeInOut' },
			height: { duration: 0.2, ease: 'easeInOut' },
			padding: { duration: 0.2, ease: 'easeInOut' },
			margin: { duration: 0.2, ease: 'easeInOut' },
		},
	},
	exit: {
		opacity: 0,
		transition: { opacity: { duration: 0.2, ease: 'easeInOut' } },
	},
};

const slideVariant = {
	top: { position: 'relative', bottom: 0 },
	bottom: { position: 'absolute', bottom: '-40px' },
};
const submissionSlideVariant = {
	top: { position: 'relative', bottom: 0 },
	bottom: { position: 'absolute', bottom: '24px' },
};

export {
	fadeVariant,
	slideVariant,
	submissionSlideVariant,
	slideFadeVehicleVariant,
	slideFadeVariant,
};
