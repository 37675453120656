import React from 'react';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import '../../styles/ui/CheckmarkListItem.css';

const CheckmarkListItem = ({ children }) => {
	return (
		<div className='checkmark-list-item'>
			<span>
				<CheckmarkIcon />
			</span>
			{children}
		</div>
	);
};

export default CheckmarkListItem;
