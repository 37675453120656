import React from 'react';

const RadioInput = ({ name, options, selectedValue, onChange }) => {
	return (
		<div className='radio-input-group'>
			{options.map((option) => (
				<div key={option.value} className='radio-input'>
					<input
						type='radio'
						name={name}
						value={option.value}
						checked={selectedValue === option.value}
						onChange={onChange}
					/>
					<label>{option.label}</label>
				</div>
			))}
		</div>
	);
};

export default RadioInput;
