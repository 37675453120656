import React from 'react';

const MailIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='57'
			height='56'
			viewBox='0 0 57 56'
			fill='none'
		>
			<g clip-path='url(#clip0_2006_10157)'>
				<path
					d='M35.7942 37.7505C34.2884 38.6149 31.1399 40.1731 27.5435 40.1731C23.947 40.1731 20.7986 38.6027 19.2928 37.7384C17.9115 36.9471 1.41016 25.5645 1.41016 25.5645V51.1297C1.41016 53.1505 3.07771 54.7818 5.14349 54.7818H49.9435C52.0093 54.7818 53.6768 53.1505 53.6768 51.1297V25.5645C53.6768 25.5645 37.163 36.9592 35.7942 37.7505Z'
					stroke='#383838'
					stroke-width='1.5'
					stroke-linejoin='round'
				/>
				<path
					d='M36.2549 37.7383L48.6993 49.9122'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
				<path
					d='M18.8321 37.7383L6.3877 49.9122'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
				<path
					d='M53.6772 25.5648V24.3474C53.6772 22.9474 52.9305 21.8882 51.8727 21.0604L44.9785 15.2656'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
				<path
					d='M1.41016 25.5652V24.3478C1.41016 22.9478 2.15682 21.8887 3.2146 21.0609L10.1213 15.2539'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
				<path
					d='M48.6992 7.30375H54.9214V6.08636C54.9214 3.28636 52.6938 1.2168 49.9436 1.2168H44.9658'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
				<path
					d='M10.1211 31.6516V7.30375C10.1211 3.94375 12.9086 1.2168 16.3433 1.2168H51.1878C47.7531 1.2168 44.9655 3.94375 44.9655 7.30375V31.6516'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
				<path
					d='M17.5879 12.1738H37.499'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
				<path
					d='M17.5879 18.2598H37.499'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
				<path
					d='M17.5879 24.3477H29.7586'
					stroke='#383838'
					stroke-width='1.5'
					stroke-miterlimit='10'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2006_10157'>
					<rect
						width='56'
						height='56'
						fill='white'
						transform='translate(0.166016)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default MailIcon;
