import React from 'react';

const ExternalLinkIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='17'
			viewBox='0 0 16 17'
			fill='none'
		>
			<g clip-path='url(#clip0_2046_24270)'>
				<path
					d='M13 8.48828V13.4883C13 14.0383 12.55 14.4883 12 14.4883H3C2.45 14.4883 2 14.0383 2 13.4883V4.48828C2 3.93828 2.45 3.48828 3 3.48828H8V2.48828H3C1.9 2.48828 1 3.38828 1 4.48828V13.4883C1 14.5883 1.9 15.4883 3 15.4883H12C13.1 15.4883 14 14.5883 14 13.4883V8.48828H13ZM15.5 0.988281V6.48828H14.5V2.69828L7.71 9.48828L7 8.77828L13.79 1.98828H10V0.988281H15.5Z'
					fill='#383838'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2046_24270'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0 0.488281)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default ExternalLinkIcon;
