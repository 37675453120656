import React, { useState } from 'react';

import '../../styles/Buttons.css';

const ButtonLink = ({
	icon = null,
	text,
	link,
	type = 'primary',
	isExternal = false,
	additionalClasses,
	...props
}) => {
	//Set target for external links
	let target = '';
	if (isExternal === true) {
		target = '_blank';
	} else {
		target = '';
	}

	// Build class string
	let classes = 'btn mt-4 ' + additionalClasses + ' ';
	if (icon) {
		classes = classes + 'btn--' + icon + ' ';
	}
	if (type) {
		classes = classes + 'btn-' + type + ' ';
	}

	return (
		<a href={link} target={target} className={classes}>
			{text}
		</a>
	);
};

export default ButtonLink;
