import React, { useState, useImperativeHandle, forwardRef } from 'react';
import TextInput from './TextInput';

const PostalCodeInput = forwardRef(
	({ value, onChange, error, isDisabled = false, ...props }, ref) => {
		const params = new URLSearchParams(window.location.search);
		const initialLang = params.get('lang');
		const [language, setLanguage] = useState(
			initialLang === 'fr' ? 'fr' : 'en'
		);

		const [localError, setLocalError] = useState('');

		const handleChange = (event) => {
			const newValue = event.target.value;
			onChange(event); // Call the onChange prop
		};

		const validatePostalCode = () => {
			const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
			if (!regex.test(value)) {
				setLocalError(languages[language].invalidFormatError);
				return false;
			} else {
				setLocalError('');
				return true;
			}
		};

		useImperativeHandle(ref, () => ({
			validatePostalCode,
		}));

		const languages = {
			en: {
				label: 'Postal code',
				invalidFormatError:
					'Please enter a six-character postal code in the format ‘A1A 1A1’.',
			},
			fr: {
				label: 'Code postal',
				invalidFormatError:
					'Veuillez entrer un code postal à six caractères au format « A1A 1A1 ».',
			},
		};

		return (
			<TextInput
				label={languages[language].label}
				value={value}
				name='postalCode'
				onChange={handleChange}
				error={localError || error}
				isDisabled={isDisabled}
				{...props}
			/>
		);
	}
);

export default PostalCodeInput;
