import React from 'react';

const AlreadyHaveEVIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='57'
			height='56'
			viewBox='0 0 57 56'
			fill='none'
		>
			<g clip-path='url(#clip0_2006_10123)'>
				<path
					d='M25 46.375L39 27.125H28.5L30.25 11.375L16.25 30.625H26.75L25 46.375Z'
					stroke='#383838'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M3.85963 16.8307C1.17995 10.3054 3.6387 5.74445 8.22807 3.31632C13.7099 0.415699 20.104 0.571011 26.0781 1.39132C25.5203 1.5357 25.0084 1.89007 24.5971 2.29476C22.8603 4.0032 21.8649 6.28257 21.2043 8.59257C20.5349 10.9376 20.2812 13.3963 18.7587 15.4023C16.6018 18.2394 12.2968 19.1385 8.85807 18.2044'
					stroke='#383838'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M32 2.11328C44.4141 4.19578 53.875 14.9933 53.875 28.0002C53.875 30.4239 53.3019 33.7839 52.3678 35.8402'
					stroke='#383838'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M32.875 53.7259C31.1797 54.0693 29.4231 54.2509 27.625 54.2509C13.1284 54.2509 1.375 42.4974 1.375 28.0009C1.375 19.813 5.12437 12.5002 11 7.68555'
					stroke='#383838'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M54.3371 46.1745L50.5986 51.7198L38.2939 43.3548L42.0324 37.8095C44.3424 34.4123 48.9689 33.5307 52.3661 35.8407C55.7633 38.1507 56.6449 42.7773 54.3349 46.1745H54.3371Z'
					stroke='#383838'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M37.5869 50.9423L41.3691 45.4473'
					stroke='#383838'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M43.7402 55.1259L47.5224 49.6309'
					stroke='#383838'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2006_10123'>
					<rect
						width='56'
						height='56'
						fill='white'
						transform='translate(0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default AlreadyHaveEVIcon;
