import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import TextInput from '../inputFields/TextInput';
import RadioInput from '../inputFields/RadioInput';
import { AnimatePresence, motion } from 'framer-motion';

import Notification from '../ui/Notification';

import '../../styles/forms.css';
import {
	fadeVariant,
	slideFadeVehicleVariant,
} from '../../utils/motionVariants.js';

import {
	setPurchaseOrLeaseStorage,
	setNewOrUsedStorage,
	setPurchasePriceStorage,
	setLeaseLengthStorage,
	setMasterListComparison,
} from '../../redux/dataStorageReducer';
import RightArrow from '../icons/RightArrow';

const VehicleInformation = ({
	isSubmission = true,
	goToNextStep,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	//Vehicle information storage
	const purchaseOrLeaseStorage = useSelector(
		(state) => state.dataStorage.storedPurchaseOrLease
	);
	const newOrUsedStorage = useSelector(
		(state) => state.dataStorage.storedNewOrUsed
	);
	const purchasePriceStorage = useSelector(
		(state) => state.dataStorage.storedPurchasePrice
	);
	const leaseLengthStorage = useSelector(
		(state) => state.dataStorage.storedLeaseLength
	);
	const masterListComparison = useSelector(
		(state) => state.dataStorage.storedMasterListComparison
	);

	const [type, setType] = useState('');
	const [newOrUsed, setNewOrUsed] = useState('');
	const [price, setPrice] = useState('');
	const [priceError, setPriceError] = useState('');
	const [leaseLength, setLeaseLength] = useState(null);

	useEffect(() => {
		if (purchaseOrLeaseStorage) {
			setType(purchaseOrLeaseStorage);
		}
		if (newOrUsedStorage) {
			setNewOrUsed(newOrUsedStorage);
		}
		if (purchasePriceStorage) {
			setPrice(purchasePriceStorage);
		}
		if (leaseLengthStorage) {
			setLeaseLength({
				value: leaseLengthStorage.value,
				label: leaseLengthStorage.label,
			});
		}
	}, []);

	const [formErrors, setFormErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [isContinueDisabled, setIsContinueDisabled] = useState(true);
	const [formValidation, setFormValidation] = useState({
		error: '',
		warning: '',
		success: '',
	});
	const [errorType, setErrorType] = useState('');

	const languages = {
		en: {
			required: 'All fields are required.',
			preamble: 'Did you purchase or lease your vehicle?',
			vehiclePurchaseLabel: 'Vehicle purchase',
			vehicleLeaseLabel: 'Vehicle lease',
			newVehicleLabel: 'New vehicle',
			usedVehicleLabel: 'Used vehicle',
			leaseLengthLabel: 'Length of vehicle lease',
			leaseLengthHelper: 'This is available on your lease agreement.',
			oneYearLabel: '12 Months (1 year)',
			twoYearLabel: '24 Months (2 years)',
			threeYearLabel: '36 Months (3 years)',
			fourYearLabel: '48 Months (4 years)',
			fiveYearLabel: '60 Months (5 years)',
			purchasePriceLabel: 'Purchase price before tax',
			purchasePriceHelper: 'The purchase price on the Bill of Sale.',
			Continue: 'Continue',
			priceError:
				'The purchase price must be less than $70,000 as part of the program terms.',
			noAddressError: 'Please enter your address.',
			noCityError: 'Please enter your City/town.',
			noEmailError: 'Please enter your email address.',
			emailNotValidError: 'Please enter a valid email address.',
			emailNotMatchError: 'Emails must match exactly.',
			invalidPostalCodeError:
				'Please enter a six-character postal code in the format ‘A1A 1A1’.',
		},
		fr: {
			required: 'Tous les champs doivent obligatoirement être remplis.',
			preamble: 'Avez-vous acheté ou loué votre véhicule?',
			vehiclePurchaseLabel: 'Achat du véhicule ',
			vehicleLeaseLabel: 'Location du véhicule',
			newVehicleLabel: 'Véhicule neuf',
			usedVehicleLabel: 'Véhicule d’occasion',
			leaseLengthLabel: 'Durée de la location du véhicule',
			oneYearLabel: '12 mois (1 an)',
			twoYearLabel: '24 mois (2 ans)',
			threeYearLabel: '36 mois (3 ans)',
			fourYearLabel: '48 mois (4 ans)',
			fiveYearLabel: '60 mois (5 ans)',
			purchasePriceLabel: 'Prix d’achat avant taxes',
			purchasePriceHelper: 'Prix d’achat dans le contrat de vente.',
			Continue: 'Continuer',
			priceError:
				'Le prix d’achat doit être de moins de 70 000 $, comme indiqué dans les modalités du programme.',
			noAddressError: 'Veuillez entrer votre adresse.',
			noCityError: 'Veuillez entrer votre ville.',
			noEmailError: 'Veuillez entrer votre adresse email.',
			emailNotValidError: 'Veuillez entrer une adresse email valide.',
			emailNotMatchError: 'Les adresses email doivent correspondre exactement.',
			invalidPostalCodeError:
				'Veuillez entrer un code postal à six caractères au format « A1A 1A1 ».',
		},
	};

	const dispatch = useDispatch();

	const loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				[
					{ value: '12', label: languages[language].oneYearLabel },
					{ value: '24', label: languages[language].twoYearLabel },
					{ value: '36', label: languages[language].threeYearLabel },
					{ value: '48', label: languages[language].fourYearLabel },
					{ value: '60', label: languages[language].fiveYearLabel },
				].filter((i) =>
					i.label.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const handleTypeChange = (event) => {
		setType(event.target.value);
		if (event.target.value === 'purchase') {
			setLeaseLength(null);
			dispatch(setLeaseLengthStorage(null));
		} else if (event.target.value === 'lease') {
			setPrice('');
			setNewOrUsed(null);
			dispatch(setPurchasePriceStorage(null));
			dispatch(setNewOrUsedStorage(null));
		}
		checkContinueDisabled();
	};

	const handleNewOrUsedChange = (event) => {
		if (
			masterListComparison === 'Used - Exempt' &&
			event.target.value === 'new'
		) {
			dispatch(setMasterListComparison('Needs validation'));
		}
		setNewOrUsed(event.target.value);
		checkContinueDisabled();
	};

	const handlePriceChange = (event) => {
		const value = event.target.value;

		// Remove commas from the entered value before checking for numeric characters and periods
		const strippedValue = value.replace(/,/g, '');

		// Check if the stripped value contains only numeric characters and periods
		if (/^\d*\.?\d*$/.test(strippedValue)) {
			// Format the value with commas, ensuring decimal parts are preserved
			const [integerPart, decimalPart] = strippedValue.split('.');
			const formattedIntegerPart = Number(integerPart).toLocaleString();
			const formattedValue =
				decimalPart !== undefined
					? `${formattedIntegerPart}.${decimalPart}`
					: formattedIntegerPart;

			setPrice(formattedValue);
			setPriceError('');
		} else {
			setPriceError('Please enter a valid number');
		}
		checkContinueDisabled();
	};

	const handleLeaseLengthChange = (selectedOption) => {
		setLeaseLength(selectedOption);
		checkContinueDisabled();
	};

	const validateForm = () => {
		const errors = {};

		if (!type) {
			errors.type = 'Please select a vehicle type.';
		}

		if (type === 'purchase' && !newOrUsed) {
			errors.newOrUsed = 'Please select whether the vehicle is new or used.';
		}

		if (type === 'purchase' && newOrUsed === 'used' && !price) {
			errors.price = 'Please enter the purchase price.';
		}
		if (type === 'purchase' && newOrUsed === 'used' && !price) {
			errors.price = 'Please enter the purchase price.';
		}

		let priceValue;
		if (price) {
			// Remove commas from the price and convert to a floating-point number
			priceValue = parseFloat(price.replace(/,/g, ''));
		}
		if (type === 'purchase' && newOrUsed === 'used' && priceValue > 70000) {
			errors.price = languages[language].priceError;
		}

		if (type === 'lease' && !leaseLength) {
			errors.leaseLength = 'Please select the length of your lease.';
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const checkContinueDisabled = () => {
		if (type === 'lease' && leaseLength) {
			setIsContinueDisabled(false);
		} else if (type === 'purchase' && newOrUsed === 'used' && price) {
			setIsContinueDisabled(false);
		} else if (type === 'purchase' && newOrUsed === 'new') {
			setIsContinueDisabled(false);
		} else {
			setIsContinueDisabled(true);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) {
			return;
		}

		setIsLoading(true);

		setTimeout(() => {
			setIsLoading(false);
			setIsDisabled(true);
			dispatch(setPurchaseOrLeaseStorage(type));
			if (type === 'purchase') {
				dispatch(setNewOrUsedStorage(newOrUsed));
				if (newOrUsed === 'used') {
					dispatch(setPurchasePriceStorage(price));
				}
			} else {
				dispatch(setLeaseLengthStorage(leaseLength));
			}
			goToNextStep();
		}, 2000);
	};

	const typeOptions = [
		{ value: 'purchase', label: languages[language].vehiclePurchaseLabel },
		{ value: 'lease', label: languages[language].vehicleLeaseLabel },
	];

	const newOrUsedOptions = [
		{ value: 'new', label: languages[language].newVehicleLabel },
		{ value: 'used', label: languages[language].usedVehicleLabel },
	];

	// Check continue button disable state on relevant state changes
	useEffect(() => {
		checkContinueDisabled();
	}, [type, newOrUsed, price, leaseLength]);

	return (
		<React.Fragment>
			<div className='form-container'>
				<p>{languages[language].preamble}</p>
				<form onSubmit={handleSubmit}>
					<RadioInput
						name='type'
						options={typeOptions}
						selectedValue={type}
						onChange={handleTypeChange}
						error={formErrors.type}
					/>
					<AnimatePresence mode='wait'>
						{type === 'lease' && (
							<motion.div
								key='lease'
								className='secondary'
								initial='hidden'
								animate='visible'
								exit='hidden'
								variants={
									type === 'lease' ? fadeVariant : slideFadeVehicleVariant
								}
								transition={{ duration: 0.2, ease: 'easeInOut' }}
							>
								<label>{languages[language].leaseLengthLabel}</label>
								<AsyncSelect
									cacheOptions
									loadOptions={loadOptions}
									defaultOptions
									className='react-select-container'
									classNamePrefix='react-select'
									styles={{
										indicatorSeparator: () => ({ display: 'none' }),
										option: (provided, state) => ({
											...provided,
											backgroundColor: state.isSelected
												? '#F0F0F0'
												: state.isFocused
												? '#F0F0F0'
												: undefined,
										}),
										control: (provided, state) => ({
											...provided,
											borderColor: state.isFocused ? '#383838' : '#383838',
											boxShadow: state.isFocused ? 'none' : 'none',
											'&:hover': {
												borderColor: state.isFocused ? '#383838' : '#383838',
												boxShadow: state.isFocused ? 'none' : 'none',
											},
										}),
									}}
									onChange={handleLeaseLengthChange}
									value={leaseLength}
								/>
								<p className='help'>{languages[language].leaseLengthHelper}</p>
							</motion.div>
						)}
						{type === 'purchase' && (
							<motion.div
								key='purchase'
								className='secondary'
								initial='hidden'
								animate='visible'
								exit='hidden'
								variants={
									type === 'purchase' ? fadeVariant : slideFadeVehicleVariant
								}
								transition={{ duration: 0.2, ease: 'easeInOut' }}
							>
								<RadioInput
									name='newOrUsed'
									options={newOrUsedOptions}
									selectedValue={newOrUsed}
									onChange={handleNewOrUsedChange}
									error={formErrors.newOrUsed}
								/>
								{newOrUsed === 'used' && (
									<motion.div
										initial='hidden'
										animate='visible'
										exit='hidden'
										className='price-input'
										variants={fadeVariant}
										transition={{ duration: 0.2, ease: 'easeInOut' }}
									>
										<TextInput
											label={languages[language].purchasePriceLabel}
											name='price'
											value={price}
											onChange={handlePriceChange}
											helperText={languages[language].purchasePriceHelper}
											error={priceError || formErrors.price}
										/>
									</motion.div>
								)}
							</motion.div>
						)}
					</AnimatePresence>
					<AnimatePresence>
						{errorType && (
							<Notification type={errorType}>
								<p>{formValidation[errorType]}</p>
							</Notification>
						)}
					</AnimatePresence>
					<div className={`button-container ${type ? '' : 'mt-8'}`}>
						<button
							className={`${isContinueDisabled ? 'disabled' : ''} primary`}
							type='submit'
							disabled={isContinueDisabled}
						>
							<AnimatePresence mode='wait'>
								{isLoading ? (
									<motion.span
										key='spinner'
										initial='hidden'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{
											duration: 0.2,
											ease: 'easeInOut',
											delay: 0.2,
										}}
									>
										<div className='button-spinner'></div>
										<span className={isLoading ? 'opacity-0' : ''}>
											{languages[language].Continue} <RightArrow />
										</span>
									</motion.span>
								) : (
									<motion.span
										key='continue'
										initial='visible'
										animate='visible'
										exit='hidden'
										variants={fadeVariant}
										transition={{
											duration: 0.2,
											ease: 'easeInOut',
											delay: 0.2,
										}}
									>
										{languages[language].Continue} <RightArrow />
									</motion.span>
								)}
							</AnimatePresence>
						</button>
					</div>
				</form>
			</div>
		</React.Fragment>
	);
};

export default VehicleInformation;
