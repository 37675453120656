import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Modal from './ui/Modal';
import RightArrow from './icons/RightArrow';

import '../styles/TermsAndConditions.css';
import CheckboxInput from './inputFields/CheckboxInput';
import { setTermsStorage } from '../redux/dataStorageReducer';

const TermsAndConditions = ({
	setShow,
	isSubmission = true,
	goToBeginning,
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');

	const checkboxRef = useRef();
	const [checkboxError, setCheckboxError] = useState('');

	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		const isValid = checkboxRef.current.validate();
		if (isValid) {
			setCheckboxError('');
			dispatch(setTermsStorage(true));
		} else {
			setCheckboxError('You must accept the terms and conditions.');
		}
	};
	const languages = {
		en: {
			termsHeader: 'Terms & Conditions',
			termsAgreeance:
				'I agree to the EV Rebate Program Terms & Conditions as listed above',
			terms: (
				<>
					<h3>MANITOBA ELECTRIC VEHICLE REBATE PROGRAM DESCRIPTION</h3>
					<p>
						The objective of the Manitoba Electric Vehicle Rebate Program (the
						"Rebate Program") is to encourage the purchase of EV and Plug-In
						Hybrid Vehicles by providing rebate payments to Manitoba Residents.
						To support the objectives, the Rebate Program encourages the
						purchase and lease of eligible EV and Plug-In Hybrid Vehicles.
						<br></br>The Government of Manitoba as represented by the Department
						of Environment and Climate Change and the Department of Finance
						(“Manitoba”) has developed the Rebate Program to be administered by
						The Manitoba Public Insurance Corporation (“MPI”).
					</p>
					<h3>ELIGIBILITY CRITERIA</h3>
					<h4>Eligible Applicants</h4>
					<p>
						Applicants must be a Manitoba Residents to be eligible for a rebate
						payment. Manitoba Residents include both individuals that are
						residents of Manitoba as defined by The Drivers and Vehicles Act,
						and entities registered to do business in Manitoba with a Manitoba
						business address.<br></br> Each Applicant will only be able to
						receive a maximum of one rebate payment, and only one rebate payment
						will be issued per motor vehicle as defined in The Manitoba Public
						Insurance Corporation Act (“Vehicle”)<br></br>
						In addition, when applying for an rebate payment, the Vehicle must
						meet certain eligibility criteria.
					</p>
					<h4>Eligible Vehicles</h4>
					<p>
						To qualify for a rebate payment the Vehicle must meet the following
						criteria:
					</p>
					<ol type='A'>
						<li type='A'>
							A rebate payment has not already been issued under the Rebate
							Program for the Vehicle.{' '}
						</li>
						<li type='A'>
							Be either:
							<ol>
								<li>
									Be a new battery electric vehicle (BEV), plug-in hybrid
									vehicle (PHEV) or fuel cell electric vehicle (FCEV) as defined
									by a list of eligible Vehicles established by Manitoba as
									published on the Manitoba Electric Vehicle Rebate Program
									website. Manitoba may modify the list of eligible Vehicles on
									written notice to MPI from time to time, or
								</li>
								<li>
									be a used BEV, PHEV or FCEV, and meets all of the remaining
									criteria.
								</li>
							</ol>
						</li>
						<li type='A'>
							If the Vehicle is a used Tesla, or a new or used BEV, PHEV, or
							FCEV, it must be purchased from a Manitoba dealer as defined by
							The Drivers and Vehicles Act (“Dealer”), on or after August 1,
							2023.
						</li>
						<li type='A'>
							If the Vehicle is not a Tesla, it must be purchased from a
							Manitoba dealer as defined by The Drivers and Vehicles Act
							(“Dealer”), on or after August 1, 2023.
						</li>
						<li type='A'>
							Be four (4) model years old or newer to qualify at the time of
							application to the Rebate Program, meaning that:
						</li>
						<ol className='custom-counter'>
							<li>
								in the first year of the Rebate Program, eligible Vehicles must
								be the 2020 model year or more recent;
							</li>
							<li>
								in the second year of the Rebate Program, eligible Vehicles must
								be the 2021 model year or more recent;
							</li>
							<li>
								in the third year of the Rebate Program, eligible Vehicles must
								be the 2022 model year or more recent.
							</li>
						</ol>
						<li type='A'>Be registered with MPI.</li>
						<li type='A'>
							Have a manufacturer’s suggested retail price (MSRP) or sale price
							of less than $70,000 (before any applicable taxes and optional
							purchases such as extended warranties and rust proofing) whether
							it is a new or used purchase.
						</li>
					</ol>
					<h3>REBATE PAYMENT AMOUNT</h3>
					<p>
						If an Applicant has not already received an rebate payment under the
						Rebate Program and purchases an eligible Vehicle in accordance with
						the eligibility criteria set out above, then they will be entitled
						to an rebate payment on their qualifying purchase of an EV or
						Plug-In Hybrid as follows:
					</p>
					<ol type='A'>
						<li type='A'>
							A new Vehicle purchase will be eligible for a $4,000 rebate
							payment.
						</li>
						<li type='A'>
							A used Vehicle purchase will be eligible for a $2,500 rebate
							payment.
						</li>
						<li type='A'>
							Leased Vehicles will be eligible for an rebate payment that is
							proportionate to length of the lease, as follows:
						</li>
						<ol type='1' className='custom-counter'>
							<li>
								if the length of the lease term is between a minimum of one year
								and less than two years, the a new Vehicle is eligible for a
								rebate payment of $1,000 and a used Vehicle is eligible for a
								rebate payment of $625.
							</li>
							<li>
								if the length of the lease term is between two years and less
								than three years, the a new Vehicle is eligible for a rebate
								payment of $2,000 and a used Vehicle is eligible for a rebate
								payment of $1,250.
							</li>
							<li>
								3. if the length of the lease term is between three years and
								less than four years, the a new Vehicle is eligible for a rebate
								payment of $3,000 and a used Vehicle is eligible for a rebate
								payment of $1,875.
							</li>
							<li>
								4. if the length of the lease term is four years or longer, the
								a new Vehicle is eligible for a rebate payment of $4,000 and a
								used Vehicle is eligible for a rebate payment of $2,500.
							</li>
						</ol>
					</ol>
					<h3>HOW TO APPLY:</h3>
					<h4>
						Please ensure that all fields in the application form for the Rebate
						Program are fully completed.
					</h4>
					<p>
						Applicants may be contacted to provide additional information or
						clarification regarding their application forms to the Rebate
						Program, and MPI reserves the right to refuse incomplete application
						forms for the Rebate Program.
						<br></br>
						For additional information on the Rebate Program, please refer to:{' '}
						<a
							href='https://www.manitoba.ca/lowercosts/evrebate/'
							target='_blank'
						>
							https://www.manitoba.ca/lowercosts/evrebate/.
						</a>
					</p>
					<p className='callout'>
						This Applicant Guide, the Terms and Conditions, the Applicant
						Privacy Consent and the Application Form for the Rebate Program
						will, upon notification of approval by MPI, constitute an rebate
						agreement. An rebate payment will subsequently be provided to the
						Applicant if they receive written notification that the rebate
						payment has been approved.
					</p>
					<h3>TERMS AND CONDITIONS FOR THE REBATE PROGRAM</h3>
					<h4>1. Rebate Agreement</h4>
					<p>
						The Applicant Guide, Terms and Conditions, Applicant Privacy Consent
						and Application Form for the Rebate Program constitute the rebate
						agreement between The Government of Manitoba as represented by the
						Department of Environment and Climate Change and the Department of
						Finance (“Manitoba”)Manitoba, MPI , The Manitoba Public Insurance
						Corporation (“MPI”) and the (“Applicant”) (collectively referred to
						as the “Parties”). If MPI approves the rebate application and sends
						the Applicant written notice of such approval, MPI’s written
						notification that the rebate application has been approved also
						forms part of this rebate agreement.
					</p>
					<h4>2. Duration of the Rebate Agreement</h4>
					<p>
						This rebate agreement will be effective as of the date the Applicant
						submits its Application Form for approval by MPI. This rebate
						agreement will terminate once the Applicant receives payment by MPI
						on behalf of Manitoba.
					</p>
					<h4>3. Entire Rebate Agreement</h4>
					<p>
						This rebate agreement agreement (including the Applicant Guide, the
						Terms and Conditions, the Applicant Privacy Consent and the
						Application Form for the Rebate Program) comprises the entire
						agreement between the Parties in relation to the subject of the
						rebate agreement. No prior document, negotiation, provision,
						undertaking or agreement has legal effect, unless incorporated by
						reference into this rebate agreement. No representation or warranty
						express, implied, or otherwise, is made by Manitoba to the Applicant
						except as expressly set out in this rebate agreement.
					</p>
					<h4>4. Basis of Payment</h4>
					<p>
						Payment to an eligible Applicant will be made by MPI on behalf of
						Manitoba in a lump-sum payment based on the rebate payment provided
						for an eligible Vehicle, which forms part of the rebate application.
						Rebate payments will be made upon approval by MPI of the Applicant’s
						completed application form, in accordance with this rebate
						agreement, based on the eligibility of the Applicant and the Vehicle
						as set out above.
					</p>
					<h4>5. Appropriations and Funding Levels</h4>
					<p>
						Notwithstanding MPI obligation to make any payment under this rebate
						agreement on Manitoba’s behalf this obligation does not arise if, at
						the time when a payment under this rebate agreement becomes due, the
						Government of Manitoba has not passed an appropriation that is
						sufficient and constitutes lawful authority for making the payment.
						<br></br> Manitoba may reduce or terminate any payment under this
						rebate agreement in response to the reduction of appropriations or
						departmental funding levels in respect of transfer payments, the
						program under which this rebate agreement was made or otherwise, MPI
						will promptly advise the Applicant of any reduction or termination
						of funding once it becomes aware of any such situation.
					</p>
					<h4>6. Public Acknowledgement of Funding</h4>
					<p>
						The Applicant acknowledges that the amount awarded by MPI, and the
						general nature of the activities supported by the Manitoba Electric
						Vehicle Rebate Program may be made publicly available by Manitoba.
					</p>
					<h4>7. Limitation of Liability </h4>
					<p>
						MPI and Manitoba will not be liable for any direct, indirect,
						consequential, exemplary or punitive damages, regardless of the form
						of action, whether in contract, tort or otherwise, arising from any
						whether in contract, tort (including negligence) or otherwise
						related to the Rebate Program or such reduction or termination of
						Rebate Program funding.<br></br> In no event will MPI or Manitoba,
						its officers, servants, employees or agents be held liable for any
						damages in contract, tort (including negligence) or otherwise, for:
					</p>
					<ol type='a' className='padded'>
						<li type='a'>
							any injury to any person, including, but not limited to, death,
							economic loss or infringement of rights;
						</li>
						<li type='a'>
							any damage to or loss or destruction of property of any person; or
						</li>
						<li type='a'>
							any obligation of any person, including, but not limited to, any
							obligation arising from a loan, capital lease or other long term
							obligation;
						</li>
					</ol>
					<p>in relation to this rebate agreement.</p>
					<h4>8. Definition of a Person</h4>
					<p>
						In this agreement, “person” includes, without limitation, a person,
						the Applicant, a third party, a corporation, or any other legal
						entity, and their officers, servants, employees or agents.
					</p>
					<h4>9. No Agency, Partnership, Joint venture, etc.</h4>
					<p>
						No provision of this rebate agreement and no action by the Parties
						will establish or be deemed to establish a partnership, joint
						venture, principal-agent relationship, or employer-employee
						relationship in any way or for any purpose whatsoever between MPI or
						Manitoba and the Applicant or between MPI or Manitoba and a third
						party.
					</p>
					<h4>10. Compliance with Laws</h4>
					<p>
						The Applicant will comply with all applicable laws and regulations
						and all requirements of regulatory bodies having jurisdiction over
						the subject matter of this rebate agreement.
					</p>
					<h4>11. Default</h4>
					<h4>11.1 Events of Default</h4>
					<p>
						The following events constitute Events of Default under this rebate
						agreement:{' '}
					</p>
					<ol type='1' className='points'>
						<li>
							the Applicant has not complied with one or more of the terms and
							conditions of this rebate agreement;
						</li>
						<li>
							the Applicant does not or no longer meets the eligibility
							criteria, including but not limited to where the eligible
							recipient has received more than one rebate payment than permitted
							under the eligibility criteria and where the eligible Vehicles was
							returned or exchanged for an ineligible Vehicle, or the eligible
							Vehicle purchase or lease was cancelled;{' '}
						</li>
						<li>
							the Applicant has submitted false or misleading information to MPI
							or made a false or misleading representation in respect of this
							rebate agreement, except for an error in good faith, demonstration
							of which is incumbent on the Applicant, to MPI's satisfaction;
						</li>
						<li>
							the Applicant is wound up, dissolved, or becomes insolvent,
							commits an act of bankruptcy, takes the benefit of any statute
							relating to bankrupt and insolvent debtors or goes into
							receivership or bankruptcy;{' '}
						</li>
					</ol>
					<h4>11.2 Remedies of Default</h4>
					<p>
						In the event of an Applicant default under Section 15.1 (Events of
						Default), Manitoba may exercise one or more of the following
						remedies, without limiting any remedy available to it at law:
					</p>
					<ol type='1'>
						<li>
							suspend any obligation by MPI to provide or continue to provide
							funding for the rebate payment, including any obligation to pay an
							amount owing prior to the date of such suspension;
						</li>
						<li>
							terminate any obligation of MPI to provide or continue to provide
							funding for the rebate payment, including any obligation to pay
							any amount owing prior to the date of such termination;{' '}
						</li>
						<li>
							require the Applicant to reimburse Manitoba all or part of the
							funding paid by MPI to the Applicant;
						</li>
						<li>terminate this rebate agreement.</li>
					</ol>
					<h4>12. Governing Law</h4>
					<p>
						This Agreement shall be interpreted, performed, and enforced in
						accordance with the laws of Manitoba and the laws of Canada
						applicable therein. The parties hereby irrevocably and
						unconditionally submit to the exclusive jurisdiction of the courts
						of the Province of Manitoba and all courts competent to hear appeals
						therefrom.
					</p>
					<h4>13. Successors and Assigns</h4>
					<p>
						This rebate agreement is binding upon the Parties and their
						respective successors and assigns.
					</p>
					<h4>14. Survival</h4>
					<p>
						The Parties’ rights and obligations, which by their nature extend
						beyond the termination of this rebate agreement, will survive any
						termination of this rebate agreement.
					</p>
					<h4>15. Counterpart Signature</h4>
					<p>
						This Agreement may be signed in one or more counterparts, each of
						which shall be deemed to be an original and all of which taken
						together shall constitute one and the same document. The signature
						of party received by electronic or digital means shall be binding
						upon the signatory with the same force and effect as an original
						signature.{' '}
					</p>
					<h3>As the Applicant, I declare and agree to the following:</h3>
					<ol type='1'>
						<li>
							The information I have provided in the application form for the
							Rebate Program is true, accurate and complete in every respect. I
							acknowledge that it is a criminal offence to knowingly make false
							or misleading statements.
						</li>
						<li>
							I agree to inform MPI of any change in the application and any
							other information or documentation provided with respect to the
							application that may have an impact on the approval of this rebate
							within 20 business days of this rebate application and submission
							of supporting documentation. I agree to inform MPI as soon as
							possible, particularly with respect to any changes related but not
							limited to situations where the eligible recipient has received
							more than one rebate payment under the eligibility criteria and
							where the eligible Vehicle was/were returned or exchanged for an
							ineligible Vehicle, or the eligible Vehicle purchase or lease was
							cancelled.
						</li>
						<li>
							I agree that Manitoba may seek a reimbursement, in whole or in
							part, of the rebate payment if: I am not in compliance with this
							rebate agreement; if the information provided in or related to the
							application has changed in any way that affects the eligibility to
							receive an rebate payment; if the information I have provided is
							inaccurate, false or misleading; or in any way related to changes
							to the eligibility criteria, particularly as outlined above.{' '}
						</li>
						<li>
							I am authorized to submit this application, make these
							declarations and bind the Applicant to this rebate agreement.
						</li>
						<li>
							I agree that MPI, and the Government of Manitoba do not assume any
							liability whatsoever relating to the Rebate Program or the rebate
							payment.
						</li>
						<li>
							I understand that failing to comply with all the application
							requirements may delay the processing of the application or may
							render the application ineligible to receive a payment under the
							Rebate Program.
						</li>
						<li>
							I acknowledge that no payment may be issued in the event that
							funding for the Rebate Program is changed or cancelled by the
							Government of Manitoba, or in the event that funding runs out.
						</li>
						<li>
							I acknowledge that neither completion of the application form for
							the Rebate Program, nor any advice provided by Environment and
							Climate Change Manitoba or The Government of Manitoba obliges MPI
							to provide funding.
						</li>
					</ol>
					<h3>PRIVACY</h3>
					<p>
						MPI will collect personal information on the application form for
						the purpose of administering the Rebate Program, including verifying
						eligibility to receive benefits from the Rebate Program. MPI is
						authorized to collect personal information on the application form
						by s. 36(1)(b) of The Freedom of Information and Protection of
						Privacy Act (“FIPPA”) as the information relates to and is necessary
						to administer the Rebate Program.<br></br> MPI must comply with the
						provisions of FIPPA. Under the provisions of FIPPA, persons have the
						right of access to, correction of and protection of their personal
						information. If there are any concerns regarding the consent, rebate
						agreement or the collection of personal information by MPI for the
						administration of the Rebate Program, please contact MPI at:{' '}
						<a href='mailto:evrebate@mpi.mb.ca'>evrebate@mpi.mb.ca</a>.<br></br>{' '}
						It is MPI’s responsibility to adhere to data storage policies, data
						retention periods, privacy management practices and policies and
						responding to access requests and any applicable privacy complaints,
						as required by FIPPA.
					</p>
					<h3>APPLICANT PRIVACY CONSENT FORM</h3>
					<h4>
						The application form and related documents contain personal
						information, some of which is the personal information of Applicant.{' '}
					</h4>
					<ol type='1'>
						<li>
							For the purposes of this Privacy Consent MPI will collect personal
							information from the Applicant and share that information with the
							Department of Environment and Climate Change (“ECC”) for the
							purpose of administering the Rebate Program, which includes
							monitoring, reporting, investigating suspected fraudulent
							applications, and communicating with Applicants.
						</li>
						<li>
							ECC may disclose personal information collected from the Applicant
							to the Department of Finance (“Finance”) for the purpose of
							administering the Rebate Program, which includes monitoring,
							investigating of suspected fraudulent applications and reporting.{' '}
						</li>
						<li>
							The Applicant declares that they consent to MPI’s collection,
							storage, disclosure and other use of this information, and all
							personal information included on the application form.
						</li>
						<li>
							The Applicant also consents to MPI sharing all information in the
							application form with ECC for the purpose of monitoring,
							reporting, investigating suspected fraudulent applications, and
							communicating with Applicants to the Rebate Program.
						</li>
						<li>
							The Applicant also consents to ECC sharing the personal
							information it receives from MPI with Finance for the purpose of
							monitoring, reporting and investigating suspected fraudulent
							applications for the Rebate Program.
						</li>
						<li>
							On behalf of the Applicant, I hereby authorize MPI to collect,
							store, and use the information in this rebate application form and
							related documents as described and for the purposes outlined in
							this Consent.
						</li>
						<li>
							All Applicant personal information provided to MPI is used and
							disclosed to ECC in accordance with the Manitoba{' '}
							<em>Freedom of Information and Protection of Privacy Act</em>{' '}
							(“FIPPA”). All personal information collected is used for the
							administration of the Rebate Program and ECC may contact
							Applicants to survey them on their experience with the Rebate
							Program.
						</li>
						<li>
							The application form must be reviewed and signed with by each
							Applicant (business and individual). When reviewing and signing
							these documents we emphasize that you are consenting to the
							collection use and disclosure of all information in the
							Application form is required.{' '}
						</li>
						<li>
							Applicants participating in the Rebate Program are agreeing to
							meet the Rebate Programs Terms & Conditions as outlined in the
							Applicant Guide. The Terms and Conditions state that you must
							“comply with all applicable laws and regulations and all
							requirements of all regulatory bodies having jurisdiction over the
							subject matter of this rebate agreement.”{' '}
						</li>
						<li>
							MPI will disclose information you provided on your application
							form and the following additional personal information about you
							to ECC, and ECC will provide the information to Finance, for the
							purpose of reporting, and monitoring the Rebate Program:
						</li>
						<ul>
							<li>Application Date</li>
							<li>
								Full legal name of applicant (for Individuals and Corporations)
							</li>
							<li>
								Applicant Address / Registered address of Applicant Corporation
							</li>
							<li>
								If Corporation, confirmation it is registered to do business in
								Manitoba and has a Manitoba business address.
							</li>
							<li>Applicant email address</li>
							<li>Applicant phone number</li>
							<li>Vehicle Information:</li>
							<ul>
								<li>Identification Number (VIN)</li>
								<li>Purchase Date </li>
								<li>Purchase Price</li>
								<li>Make</li>
								<li>Model</li>
								<li>Type (BEV, PHEV or FCEV) </li>
							</ul>
							<li>Rebate:</li>
							<ul>
								<li>Type (New, Used of Lease)</li>
								<li>Lease Length if applicable</li>
								<li>Rebate amount approved</li>
							</ul>
							<li>MPI processing date</li>
							<li>Application status</li>
							<li>Dealership Name</li>
							<li>
								Application information including confirmation that Applicant
								has agreed to standard terms, conditions and consents
							</li>
						</ul>
						<li>
							MPI will also provide information on your application form and the
							additional information listed above to ECC, and ECC will provide
							the information to Finance, for the purpose of conducting
							investigations where it is suspected fraudulent applications have
							been made.
						</li>
						<ul>
							<li>Upon Investigation</li>
							<ul>
								<li>Bill of Sale </li>
								<li>Lease Agreement </li>
								<li>Registration</li>
								<li>Rejection reason if applicable</li>
							</ul>
						</ul>
						<li>
							Your name, address and email address will also be provided to ECC
							for communication purposes, including periodic surveys on the
							usage of the vehicle.
						</li>
						<li>
							Your consent is necessary for MPI to disclose your personal
							information to ECC and for ECC to disclose your personal
							information to Finance. MPI and ECC will not disclose your
							personal information without your consent.
						</li>
						<li>
							Your personal information is protected by FIPPA. Any other use or
							disclosure of your personal information by MPI, and ECC or Finance
							must be authorized by you or by FIPPA. If you have any questions
							about the collection or disclosure of your personal information,
							please contact please contact{' '}
							<a href='mailto:evrebate@mpi.mb.ca'>evrebate@mpi.mb.ca</a> .
						</li>
					</ol>
					<h3>CONSENT</h3>
					<ol>
						<li>
							I consent and authorize MPI to disclose my personal information
							contained on this application form and other personal information,
							and for ECC to collect personal information about me from MPI, for
							the purpose of administering, reporting, monitoring, investigating
							and communicating with me about my application to the Rebate
							Program.
						</li>
						<li>
							I consent and authorize ECC to disclose my personal information
							contained on this application form and other personal information,
							and for Finance to collect personal information about me from ECC,
							for the purpose of administering, reporting, monitoring and
							investigating suspected fraudulent applications made to the Rebate
							Program.
						</li>
						<h4>I understand that:</h4>
						<li>
							I have the right to withdraw my consent at any time by notifying
							MPI. However, my consent cannot be withdrawn retroactively and
							withdrawal of my consent will result in my application to the
							Rebate Program being rejected and I will not receive benefits
							under this Rebate Program.
						</li>
						<li>
							My consent is voluntary, however refusal to provide consent will
							be grounds for the denial of my application to receive benefits
							under the Rebate Program.
						</li>
						<li>
							MPI, ECC and Finance will not to use or disclose the information,
							except for the purposes noted above. MPI will disclose only as
							much information about me to ECC as is necessary. ECC will only
							disclose as much information about me to Finance as is necessary.
						</li>
						<li>
							The personal information received by MPI, ECC and Finance will be
							kept in a secure manner and access to the information will be
							limited to individuals who require access for the purposes listed
							above.{' '}
						</li>
					</ol>
					<h4>DECLARATION</h4>
					<p>
						I certify that all of the information provided in this application
						is true and complete. I understand that any false or inaccurate
						statements or omissions made in this application may disqualify me
						from receiving benefits from the Rebate Program.
					</p>
				</>
			),
			continue: 'Continue',
			cancel: 'Cancel',
			close: 'Close',
		},
		fr: {
			termsHeader: 'Termes et Conditions',
			termsAgreeance:
				"J'accepte les Termes et Conditions du Programme de Remise pour Véhicules Électriques tels que listés ci-dessus",
			terms: (
				<>
					<h3>
						DESCRIPTION DU PROGRAMME DE RABAIS POUR VÉHICULES ÉLECTRIQUES DU
						MANITOBA
					</h3>
					<p>
						Le Programme de rabais pour véhicules électriques du Manitoba (le «
						Programme de rabais ») a pour objectif d’encourager les Manitobains
						à acheter un véhicule électrique ou un véhicule hybride
						rechargeable, en leur offrant des rabais. Pour encadrer cet
						objectif, le Programme de rabais promeut l’achat et la location de
						véhicules électriques et hybrides rechargeables admissibles.
						<br></br>Le gouvernement du Manitoba, représenté par le ministère de
						l’Environnement et du Changement climatique et le ministère des
						Finances (le « Manitoba »), a confié l’administration du Programme
						de rabais à la Société d’assurance publique du Manitoba (la « SAPM
						»).
					</p>
					<h3>CRITÈRES D’ADMISSIBILITÉ</h3>
					<h4>Demandeurs admissibles</h4>
					<p>
						Pour être admissible à un rabais, le demandeur doit être un habitant
						du Manitoba, qui peut être soit une personne physique qui réside au
						Manitoba au sens de la Loi sur les conducteurs et les véhicules ou
						une personne morale enregistrée pour faire des affaires au Manitoba
						et ayant une adresse commerciale au Manitoba. <br></br> Un demandeur
						ne peut recevoir qu’un seul rabais, et un seul rabais sera octroyé
						par véhicule automobile (le « véhicule »), comme défini dans la Loi
						sur la Société d’assurance publique du Manitoba. <br></br>
						De plus, le véhicule faisant l’objet de la demande de rabais doit
						réunir certains critères d’admissibilité.
					</p>
					<h4>Véhicules admissibles </h4>
					<p>
						Pour être admissible à un rabais, le véhicule doit réunir les
						critères suivants :
					</p>
					<ol type='A'>
						<li type='A'>
							Aucun rabais n’a déjà été octroyé à l’égard du véhicule en vertu
							du Programme de rabais pour véhicules électriques.
						</li>
						<li type='A'>
							Le véhicule doit être :
							<ol>
								<li>
									Un nouveau véhicule électrique à batterie (VEB), un véhicule
									hybride rechargeable (VHR) ou un véhicule électrique à pile à
									combustible (VEPC), qui est désigné sur une liste de véhicules
									admissibles, établie par le Manitoba et publiée sur le site
									Web du Programme de rabais pour véhicules électriques du
									Manitoba. Le Manitoba peut modifier ladite liste sur avis
									signifié à la SAPM;
								</li>
								<li>
									Un VEB, VHR ou VEPC d’occasion;<br></br>et réunir tous les
									critères restants.
								</li>
							</ol>
						</li>
						<li type='A'>
							Si le véhicule est une Tesla neuve, son achat doit se faire auprès
							d’un commerçant Tesla au Canada, entre le 1er août 2023 et le 2
							mai 2024 (inclusivement), ou d’un commerçant Tesla à Winnipeg,
							après le 3 mai 2024 (inclusivement).
						</li>
						<li type='A'>
							Si le véhicule est une Tesla d’occasion, ou un VEB, VHR ou VEPC
							neuf ou d’occasion, son achat doit se faire auprès d’un commerçant
							du Manitoba, comme défini par la Loi sur les conducteurs et les
							véhicules (le « commerçant »), après le 1er août 2023
							(inclusivement).
						</li>
						<li type='A'>
							À la date à laquelle la demande est faite au titre du Programme de
							rabais, le véhicule doit avoir quatre (4) années automobiles ou
							moins. Autrement dit :
						</li>
						<ol className='custom-counter'>
							<li>
								Au cours de la première année du Programme de rabais, le
								véhicule admissible doit être de l’année automobile 2020 ou plus
								récente;
							</li>
							<li>
								Au cours de la deuxième année du Programme de rabais, le
								véhicule admissible doit être de l’année automobile 2021 ou plus
								récente;
							</li>
							<li>
								Au cours de la troisième année du Programme de rabais, le
								véhicule admissible doit être de l’année automobile 2022 ou plus
								récente;
							</li>
						</ol>
						<li type='A'>Le véhicule doit être inscrit auprès de la SAPM.</li>
						<li type='A'>
							Le véhicule, qu’il soit neuf ou d’occasion, doit avoir un prix de
							détail conseillé (PDC) ou un prix de vente inférieur à 70 000 $
							(avant taxes et options, comme la garantie prolongée ou le
							traitement antirouille).
						</li>
					</ol>
					<h3>MONTANT DU RABAIS</h3>
					<p>
						Si le demandeur n’a pas déjà reçu un rabais au titre du Programme de
						rabais et achète un véhicule admissible conformément aux critères
						d’admissibilité énoncés ci-dessus, il peut se prévaloir d’un rabais
						sur son achat admissible d’un véhicule électrique ou hybride
						rechargeable, comme suit :
					</p>
					<ol type='A'>
						<li type='A'>
							Un véhicule neuf sera admissible à un rabais de 4 000 $.
						</li>
						<li type='A'>
							Un véhicule d’occasion sera admissible à un rabais de 2 500 $.
						</li>
						<li type='A'>
							Un véhicule loué sera admissible à un rabais proportionnel à la
							durée du contrat de location, comme suit :
						</li>
						<ol type='1' className='custom-counter'>
							<li>
								Si la durée du contrat de location est supérieure à un an et
								inférieure à deux ans, un véhicule neuf est admissible à un
								rabais de 1 000 $ tandis qu’un véhicule d’occasion est
								admissible à un rabais de 625 $.
							</li>
							<li>
								Si la durée du contrat de location est supérieure à deux ans et
								inférieure à trois ans, un véhicule neuf est admissible à un
								rabais de 2 000 $ tandis qu’un véhicule d’occasion est
								admissible à un rabais de 1 250 $.
							</li>
							<li>
								Si la durée du contrat de location est supérieure à trois ans et
								inférieure à quatre ans, un véhicule neuf est admissible à un
								rabais de 3 000 $ tandis qu’un véhicule d’occasion est
								admissible à un rabais de 1 875 $.
							</li>
							<li>
								Si la durée du contrat de location est de quatre ans ou plus, un
								véhicule neuf est admissible à un rabais de 4 000 $ tandis qu’un
								véhicule d’occasion est admissible à un rabais de 2 500 $.
							</li>
						</ol>
					</ol>
					<h3>PRÉSENTATION D’UNE DEMANDE :</h3>
					<h4>
						Veillez à ce que tous les champs du formulaire de demande au titre
						du Programme de rabais soient dûment remplis.
					</h4>
					<p>
						La SAPM peut communiquer avec le demandeur pour lui demander des
						renseignements supplémentaires ou des éclaircissements concernant
						son formulaire de demande et se réserve le droit de refuser les
						formulaires qui ne sont pas dûment remplis.
						<br></br>
						Pour en savoir plus sur le Programme de rabais, consultez{' '}
						<a
							href='https://www.gov.mb.ca/lowercosts/evrebate/index.fr.html'
							target='_blank'
						>
							Province du Manitoba | Programme de rabais pour véhicules
							électriques (gov.mb.ca).
						</a>
					</p>
					<p className='callout'>
						Le présent guide du demandeur, les conditions générales, le
						consentement à la communication des renseignements personnels du
						demandeur et le formulaire de demande au titre du Programme de
						rabais constitueront, dès notification de l’approbation par la SAPM,
						l’accord de rabais. Un rabais sera ensuite octroyé au demandeur si
						ce dernier reçoit un avis écrit lui signifiant l’approbation de sa
						demande.
					</p>
					<h3>CONDITIONS GÉNÉRALES DU PROGRAMME DE RABAIS </h3>
					<h4>1. Accord de rabais</h4>
					<p>
						Le guide du demandeur, les conditions générales, le consentement à
						la communication des renseignements personnels et le formulaire de
						demande au titre du Programme de rabais constituent l’accord de
						rabais entre le Manitoba, la SAPM et le demandeur (collectivement,
						les « parties »). <br></br>Si la SAPM approuve la demande de rabais
						et envoie au demandeur un avis écrit lui signifiant une telle
						approbation, ledit avis fera également partie de l’accord de rabais.
					</p>
					<h4>2. Durée de l’accord de rabais</h4>
					<p>
						L’accord de rabais entre en vigueur à la date à laquelle le
						demandeur soumettra son formulaire de demande à l’approbation de la
						SAPM. Il prend fin une fois que le demandeur aura reçu le paiement
						de la SAPM au nom du Manitoba.
					</p>
					<h4>3. Intégralité de l’accord de rabais</h4>
					<p>
						L’accord de rabais (notamment le guide du demandeur, les conditions
						générales, le consentement à la communication des renseignements
						personnels et le formulaire de demande au titre du Programme de
						rabais) constitue l’intégralité de l’accord entre les parties eu
						égard à l’objet de l’accord de rabais. Aucun document, négociation,
						disposition, engagement ou accord antérieur n’a un quelconque effet
						juridique, à moins qu’il ne soit intégré par renvoi dans le présent
						accord de rabais. Aucune déclaration ou garantie expresse, implicite
						ou autre, n’est faite par le Manitoba au demandeur, sauf dans les
						cas expressément énoncés dans le présent accord de rabais.
					</p>
					<h4>4. Méthode de paiement</h4>
					<p>
						La SAPM fera le paiement au demandeur admissible au nom du Manitoba
						sous la forme d’une somme forfaitaire égale au rabais octroyé au
						véhicule admissible visé par la demande de rabais. Le paiement sera
						fait sur approbation par la SAPM du formulaire de demande dûment
						rempli, conformément au présent accord de rabais, en fonction de
						l’admissibilité du demandeur et du véhicule, comme indiqué
						ci-dessus.
					</p>
					<h4>5. Crédits ouverts et niveaux de financement</h4>
					<p>
						Nonobstant l’obligation assumée par la SAPM d’effectuer un
						quelconque paiement en vertu de cet accord de rabais au nom du
						Manitoba, ladite obligation ne naît pas si, à la date à laquelle le
						paiement en vertu de cet accord de rabais devient exigible, le
						gouvernement du Manitoba n’a pas ouvert des crédits suffisants et
						conféré le pouvoir légal d’effectuer le paiement. <br></br>Le
						Manitoba peut réduire un quelconque paiement ou y mettre fin en
						vertu du présent accord de rabais advenant une réduction des crédits
						ouverts ou des niveaux de financement ministériels visant les
						paiements de transfert au programme en vertu duquel cet accord de
						rabais a été conclu ou autrement. La SAPM informera sans tarder le
						demandeur de toute réduction ou annulation du financement dès
						qu’elle aura eu connaissance de telles circonstances.
					</p>
					<h4>6. Reconnaissance publique du financement</h4>
					<p>
						Le demandeur reconnaît que le Manitoba se réserve le droit de
						publier le montant octroyé par la SAPM et la nature générale des
						activités encadrées par le Programme de rabais pour véhicules
						électriques du Manitoba.
					</p>
					<h4>7. Limitation de responsabilité </h4>
					<p>
						La SAPM et le Manitoba n’assumeront aucune responsabilité à l’égard
						des dommages directs, indirects, immatériels, exemplaires ou
						punitifs, quelle que soit la forme d’action, contractuelle,
						délictuelle ou autre, découlant de tout manquement contractuel,
						délictuel (y compris la négligence) ou autre se rapportant au
						Programme de rabais ou à une réduction ou annulation du financement
						du Programme de rabais.<br></br> La SAPM et le Manitoba, leurs
						dirigeants, préposés, employés ou mandataires n’assumeront aucune
						responsabilité à l’égard des dommages contractuels, délictuels (y
						compris la négligence) ou autres, découlant de :
					</p>
					<ol type='a' className='padded'>
						<li type='a'>
							Tout préjudice causé à une quelconque personne, notamment le
							décès, la perte financière ou la violation des droits;
						</li>
						<li type='a'>
							Tout dommage, perte ou destruction de biens d’une quelconque
							personne;
						</li>
						<li type='a'>
							Toute obligation à l’égard d’une quelconque personne, notamment
							toute obligation découlant d’un prêt, d’un bail-acquisition ou
							d’une autre obligation à long terme;
						</li>
					</ol>
					<p>se rapportant au présent accord de rabais. </p>
					<h4>8. Définition de personne</h4>
					<p>
						Dans le présent accord, le terme « personne » comprend, sans s’y
						limiter, une quelconque personne physique, le demandeur, un tiers,
						une société ou toute autre personne morale, ainsi que leurs
						dirigeants, préposés, employés ou mandataires.
					</p>
					<h4>
						9. Absence de relation mandant-mandataire, de partenariat, de
						coentreprise, etc.
					</h4>
					<p>
						Aucune disposition du présent accord de rabais et aucun acte des
						parties n’établira ou ne sera réputé établir un partenariat, une
						coentreprise, une relation mandant-mandataire ou une relation
						employeur-employé, de quelque manière ou à quelque fin que ce soit,
						entre la SAPM ou le Manitoba avec le demandeur, ou entre la SAPM ou
						le Manitoba avec un tiers.
					</p>
					<h4>10. Respect des lois</h4>
					<p>
						Le demandeur respectera tous les règlements et lois applicables,
						ainsi que toutes les exigences imposées par les organismes de
						réglementation ayant compétence sur l’objet du présent accord de
						rabais
					</p>
					<h4>11. Défaut</h4>
					<h4>11.1 Cas de défaut</h4>
					<p>
						Les circonstances suivantes constituent des cas de défaut en vertu
						du présent accord de rabais :
					</p>
					<ol type='1' className='points'>
						<li>
							Le demandeur ne respecte pas une ou plusieurs des conditions
							générales du présent accord de rabais;
						</li>
						<li>
							Le demandeur ne répond pas ou plus aux critères d’admissibilité,
							notamment lorsqu’il reçoit un nombre de rabais supérieur à ce qui
							est autorisé en vertu des critères d’admissibilité et lorsque le
							véhicule admissible est retourné ou échangé contre un véhicule non
							admissible, ou lorsque l’achat ou la location d’un véhicule
							admissible est annulé;
						</li>
						<li>
							Le demandeur présente à la SAPM des renseignements faux ou
							trompeurs ou fait des déclarations fausses ou trompeuses à l’égard
							de cet accord de rabais, sauf erreur de bonne foi dont la validité
							doit être prouvée par le demandeur, à la satisfaction de la SAPM;
						</li>
						<li>
							Le demandeur fait l’objet d’une liquidation, d’une dissolution ou
							d’une insolvabilité, commet un acte de faillite, tire avantage
							d’une quelconque loi sur les débiteurs faillis et insolvables, se
							met sous séquestre ou déclare sa faillite.
						</li>
					</ol>
					<h4>11.2 Recours en cas de défaut</h4>
					<p>
						En cas de défaut du demandeur en vertu de l’article 12.1 (Cas de
						défaut), le Manitoba peut exercer un ou plusieurs des recours
						suivants, sans limiter tous autres recours dont il peut se prévaloir
						en droit :
					</p>
					<ol type='1'>
						<li>
							Suspendre toute obligation de la SAPM d’assurer ou de continuer
							d’assurer un financement pour le paiement du rabais, y compris
							toute obligation de payer un montant dû avant la date d’une telle
							suspension;
						</li>
						<li>
							Mettre fin à toute obligation de la SAPM d’assurer ou de continuer
							d’assurer un financement pour le paiement du rabais, y compris
							toute obligation de payer un montant dû avant la date d’une telle
							fin;
						</li>
						<li>
							Exiger du demandeur qu’il rembourse au Manitoba la totalité ou une
							partie du rabais qui lui est versé par la SAPM;
						</li>
						<li>Résilier le présent accord de rabais.</li>
					</ol>
					<h4>12. Droit applicable</h4>
					<p>
						Le présent accord doit être interprété, exécuté et appliqué
						conformément aux lois du Manitoba et du Canada pertinentes. Les
						parties se soumettent par les présentes de manière irrévocable et
						inconditionnelle à la compétence exclusive des tribunaux de la
						province du Manitoba et de tout tribunal ayant compétence pour
						entendre les appels.
					</p>
					<h4>13. Successeurs et ayants droit</h4>
					<p>
						Le présent accord de rabais est opposable aux parties, ainsi qu’à
						leurs successeurs et ayants droit respectifs.
					</p>
					<h4>14. Survie</h4>
					<p>
						Les droits et obligations des parties qui, de par leur nature, ne
						cessent pas à la résiliation du présent accord de rabais, survivront
						à la résiliation du présent accord de rabais.
					</p>
					<h4>15. Exemplaires</h4>
					<p>
						Le présent accord peut être signé en un ou plusieurs exemplaires,
						dont chacun sera considéré comme un original et dont la totalité
						constituera un seul et même document. Toute signature reçue par des
						moyens électroniques ou numériques sera opposable au signataire avec
						la même force et le même effet qu’une signature manuscrite.
					</p>
					<h3>En qualité de demandeur, je déclare et accepte ce qui suit :</h3>
					<ol type='1'>
						<li>
							Les renseignements que j’ai fournis dans le formulaire de demande
							au titre du Programme de rabais sont vrais, exacts et complets à
							tous égards. Je conviens que faire sciemment des déclarations
							fausses ou trompeuses constitue une infraction criminelle.
						</li>
						<li>
							J’accepte d’informer la SAPM de tout changement à la demande et de
							tout autre renseignement ou document remis à l’égard de la
							demande, qui pourrait se répercuter sur l’approbation de ce
							rabais, dans les vingt (20) jours ouvrables suivant cette demande
							de rabais et la présentation des pièces justificatives. J’accepte
							d’informer la SAPM dans les plus brefs délais, notamment en ce qui
							concerne tout changement se rapportant, sans s’y limiter, aux
							situations où le bénéficiaire admissible reçoit plus d’un rabais
							en vertu des critères d’admissibilité et où le véhicule admissible
							est retourné ou échangé contre un véhicule inadmissible, ou
							l’achat ou la location du véhicule admissible est annulé.
						</li>
						<li>
							Je conviens que le Manitoba peut demander un remboursement, en
							totalité ou en partie, du rabais si je ne respecte pas le présent
							accord de rabais; si les renseignements fournis dans la demande ou
							s’y rapportant changent d’une manière qui se répercute sur
							l’admissibilité au rabais; si les renseignements que j’ai fournis
							sont inexacts, faux ou trompeurs; ou si les renseignements se
							rapportent de quelque manière que ce soit aux changements apportés
							aux critères d’admissibilité, particulièrement comme indiqué
							ci-dessus.
						</li>
						<li>
							Je suis autorisé à présenter la présente demande, à faire les
							présentes déclarations et à lier le demandeur au présent accord de
							rabais.
						</li>
						<li>
							Je conviens que la SAPM et le Manitoba n’assument aucune
							responsabilité, de quelque nature que ce soit, concernant le
							Programme de rabais ou le paiement du rabais.
						</li>
						<li>
							Je comprends que le non-respect d’une quelconque exigence de la
							demande peut retarder le traitement de celle-ci ou la rendre
							inadmissible à recevoir un paiement au titre du Programme de
							rabais.
						</li>
						<li>
							Je conviens qu’aucun paiement ne peut être fait si le financement
							au titre du Programme de rabais est modifié ou annulé par le
							Manitoba, ou si les fonds sont épuisés.
						</li>
						<li>
							Je conviens que ni le fait de remplir le formulaire de demande au
							titre du Programme de rabais, ni un quelconque conseil prodigué
							par le Manitoba n’oblige la SAPM à accorder un financement.
						</li>
					</ol>
					<h3>PROTECTION DES RENSEIGNEMENTS PERSONNELS </h3>
					<p>
						La SAPM recueillera des renseignements personnels figurant sur le
						formulaire de demande dans le but d’administrer le Programme de
						rabais, notamment pour vérifier l’admissibilité aux avantages prévus
						le Programme de rabais. La SAPM est autorisée à recueillir les
						renseignements personnels figurant sur le formulaire de demande en
						vertu de l’alinéa 36(1)b) de la Loi sur l’accès à l’information et
						la protection de la vie privée (la « LAIPVP »), du fait que les
						renseignements se rapportent au Programme de rabais et sont
						nécessaires à son administration. <br></br> La SAPM est tenue de se
						conformer aux dispositions de la LAIPVP. En vertu de cette loi, les
						personnes ont le droit d’accéder à leurs renseignements personnels,
						de les corriger et de les protéger. Si vous avez des préoccupations
						concernant le consentement, l’accord de rabais ou la collecte de
						renseignements personnels par la SAPM en vue de l’administration du
						Programme de rabais, n’hésitez pas à contacter la SAPM à{' '}
						<a href='mailto:evrebate@mpi.mb.ca'>evrebate@mpi.mb.ca</a>.<br></br>{' '}
						La SAPM se fait un devoir de respecter les politiques en matière de
						stockage des données, les périodes de conservation des données, les
						pratiques et politiques en matière de gestion de la confidentialité,
						ainsi que de répondre aux demandes d’accès et à toute plainte
						relative à la confidentialité applicable, comme l’exige la LAIPVP.
					</p>
					<h3>
						CONSENTEMENT À LA COMMUNICATION DES RENSEIGNEMENTS PERSONNELS DU
						DEMANDEUR
					</h3>
					<h4>
						Le formulaire de demande et les documents connexes contiennent des
						renseignements personnels, dont certains concernent le demandeur.
					</h4>
					<ol type='1'>
						<li>
							Aux fins du présent consentement, la SAPM recueillera des
							renseignements personnels auprès du demandeur et en fera part au
							ministère de l’Environnement et du Changement climatique
							(ministère de l’ECC) dans le but d’administrer le Programme de
							rabais, notamment à en assurer le suivi des demandes présumées
							frauduleuses, à enquêter sur celles-ci et à produire des rapports
							à leur égard, et de communiquer avec les demandeurs.
						</li>
						<li>
							Le ministère de l’ECC peut communiquer les renseignements
							personnels recueillis auprès du demandeur au ministère des
							Finances aux fins d’administration du Programme de rabais,
							notamment à assurer le suivi des demandes présumées frauduleuses,
							à enquêter sur celles-ci et à produire des rapports à leur égard.
						</li>
						<li>
							Le demandeur déclare qu’il consent à la collecte, au stockage, à
							la communication et à toute autre utilisation par la SAPM de ces
							renseignements, ainsi que de tous les renseignements personnels
							figurant dans le formulaire de demande.
						</li>
						<li>
							Le demandeur autorise aussi la SAPM à communiquer tous les
							renseignements figurant dans le formulaire de demande au ministère
							de l’ECC dans le but d’assurer le suivi des demandes présumées
							frauduleuses, d’enquêter sur celles-ci et de produire des rapports
							à leur égard, ainsi que de communiquer avec les demandeurs au
							Programme de rabais.
						</li>
						<li>
							Le demandeur autorise aussi le ministère de l’ECC à communiquer au
							ministère des Finances les renseignements personnels qu’il reçoit
							de la SAPM dans le but d’assurer le suivi des demandes présumées
							frauduleuses, d’enquêter sur celles-ci et de produire des rapports
							à leur égard.
						</li>
						<li>
							Au nom du demandeur, j’autorise par la présente la SAPM à
							recueillir, à stocker et à utiliser les informations figurant dans
							le présent formulaire de demande et les documents connexes, comme
							décrit et aux fins décrites dans le présent consentement.
						</li>
						<li>
							Tous les renseignements personnels du demandeur fournis à la SAPM
							sont utilisés et communiqués au ministère de l’ECC, conformément à
							la LAIPVP. Ces renseignements serviront à l’administration du
							Programme de rabais et le ministère de l’ECC peut contacter le
							demandeur pour sonder son avis sur son expérience avec le
							Programme de rabais.
						</li>
						<li>
							Le formulaire de demande doit être examiné et signé par chaque
							demandeur (qu’il s’agisse d’une personne physique ou morale). Nous
							précisons qu’au moment d’examiner et de signer ces documents, vous
							consentez à la collecte, à l’utilisation et à la communication de
							toutes les informations contenues dans le formulaire de demande.
						</li>
						<li>
							Les demandeurs participant au Programme de rabais acceptent de
							respecter les conditions générales dudit programme, comme décrit
							dans le guide du demandeur. Les conditions générales stipulent que
							« le demandeur respectera tous les règlements et lois applicables,
							ainsi que toutes les exigences imposées par les organismes de
							réglementation ayant compétence sur l’objet du présent accord de
							rabais ».
						</li>
						<li>
							La SAPM communiquera les renseignements figurant sur le formulaire
							de demande et les renseignements personnels supplémentaires
							suivants vous concernant au ministère de l’ECC, et celui-ci
							communiquera ces renseignements au ministère des Finances, à des
							fins de production de rapports et de suivi du Programme de rabais
							:
						</li>
						<ul>
							<li>Date de la demande</li>
							<li>
								Nom légal complet du demandeur (tant pour les particuliers que
								les entreprises)
							</li>
							<li>Adresse du demandeur</li>
							<li>
								S’il s’agit d’une entreprise, fournir une confirmation qu’elle
								est enregistrée pour faire des affaires au Manitoba et qu’elle
								possède une adresse commerciale au Manitoba.
							</li>
							<li>Adresse électronique du demandeur</li>
							<li>Numéro de téléphone du demandeur</li>
							<li>Informations sur le véhicule :</li>
							<ul>
								<li>Numéro d'identification du véhicule (NIV)</li>
								<li>Date d’achat </li>
								<li>Prix d’achat</li>
								<li>Marque</li>
								<li>Modèle</li>
								<li>Type (VEB, VHR or VEPC) </li>
							</ul>
							<li>Rabais :</li>
							<ul>
								<li>Genre (neuf, utilisé ou loué)</li>
								<li>Durée du bail, le cas échéant</li>
								<li>Montant du rabais approuvé</li>
							</ul>
							<li>Date de traitement par la SAPM</li>
							<li>État de la demande</li>
							<li>Nom du commerçant</li>
							<li>
								Informations sur la demande, notamment la confirmation que le
								demandeur a accepté les conditions générales et les
								consentements
							</li>
						</ul>
						<li>
							La SAPM communiquera aussi les renseignements figurant sur votre
							formulaire de demande et les renseignements personnels
							supplémentaires énumérés ci-dessus au ministère de l’ECC, et
							celui-ci les communiquera à son tour au ministère des Finances,
							qui s’enquêtera sur les demandes présumées frauduleuses.
						</li>
						<ul>
							<li>Dans le cadre de l’enquête </li>
							<ul>
								<li>Acte de vente </li>
								<li>Contrat de location </li>
								<li>Immatriculation</li>
								<li>Motif du refus, le cas échéant</li>
							</ul>
						</ul>
						<li>
							Votre nom, votre adresse et votre adresse électronique seront
							également communiqués au ministère de l’ECC à des fins de
							communication, y compris dans le cadre de sondages périodiques sur
							l’utilisation du véhicule.
						</li>
						<li>
							Votre consentement est nécessaire pour que la SAPM communique vos
							renseignements personnels au ministère de l’ECC et pour que ce
							dernier les communique à son tour au ministère des Finances. La
							SAPM et le ministère de l’ECC ne communiqueront pas vos
							renseignements personnels sans votre consentement.
						</li>
						<li>
							Vos renseignements personnels sont protégés en vertu de la LAIPVP.
							Toute autre utilisation ou communication de vos renseignements
							personnels par la SAPM, le ministère de l’ECC ou le ministère des
							Finances doit être autorisée par vous ou en vertu de la LAIPVP. Si
							vous avez des questions concernant la collecte ou la communication
							de vos renseignements personnels, veuillez contacter
							<a href='mailto:evrebate@mpi.mb.ca'>evrebate@mpi.mb.ca</a> .
						</li>
					</ol>
					<h3>CONSENTEMENT</h3>
					<ol>
						<li>
							J’autorise la SAPM à communiquer mes renseignements personnels
							figurant dans le présent formulaire de demande et tout autre
							renseignement personnel, et le ministère de l’ECC à recueillir les
							renseignements personnels me concernant auprès de la SAPM, dans le
							but de traiter ma demande au titre du Programme de rabais, de
							produire des rapports, d’assurer le suivi, de mener des enquêtes
							et de communiquer avec moi.
						</li>
						<li>
							J’autorise le ministère de l’ECC à communiquer mes renseignements
							personnels figurant dans le présent formulaire de demande et tout
							autre renseignement personnel, et le ministère des Finances à
							recueillir les renseignements personnels me concernant, dans le
							but d’administrer les demandes présumées frauduleuses faites au
							titre du Programme de rabais, d’en assurer le suivi, de produire
							des rapports à leur égard, et d’enquêter sur celles-ci.
						</li>
						<h4>Je comprends que :</h4>
						<li>
							J’ai le droit de rétracter mon consentement à tout moment en
							informant la SAPM. Cependant, je conviens que la rétraction de mon
							consentement ne peut pas se faire rétroactivement, qu’elle
							entraînera le refus de ma demande au titre du Programme de rabais
							et que je ne bénéficierai pas des avantages prévus par ce
							programme.
						</li>
						<li>
							Mon consentement est volontaire, mais mon refus de le donner
							constituera un motif de refus de ma demande au titre du Programme
							de rabais. La SAPM, le ministère de l’ECC et le ministère des
							Finances n’utiliseront ni ne communiqueront les renseignements,
							sauf aux fins indiquées ci-dessus. La SAPM ne communiquera au
							ministère de l’ECC que les renseignements nécessaires me
							concernant, et ce dernier ne communiquera au ministère des
							Finances que les renseignements nécessaires me concernant.
						</li>
						<li>
							Les renseignements personnels reçus par la SAPM, le ministère de
							l’ECC et le ministère des Finances seront conservés de manière
							sûre et l’accès à ces renseignements sera limité aux personnes qui
							en ont besoin aux fins énumérées ci-dessus.
						</li>
					</ol>
					<h4>DÉCLARATION</h4>
					<p>
						J’atteste que tous les renseignements fournis dans la présente
						demande sont véridiques et complets. Je comprends que toute
						déclaration fausse ou inexacte ou toute omission dans la demande
						peut me disqualifier des avantages prévus par le Programme de
						rabais.
					</p>
				</>
			),
			continue: 'Continuer',
			cancel: 'Annuler',
			close: 'Fermer',
		},
	};

	return (
		<Modal allowClose={!isSubmission} setShow={setShow} classes='terms-modal'>
			<div>
				<h2 className='TCHeader'>{languages[language].termsHeader}</h2>
				<div className='terms-scroller'>{languages[language].terms}</div>
			</div>
			<form className='terms-modal_form' onSubmit={handleSubmit}>
				{isSubmission && (
					<CheckboxInput
						ref={checkboxRef}
						label={languages[language].termsAgreeance}
						name='terms'
						externalError={checkboxError}
					/>
				)}
				<div className='button-container'>
					{isSubmission && (
						<button
							onClick={(e) => {
								e.preventDefault();
								goToBeginning();
							}}
							className='secondary'
						>
							{languages[language].cancel}
						</button>
					)}
					{isSubmission && (
						<button type='submit' className='primary'>
							{languages[language].continue} <RightArrow />
						</button>
					)}
					{!isSubmission && (
						<button
							onClick={(e) => {
								e.preventDefault();
								setShow(false);
							}}
							className='primary'
						>
							{languages[language].close}
						</button>
					)}
				</div>
			</form>
		</Modal>
	);
};

export default TermsAndConditions;
