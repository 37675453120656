import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import '../styles/forms.css';
import CustomerHasLicense from './forms/CustomerHasLicense';
import CustomerNoLicense from './forms/CustomerNoLicense';
import { fadeVariant } from '../utils/motionVariants';
import Application from './ui/Application';

const CustomerEligibility = ({
	isSubmission = false,
	isStatusTracker = false,
	goToNextStep,
	...props
}) => {
	const params = new URLSearchParams(window.location.search);
	const initialLang = params.get('lang');

	const [customerType, setCustomerType] = useState(null);
	const [language, setLanguage] = useState(initialLang === 'fr' ? 'fr' : 'en');
	const [encryptedPartyID, setEncryptedPartyID] = useState('');

	const elementRef = useRef(null);

	const [applicationData, setApplicationData] = useState([]);
	const [hasError, setHasError] = useState(false);

	const customerSelection = (selection) => {
		setCustomerType(selection);
	};

	const languages = {
		en: {
			titleText: 'Check customer eligibility',
			statusTitleText: 'Check the status of your EV Rebate application',
			statusPreamble:
				'Here is the information we currently have for your EV Rebate application. You may be contacted by email for more information. You’ll be notified by mail once MPI has approved your application. If you have any questions, please email <a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			preamble: (
				<>
					<p>
						Thinking about purchasing an electric vehicle and want to know if
						you’re eligible for the EV Rebate? A few things you need to know:
					</p>
					<ul>
						<li>Only one customer account can apply for the EV Rebate.</li>
						<li>Only the legal/registered owner of the vehicle can apply.</li>
						<li>You must be a permanent Manitoba resident to apply.</li>
					</ul>
					<p>See if you’re eligible by selecting one of the options below.</p>
				</>
			),
			DL: 'Valid Manitoba driver’s licence or ID card',
			NDL: 'No Manitoba driver’s licence or ID card',
			corporate: 'Corporate customer',
			select: 'Select',
			returnHome: 'Return to the homepage',
			startOver: 'Start over',
		},
		fr: {
			titleText: "Vérifier l'éligibilité du client",
			statusTitleText:
				'Vérifiez l’état de votre demande d’accès au Programme de remboursement à l’achat ou à la location d’un VE',
			statusPreamble:
				'Voici les renseignements dont nous disposons à l’heure actuelle pour votre demande d’accès au Programme de remboursement à l’achat ou à la location d’un VE. Nous pourrions communiquer avec vous par courriel pour obtenir de plus amples renseignements. Vous recevrez une confirmation de la SAPM par courriel quand votre demande sera approuvée. Si vous avez des questions, veuillez communiquer avec nous à l’adresse<a href="mailto:evrebate@mpi.mb.ca">evrebate@mpi.mb.ca</a>.',
			preamble: (
				<>
					<p>
						Vous envisagez d’acheter un véhicule électrique et vous voulez
						savoir si vous êtes admissible au Programme de remboursement à
						l’achat ou à la location d’un VE? <br></br>Voici quelques éléments à
						savoir :
					</p>
					<ul>
						<li>
							Une seule demande d’accès au Programme de remboursement à l’achat
							ou à la location d’un VE est permise par compte de client.
						</li>
						<li>
							Seul le propriétaire légal/immatriculé du véhicule peut présenter
							une demande.
						</li>
						<li>
							Vous devez être un résident permanent du Manitoba pour présenter
							une demande.
						</li>
					</ul>
					<p>
						Vérifiez si vous êtes admissible en sélectionnant l’une des options
						ci-dessous.
					</p>
				</>
			),
			DL: 'Permis de conduire ou carte d’identité du Manitoba',
			NDL: 'Aucun permis de conduire ou carte d’identité du Manitoba',
			corporate: 'Client commercial',
			select: 'Sélectionner',
			returnHome: 'Retour à l’accueil',
			startOver: 'Recommencer',
		},
	};

	if (isSubmission) {
		languages.en.preamble = <p>Select your identification type below.</p>;
		languages.fr.preamble = (
			<p>Sélectionnez votre type de pièce d’identité ci-dessous. </p>
		);
	}
	if (isStatusTracker) {
		languages.en.titleText = 'Check the status of your EV Rebate application';
		languages.en.preamble = (
			<p style={{ fontSize: '20px' }}>
				Enter the following customer information to find out the status of your
				recent EV rebate application.
			</p>
		);
		languages.fr.preamble = (
			<p style={{ fontSize: '20px' }}>
				Saisissez les renseignements suivants sur le client pour vérifier l’état
				de votre demande.
			</p>
		);
	}

	const returnHome = () => {
		window.location.href = '/';
	};

	const startOverApplications = () => {
		setApplicationData([]);
	};

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const lang = params.get('lang');

		if (lang === 'en' || lang === 'fr') {
			setLanguage(lang);
		}
	}, []);

	useEffect(() => {
		const fetchApplications = async () => {
			try {
				const listData = await fetch('/wp-json/custom/v1/check-status', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						party_id: encryptedPartyID,
					}),
				});

				const listResponse = await listData.json();
				if (listResponse.success) {
					//setApplicationData(null);
				} else {
					if (listResponse.api_response !== null) {
						if (listResponse.api_response)
							setApplicationData(listResponse.api_response);
					} else {
						setHasError(true);
					}
				}
			} catch (error) {
				console.error('Error:', error);
			}
		};

		if (encryptedPartyID) {
			fetchApplications();
		}
	}, [encryptedPartyID]);

	useEffect(() => {
		if (applicationData) {
			setTimeout(() => {
				if (elementRef.current) {
					elementRef.current.scrollIntoView({ behavior: 'smooth' });
				}
			}, 500);
		}
	}, [applicationData]);

	return (
		<React.Fragment>
			<AnimatePresence mode='wait'>
				{applicationData.length === 0 && (
					<motion.div
						key='1'
						className='customer-types_container form-container'
						initial='visible'
						animate='visible'
						exit='hidden'
						variants={fadeVariant}
						transition={{ duration: 0.2, ease: 'easeInOut' }}
					>
						{!isSubmission && <h1>{languages[language].titleText}</h1>}
						<div>{languages[language].preamble}</div>
						<div
							className={`${
								customerType ? 'customer-types closed' : 'customer-types'
							} ${isSubmission ? '' : 'no-submit'}`}
						>
							<div
								className={customerType === 'individual-DL' ? 'active' : ''}
								onClick={() => {
									customerSelection('individual-DL');
								}}
							>
								<div>
									<AnimatePresence>
										{!customerType && (
											<motion.img
												src={`/wp-content/plugins/ev-incentive-react/build/images/drivers-license.png`}
												key='individual-DL-img'
												initial='visible'
												animate='visible'
												exit='hidden'
												variants={fadeVariant}
												transition={{ duration: 0.2, ease: 'easeInOut' }}
												className='eligibility-images'
											/>
										)}
									</AnimatePresence>
									<span>{languages[language].DL}</span>
								</div>
								<AnimatePresence>
									{!customerType && (
										<motion.button
											key='individual-DL-btn'
											initial='visible'
											animate='visible'
											exit='hidden'
											variants={fadeVariant}
											transition={{ duration: 0.2, ease: 'easeInOut' }}
										>
											{languages[language].select}
										</motion.button>
									)}
								</AnimatePresence>
							</div>
							<div
								className={customerType === 'individual-NDL' ? 'active' : ''}
								onClick={() => {
									customerSelection('individual-NDL');
								}}
							>
								<div>
									<AnimatePresence>
										{!customerType && (
											<motion.img
												src={`/wp-content/plugins/ev-incentive-react/build/images/no-drivers-license.png`}
												key='individual-NDL-img'
												initial='visible'
												animate='visible'
												exit='hidden'
												variants={fadeVariant}
												transition={{ duration: 0.2, ease: 'easeInOut' }}
												className='eligibility-images'
											/>
										)}
									</AnimatePresence>
									<span
										dangerouslySetInnerHTML={{
											__html: languages[language].NDL,
										}}
									></span>
								</div>
								<AnimatePresence>
									{!customerType && (
										<motion.button
											key='individual-NDL-btn'
											initial='visible'
											animate='visible'
											exit='hidden'
											variants={fadeVariant}
											transition={{ duration: 0.2, ease: 'easeInOut' }}
										>
											{languages[language].select}
										</motion.button>
									)}
								</AnimatePresence>
							</div>
							<div
								className={customerType === 'corporate' ? 'active' : ''}
								onClick={() => {
									customerSelection('corporate');
								}}
							>
								<div>
									<AnimatePresence>
										{!customerType && (
											<motion.img
												src={`/wp-content/plugins/ev-incentive-react/build/images/corporate-customer-card-vehicle.png`}
												key='corporate-img'
												initial='visible'
												animate='visible'
												exit='hidden'
												variants={fadeVariant}
												transition={{ duration: 0.2, ease: 'easeInOut' }}
												className='eligibility-images'
											/>
										)}
									</AnimatePresence>
									<span>{languages[language].corporate}</span>
								</div>
								<AnimatePresence>
									{!customerType && (
										<motion.button
											key='corporate-btn'
											initial='visible'
											animate='visible'
											exit='hidden'
											variants={fadeVariant}
											transition={{ duration: 0.2, ease: 'easeInOut' }}
										>
											{languages[language].select}
										</motion.button>
									)}
								</AnimatePresence>
							</div>
						</div>
						<AnimatePresence mode='wait'>
							{customerType === 'corporate' && (
								<motion.div
									key='corporate-3'
									initial='hidden'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{
										duration: 0.2,
										ease: 'easeInOut',
										delay: 0.3,
									}}
								>
									<CustomerNoLicense
										isCorporate={true}
										isSubmission={isSubmission}
										goToNextStep={goToNextStep}
										setEncryptedPartyID={setEncryptedPartyID}
										isStatusTracker={isStatusTracker}
										hasError={hasError}
									/>
								</motion.div>
							)}
							{customerType === 'individual-DL' && (
								<motion.div
									key='individual-4'
									initial='hidden'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{
										duration: 0.2,
										ease: 'easeInOut',
										delay: 0.3,
									}}
								>
									<CustomerHasLicense
										isSubmission={isSubmission}
										goToNextStep={goToNextStep}
										setEncryptedPartyID={setEncryptedPartyID}
										isStatusTracker={isStatusTracker}
										hasError={hasError}
									/>
								</motion.div>
							)}
							{customerType === 'individual-NDL' && (
								<motion.div
									key='individual-5'
									initial='hidden'
									animate='visible'
									exit='hidden'
									variants={fadeVariant}
									transition={{
										duration: 0.2,
										ease: 'easeInOut',
										delay: 0.3,
									}}
								>
									<CustomerNoLicense
										isSubmission={isSubmission}
										goToNextStep={goToNextStep}
										setEncryptedPartyID={setEncryptedPartyID}
										isStatusTracker={isStatusTracker}
										hasError={hasError}
									/>
								</motion.div>
							)}
						</AnimatePresence>
					</motion.div>
				)}
				{applicationData.length > 0 && (
					<motion.div
						key='10'
						className='form-container'
						initial='visible'
						animate='visible'
						exit='hidden'
						variants={fadeVariant}
						transition={{ duration: 0.2, ease: 'easeInOut' }}
					>
						<h1 ref={elementRef}>{languages[language].statusTitleText}</h1>
						<p
							style={{ fontSize: '20px' }}
							dangerouslySetInnerHTML={{
								__html: languages[language].statusPreamble,
							}}
						></p>
						{applicationData.length > 0 && (
							<div className='application_container'>
								<AnimatePresence>
									{applicationData.map((application, index) => (
										<motion.div
											key={index}
											initial='hidden'
											animate='visible'
											exit='hidden'
											variants={fadeVariant}
											transition={{ delay: index * 0.1 }}
										>
											<Application index={index} application={application} />
										</motion.div>
									))}
								</AnimatePresence>
							</div>
						)}
						<div className='button-container'>
							<button
								className={`primary`}
								type='submit'
								onClick={() => startOverApplications()}
							>
								{languages[language].startOver}
							</button>
							<button
								className={`secondary`}
								type='submit'
								onClick={() => returnHome()}
							>
								{languages[language].returnHome}
							</button>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</React.Fragment>
	);
};

export default CustomerEligibility;
